// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stepper_stepper__T8488 {\n    width: 50%;\n}\n.Stepper_contenedorStepper__SKXMg{\n    width: 20px!important;\n}\n@media screen and (min-width: 992px) {\n\t.Stepper_stepper__T8488 {\n\t\twidth: 50%;\n\t\tmargin: 0 auto;\n\t}\n}\n/* MuiStepper-root MuiStepper-horizontal MuiStepper-alternativeLabel css-10mg1vw-MuiStepper-root{\n    width: 50px;\n} */", "",{"version":3,"sources":["webpack://./src/components/DesktopStepper/Stepper.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,qBAAqB;AACzB;AACA;CACC;EACC,UAAU;EACV,cAAc;CACf;AACD;AACA;;GAEG","sourcesContent":[".stepper {\n    width: 50%;\n}\n.contenedorStepper{\n    width: 20px!important;\n}\n@media screen and (min-width: 992px) {\n\t.stepper {\n\t\twidth: 50%;\n\t\tmargin: 0 auto;\n\t}\n}\n/* MuiStepper-root MuiStepper-horizontal MuiStepper-alternativeLabel css-10mg1vw-MuiStepper-root{\n    width: 50px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": "Stepper_stepper__T8488",
	"contenedorStepper": "Stepper_contenedorStepper__SKXMg"
};
export default ___CSS_LOADER_EXPORT___;
