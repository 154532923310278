import axios from "axios";

// const baseURL = process.env.REACT_APP_API_HOST;
const baseURL = process.env.REACT_APP_API_HOST_LOCAL; 

const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default apiInstance;
