export const textos = {
	arrayTextos: [
		{
			parrafos: [
				"Los datos que facilita el Usuario en el proceso de registro a RavaOnline.com se incorporarán a un fichero automatizado que se regirá por la siguiente Política de Privacidad.",
			],
		},
		{
			titulo: "1. Tratamiento de datos",
			parrafos: [
				"Rava Bursátil S.A. se compromete al cumplimiento de su obligación de secreto de los datos de carácter personal y de su deber de tratarlos con confidencialidad, y asume, a estos efectos, las medidas de índole técnica, organizativa y de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, de acuerdo con la legislación aplicable.",
				"Los datos recogidos son almacenados bajo la confidencialidad y las medidas de seguridad legalmente establecidas y no serán cedidos ni compartidos con empresas ni entidades ajenas al responsable legal de la web.",
				"Rava Bursátil S.A. podrá revelar cualquier información, incluyendo datos de carácter personal, que considere necesaria para dar cumplimiento a las obligaciones legales.",
			],
		},
		{
			titulo: "2. Identificación del responsable del fichero",
			parrafos: [
				"Rava Bursátil S.A., con domicilio en la calle 25 de mayo 277, 5 24, Ciudad Autónoma de Buenos Aires, República Argentina, con CUIT 30-59502502-4 será la responsable del fichero o ficheros que contengan la información que usted nos facilite.",
			],
		},
		{
			titulo: "3. Derechos de Acceso, Rectificación, Cancelación y Oposición",
			parrafos: [
				"El Usuario podrá ejercer los derechos de acceso, cancelación o rectificación a través de las siguientes formas:",
			],
			parrafosEnBold: [
				"Envío por correo postal a la siguiente dirección: 25 de mayo 277, 5 24, Ciudad Autónoma de Buenos Aires, República Argentina.",
				"Comunicación por medio de una llamada al número de teléfono: +54 11 3434 9421",
				"Envío por correo electrónico a la siguiente dirección: info@ravaonline.com",
			],
		},
		{
			titulo: "4. Navegación con cookies y Marcas Web o Web Beacon",
			parrafos: [
				"RavaOnline.com utiliza pequeños ficheros de datos que se generan en el ordenador del usuario y que nos permiten obtener información sobre la fecha y hora de la última vez que el usuario visitó nuestro web, así como otros datos de caracter estadístico. La utilización de cookies permite:",
				"Reconocer a sus usuarios registrados con anterioridad, facilitando el acceso a sus servicios Almacenar las preferencias y configuraciones personales del usuario Aquellos usuarios que no deseen recibir cookies o quieran ser informados antes de que se almacenen en su ordenador pueden configurar su navegador a tal efecto.",
				"La mayor parte de los navegadores de hoy en día permiten la gestión de las cookies de 3 formas diferentes:",
			],
			items: [
				"Las cookies no se aceptan nunca.",
				"Para cada cookie, el navegador pregunta al usuario si ésta debe ser aceptada.",
				"Las cookies se aceptan siempre.",
			],
		},
		{
			parrafos: [
				"El navegador también puede incluir la posibilidad de especificar mejor qué cookies tienen que ser aceptadas y cuáles no. En concreto, el usuario puede optar por alguna de las siguientes opciones: rechazar las cookies de determinados dominios; rechazar las cookies de terceros; aceptar cookies como no persistentes (se eliminan cuando el navegador se cierra); permitir al servidor crear cookies para un dominio diferente. Además, los navegadores también pueden permitir a los usuarios ver y borrar cookies individualmente.",
			],
			parrafosConLinks: [
				'Más información: <a href="http://es.wikipedia.org/wiki/Cookie"> http://es.wikipedia.org/wiki/Cookie</a>',
				// "Al igual que las cookies este sitio también puede contener web beacons, un archivo electrónico gráfico que permite contabilizar a los usuarios que acceden al sitio o acceden a determinadas cookies del mismo, de esta manera, podremos ofrecerle una experiencia aún más personalizada.",
				// "Más información: http://es.wikipedia.org/wiki/Web_bug.",
			],
		},
		{
			parrafos: [
				"Al igual que las cookies este sitio también puede contener web beacons, un archivo electrónico gráfico que permite contabilizar a los usuarios que acceden al sitio o acceden a determinadas cookies del mismo, de esta manera, podremos ofrecerle una experiencia aún más personalizada.",
			],
			parrafosConLinks: [
				'Más información: <a href="http://es.wikipedia.org/wiki/Web_bug"> http://es.wikipedia.org/wiki/Web_bug</a>',
			],
		},
		{
			titulo: "5. Otros datos recogidos",
			parrafos: [
				"RavaOnline.com recoge datos sobre los usuarios y sus visitas. Estos datos, tales como áreas más visitadas, servicios accedidos, rutas de navegación, etc. son de carácter puramente estadístico y sirven para un posterior estudio y análisis. Esta acción nos permite y avanzar en el diseño de nuevos servicios y mejorar los existentes. Este sitio está siendo medido con las soluciones de Nielsen//NetRatings,Google Analytics y TradeDoubler. Estas soluciones utilizan marcas en las páginas y cookies anónimas. Recomendamos a los Usuarios revisar detalladamente las Políticas De Privacidad de los servicios de rastreo y estadísticas utilizados en RavaOnline.com:",
			],
		},
		{
			titulo: "6. Google Analytics y Google AdSense",
			parrafos: [
				"Esta página web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google. Puede Usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe Usted saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este website. Al utilizar este website Usted consiente el tratamiento de información acerca de Usted por Google en la forma y para los fines arriba indicados.",
			],
			parrafosConLinks: [
				'Nielsen: <a href="http://www.nielsen-netratings.com/about_pri.jsp">http://www.nielsen-netratings.com/about_pri.jsp</a>',
				'Google Analytics: <a href="http://www.google.com/analytics/terms/us.html">http://www.google.com/analytics/terms/us.html</a>',
				'TradeDoubler: <a href="http://www.tradedoubler.com/legal/privacy_policy.php">http://www.tradedoubler.com/legal/privacy_policy.php</a>',
			],
		},
		{
			tituloChico: ["Cookies de publicidad de Google de AdSense"],
			parrafos: [
				"Google utiliza la cookie de DoubleClick DART en los anuncios publicados en sitios web de editores que muestran anuncios de AdSense para contenido. Cuando un usuario final accede a un sitio web del editor de AdSense y ve un anuncio o hace clic en él, es posible que se introduzca una cookie en su navegador. Los datos que se recopilan a partir de estas cookies se utilizan para ayudar a los editores de AdSense a publicar y administrar mejor los anuncios de sus sitios y de toda la Web.",
			],
			parrafosConLinks: [
				'Los usuarios pueden inhabilitar el uso de la cookie de DART accediendo a las políticas de privacidad publicitarias de Google  <a href="http://www.google.com/privacy_ads.html">(http://www.google.com/privacy_ads.html)</a>',
				'Más información sobre la cookie de DART de DoubleClick: <a href="http://www.doubleclick.com/privacy/faq.aspx">http://www.doubleclick.com/privacy/faq.aspx</a>',
			],
		},
		{
			titulo: "7. Política de Privacidad de la Publicidad proporcionada y Acciones de terceros",
			parrafos: [
				"Es posible que determinadas empresas asociadas introduzcan y lean cookies en su navegador, o que utilicen etiquetas de seguimiento para recopilar información como consecuencia de la publicación de anuncios en su sitio web",
				"Este sitio incluye anuncios de publicidad servidos por Google AdSense, Google AdWords y TradeDoubler. Recomendamos a los Usuarios revisar detalladamente las Políticas De Privacidad de los servicios de publicidad utilizados en RavaOnline.com:",
			],
			parrafosConLinks: [
				'Google AdSense: <a href="http://www.google.es/privacy.html#utm_source=aso&utm_campaign=ww-ww-et-ashp&utm_medium=link">http://www.google.es/privacy.html#utm_source=aso&utm_campaign=ww-ww-et-ashp&utm_medium=link</a>',
				'Google AdWords: <a href="http://www.google.com/intl/es/privacy.html">http://www.google.com/intl/es/privacy.html</a>',
				'TradeDoubler: <a href="http://www.tradedoubler.com/pan/cms/privacy_policy">http://www.tradedoubler.com/pan/cms/privacy_policy</a>',
			],
		},
		{
			parrafosPostLinks: [
				"Asimismo, usted puede encontrar dentro de este sitio, páginas, promociones, micrositios, tiendas virtuales, encuestas, patrocinadores, publicistas, contratistas y/o socios y servicios comerciales, en conjunto con otros servicios compartidos, propios o cobrandeados con terceros (”Sitios Cobrandeados”) que le podrán solicitar Información, los cuales este sitio le podrá revelar información proporcionada por usted.",
				"La Información que se proporcione en estos Sitios Cobrandeados esta sujeta a las políticas de privacidad o leyendas de responsabilidad de Información que se desplieguen en dichos Sitios y no estará sujeta a esta política de privacidad. Por lo que recomendamos ampliamente a los Usuarios a revisar detalladamente las políticas de privacidad que se desplieguen en los Sitios Cobrandeados",
			],
		},
		{
			titulo: "8. Boletines informativos, comunicaciones comerciales y notificaciones",
			parrafos: [
				"RavaOnline.com hara envío de Boletines Informativos y Comunicación Comerciales a los usuarios que así lo autoricen. Estos estarán basados en el envío de información relativa a novedades, contenidos destacados, consejos relevantes al funcionamiento del propio sistema e información comercial.",
				"Con dicha autorización se entenderá que los usuarios aceptan expresamente la recepción de dichas comunicaciones.",
				"Este consentimiento tiene carácter revocable, sin efectos retroactivos, con la simple notificación de su voluntad al remitente. Paralelamente se han implementado los mecanismos necesarios para que los usuarios que lo requieran puedan dejar de recibir en cualquier momento los anteriores servicios y las comunicaciones.",
			],
		},
		{
			titulo: "9. Cambio de Titularidad",
			parrafos: [
				"En caso de adquisición, fusión o cualesquiera otras causas que provoquen el cambio de la titularidad de la web y el fichero de datos, el Usuario consiente expresamente que sus datos de registro y su INFORMACIÓN sean transferidos por Rava Bursátil S.A. al nuevo titular, pudiendo ser este último nacional o extranjero. Cuando esto se produzca, Rava Bursátil S.A. cumplirá, en todo caso, con el deber de información a este respecto al Usuario.",
			],
		},
	],
};
