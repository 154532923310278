import styled, { css } from "styled-components";

export const ContenedorBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.backgroundContainerHome};
    border-radius: 0.8em;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    width: 90%;
    height: 65%;
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
    justify-content: center;
    justify-self: center;
    padding: 1em;
    align-self: flex-start;
    align-content: center;
    @media (min-width: 992px) {
        width: 40%;
        height: 60%;
        padding: 1em;
        margin-top: 0;
        margin-left: 0em;
        margin-right: 0em;
        border-radius: 0.4em;
        align-self: center;
    }
    @media (min-width: 1440px) {
        width: 30%;
        height: 65%;
        padding: 1em;
    }
`;

export const Input = styled.input`
background: ${({theme}) => theme.backgroundInput};
	box-shadow: 4px 4px 10px 0px ${({theme}) => theme.inputShadow2} inset;
	border-radius: 5px;
	padding: 0.8em;
	color: ${({ theme }) => theme.fontColor};	
	border:  0.1px solid ${({theme}) => theme.bordesInput};
	${props => props.className === "error" && css`border:  0.1px solid #E3342F`};
	@media (max-width: 992px) {
		font-size: 14px;
	}
`;