import {
	GET_DATOS_PERSONALES,
	SAVE_DATOS_PERSONALES,
	GET_RESULTADO_TEST,
	SAVE_RESULTADO_TEST,
	GET_DDJJ,
	SAVE_DDJJ,
	GET_TERMINOS,
	SET_DNI,
} from "../constantes";

const initialState = {
	datosPersonales: {},
	datosPersonalesPrevios: {},
	resultadoTest: [],
	resultadoTestPrevio: {},
	declaraciones: [],
	declaracionesPrevias: {},
	terminosAceptados: false,
};

function datosEtapasReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DATOS_PERSONALES:
			return {
				...state,
				datosPersonalesPrevios: action.payload,
			};
		case SAVE_DATOS_PERSONALES:
			return {
				...state,
				datosPersonales: action.payload,
			};
		case SET_DNI:
			return {
				...state,
				datosPersonalesPrevios: {
					...state.datosPersonalesPrevios,
					NumeroDNI: action.payload,
				},
			};
		case GET_RESULTADO_TEST:
			return {
				...state,
				resultadoTestPrevio: action.payload,
			};
		case SAVE_RESULTADO_TEST:
			return {
				...state,
				resultadoTest: action.payload,
			};
		case GET_DDJJ:
			return {
				...state,
				declaracionesPrevias: action.payload,
			};
		case SAVE_DDJJ:
			return {
				...state,
				declaraciones: action.payload,
			};
		case GET_TERMINOS:
			return {
				...state,
				terminosAceptados: action.payload,
			};
		default:
			return state;
	}
}

export default datosEtapasReducer;
