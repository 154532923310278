import React, { useEffect, useState } from 'react'
import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { cambiarPantallaActual } from '../../redux/actions';
import s from './CotitularesConformidad.module.css'
import apiRoutes from '../../api/apiRoutes';

export const CotitularesConformidad = () => {
    const dispatch = useDispatch();

    const [confirmado, setConfirmado] = useState(false);

    let url = window.location.href;
    let token = url.substring(url.lastIndexOf("/")).slice(1);

    // Create a buffer from the string
    let bufferObj = Buffer.from(token, "base64");

    // Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");

    let nameTitular = decodedString.substring(decodedString.lastIndexOf("nameTitular:"), decodedString.lastIndexOf("&email:")).slice(12);
    let email = decodedString.substring(decodedString.lastIndexOf("&email:"), decodedString.lastIndexOf("&code:")).slice(7);
    let code = decodedString.substring(decodedString.lastIndexOf("&code:"), decodedString.lastIndexOf("&emailCotitular:")).slice(6);
    let emailCotitular = decodedString.substring(decodedString.lastIndexOf("&emailCotitular:"), decodedString.lastIndexOf("&name:")).slice(16);
    let name = decodedString.substring(decodedString.lastIndexOf("&name:"), decodedString.lastIndexOf("&numCuenta:")).slice(6);
    let numCuenta = decodedString.substring(decodedString.lastIndexOf("&numCuenta:")).slice(11);


    useEffect(() => {
        dispatch(cambiarPantallaActual(-1));
    }, []);

    const handleClick = async () => {
        await apiRoutes.conformidadIncorporacion({nameTitular, email, code, emailCotitular}).then(
            function (value) {
                // Success!
                setConfirmado(true)
            },
            function (error) {
                console.log(error.response);
            }

        );
    };

    return (
        <div className={s.contenedorPrincipal}>
            <Icon
                icon="la:hands-helping"
                style={{
                    fontSize: "56px",
                    color: `${({ theme }) => theme.fontColor}`,
                }}
            />

            {confirmado ? (
                <>
                    <h3 className={s.titulo}>¡LISTO!</h3>

                    <p className={s.texto}>
                        {" "}
                        Ya prestaste conformidad para la incorporación de un nuevo titular.
                    </p>
                </>
            ) : (
                <>
                    <h3 className={s.titulo}>INCORPORACIÓN DE NUEVO TITULAR</h3>

                    <p className={s.texto}>
                        {" "}
                        Prestas conformidad para la incorporación de {name} a
                        la cuenta Nº {numCuenta}
                    </p>
                    <div className={s.buttonContainer}>
                        <Button
                            style={{
                                marginRight: "16px",
                                backgroundColor: "#C9C9C9",
                                fontSize: "0.8em",
                            }}
                            onClick={handleClick}
                            variant="contained"
                            size="large"
                        >
                            confirmar
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
