import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserData, setReanudar, restartStore, getOldUserData } from "../redux/actions";
import { useNavigate } from "react-router-dom";

/**
 * Hook que manipula los formularios para iniciar una apertura (en Reapertura de usuarios y y Nueva apertura form)
 * @param {Objetc} initialForm Formulario inicial
 * @param {Object} validateForm Función validadora que devuelque los errores
 * @param {Boolean} [actualizacion] Si es una reapertura de un usuario que ya tiene cuenta o es una nueva apertura
 * @returns {Objetc} 
 */

export const useFormLogin = (initialForm, validateForm, actualizacion) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [form, setForm] = useState(initialForm);

	const [errors, setErrors] = useState({});

	useEffect(() => {
		// Ir comprobando los errores mientras el usuario modifica los campos,
		// una vez que ya haya errores, después del primer intento de submit
		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form));
		}
	}, [form]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});

		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();


		let errorsVariableEstatica = validateForm(form);
		setErrors(validateForm(form));

		// Se comprueba que no haya errores
		if (
			Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0
		) 
		{
			if(actualizacion){
				//Si es un usuario ya registrado 
				try {
					dispatch(restartStore());
					dispatch(
						getOldUserData({
							name: form.name.toLowerCase(),
							last_name: form.last_name.toLowerCase(),
							phone: form.phone,
							email: form.email.toLowerCase(),
							code: form.code,
							numCuenta: parseInt(form.numCuenta)
						})
					);
					
					dispatch(setReanudar(false));
					
					//navega a datos personales ya que no necesita validar el mail
					navigate("/datos-personales");
				} catch (error) {
					console.log(error);
				}
			}
			else {
			//Si es una nueva apertura
			try {
				dispatch(restartStore());
				dispatch(
					getUserData({
						name: form.name,
						last_name: form.last_name,
						phone: form.phone,
						email: form.email,
					})
				);
				dispatch(setReanudar(false));
				navigate("/confirmacion");
			} catch (error) {
				console.log(error);
			}
		}
		}
	};

	return {
		form,
		errors,
		handleChange,
		handleSubmit,
	};
};
