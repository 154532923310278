/**
 * Función validadora para abrir una nueva apertura
 * @param {Objetc} form Formulario
 * @param {Objetc} datosP En caso de ya haber completado el formulario anteriormente
 * @param {Array<String>} subsanar Etapas a subsanar
 * @returns {Object} Errores en caso de haberlos 
 */

export const validacionesNuevaApertura = (form) => {
	let errors = {};

	let reEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

	let reTel = /^([0-9]){2,}$/;

	if (!form.name) {
		errors.name = "El campo 'Nombre' es requerido";
	}
	if (!form.last_name) {
		errors.last_name = "El campo 'Apellido' es requerido";
	}
	if (!form.phone) {
		errors.phone = "El campo 'Celular' es requerido";
	} else if (!reTel.test(form.phone)) {
		errors.phone = "El celular debe ser de caractéres númericos";
	} else if (form.phone.length < 10) {
		errors.phone = "El celular debe tener al menos 10 caracteres";
	} else if (form.phone.slice(0, 2) === "15" || form.phone.slice(0, 1) === "0" ) {
		errors.phone = "Ingresar con código de área (ej. 11) sin 0 ni 15"
	}
	if (!form.email) {
		errors.email = "El campo 'Email' es requerido";
	} else if (!reEmail.test(form.email)) {
		errors.email = "El email debe ser válido";
	}
	if (!form.email) {
		errors.email = "El campo 'Email' es requerido";
	}

	return errors;
};
