import React from "react";
import { Input, OptionContainer, Select } from "../../views/DatosPersonales/datosElements";
import s from "../../views/DatosPersonales/DatosPersonales.module.css"
import { arrayGenero, arrayGeneroFem, arrayGeneroMasc, arrayGeneroX, tipoDocumento, tipoDocumentoDNI, tipoDocumentoLC, tipoDocumentoLE, tipoDocumentoPasaporte } from "../../helpers/helperDatos";

export const ConyugeForm = ({form, datosP, handleBlur, handleChange, subsanar, errors, fechaLimite}) => {
    return (
        <div>
            <h4 className={s.subtitulo}>DATOS PERSONALES CÓNYUGE</h4>
            <div className={s.seccion}>
                <OptionContainer>
                    <label className={s.labelForm}>Nombre</label>
                    <Input
                        type="text"
                        name="NombreConyuge"
                        value={form.name}
                        defaultValue={
                            datosP?.NombreConyuge && datosP.NombreConyuge
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        {...(subsanar.length > 0 && {
                            readOnly: true,
                        })}
                        className={errors.nombreConyuge && "errorCampo"}
                    />
                    {errors.nombreConyuge && (
                        <p className={s.error}>{errors.nombreConyuge}</p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>Apellido</label>
                    <Input
                        type="text"
                        name="ApellidoConyuge"
                        value={form.name}
                        defaultValue={
                            datosP?.ApellidoConyuge && datosP.ApellidoConyuge
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        {...(subsanar.length > 0 && {
                            readOnly: true,
                        })}
                        className={errors.apellidoConyuge && "errorCampo"}
                    />
                    {errors.apellidoConyuge && (
                        <p className={s.error}>{errors.apellidoConyuge}</p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>Sexo</label>
                    {datosP?.GeneroConyuge ? (
                        <Select
                            onChange={(e) => handleChange(e)}
                            name="GeneroConyuge"
                            defaultValue={"DEFAULT"}
                            value={form.name}
                            className={errors.generoConyuge && "errorCampo"}
                        >
                            {datosP.GeneroConyuge === "Femenino" && (
                                <>
                                    <option value="DEFAULT">Femenino</option>
                                    {arrayGeneroFem.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                            {datosP.GeneroConyuge === "Masculino" && (
                                <>
                                    <option value="DEFAULT">Masculino</option>
                                    {arrayGeneroMasc.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                            {datosP.GeneroConyuge === "X" && (
                                <>
                                    <option value="DEFAULT">X</option>
                                    {arrayGeneroX.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                        </Select>
                    ) : (
                        <Select
                            onChange={(e) => handleChange(e)}
                            defaultValue={"DEFAULT"}
                            name="GeneroConyuge"
                            value={form.name}
                            className={errors.generoConyuge && "errorCampo"}
                        >
                            <option
                                value="DEFAULT"
                                className={s.option}
                                disabled
                            >
                                Selecciona
                            </option>
                            {arrayGenero.map((e) => (
                                <option key={e}>{e}</option>
                            ))}
                        </Select>
                    )}

                    {errors.generoConyuge && (
                        <p className={s.error}>{errors.generoConyuge}</p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>Fecha de nacimiento</label>
                    <Input
                        type="date"
                        name="FechaDeNacimientoConyuge"
                        max={fechaLimite}
                        value={form.name}
                        defaultValue={
                            datosP?.FechaDeNacimientoConyuge &&
                            datosP.FechaDeNacimientoConyuge
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        {...(subsanar.length > 0 && {
                            readOnly: true,
                        })}
                        className={
                            errors.fechaDeNacimientoConyuge && "errorCampo"
                        }
                    />
                    {errors.fechaDeNacimientoConyuge && (
                        <p className={s.error}>
                            {errors.fechaDeNacimientoConyuge}
                        </p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>Tipo de documento</label>
                    {datosP?.TipoDeDocumentoConyuge ? (
                        <Select
                            onChange={(e) => handleChange(e)}
                            name="TipoDeDocumentoConyuge"
                            defaultValue={"DEFAULT"}
                            value={form.name}
                            className={
                                errors.tipoDeDocumentoConyuge && "errorCampo"
                            }
                        >
                            {datosP.TipoDeDocumentoConyuge === "DNI" && (
                                <>
                                    <option value="DEFAULT">DNI</option>
                                    {tipoDocumentoDNI.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                            {datosP.TipoDeDocumentoConyuge === "Pasaporte" && (
                                <>
                                    <option value="DEFAULT">Pasaporte</option>
                                    {tipoDocumentoPasaporte.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                            {datosP.TipoDeDocumentoConyuge === "LE" && (
                                <>
                                    <option value="DEFAULT">LE</option>
                                    {tipoDocumentoLE.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                            {datosP.TipoDeDocumentoConyuge === "LC" && (
                                <>
                                    <option value="DEFAULT">LC</option>
                                    {tipoDocumentoLC.map((e) => (
                                        <option disabled={subsanar} key={e}>{e}</option>
                                    ))}
                                </>
                            )}
                        </Select>
                    ) : (
                        <Select
                            onChange={(e) => handleChange(e)}
                            defaultValue={"DEFAULT"}
                            name="TipoDeDocumentoConyuge"
                            value={form.name}
                            className={
                                errors.tipoDeDocumentoConyuge && "errorCampo"
                            }
                        >
                            <option
                                value="DEFAULT"
                                className={s.option}
                                disabled
                            >
                                Selecciona
                            </option>
                            {tipoDocumento.map((e) => (
                                <option key={e}>{e}</option>
                            ))}
                        </Select>
                    )}

                    {errors.tipoDeDocumentoConyuge && (
                        <p className={s.error}>
                            {errors.tipoDeDocumentoConyuge}
                        </p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>Número de documento</label>
                    <Input
                        type="text"
                        name="NumeroDeDocumentoConyuge"
                        value={form.name}
                        defaultValue={
                            datosP?.NumeroDeDocumentoConyuge &&
                            datosP.NumeroDeDocumentoConyuge
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        {...(subsanar.length > 0 && {
                            readOnly: true,
                        })}
                        className={
                            errors.numeroDeDocumentoConyuge && "errorCampo"
                        }
                    />
                    {errors.numeroDeDocumentoConyuge && (
                        <p className={s.error}>
                            {errors.numeroDeDocumentoConyuge}
                        </p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>
                        Típo de identificación fiscal
                    </label>
                    {datosP?.TipoDeIdFiscalConyuge ? (
                        <Select
                            onChange={(e) => handleChange(e)}
                            name="TipoDeIdFiscalConyuge"
                            defaultValue={"DEFAULT"}
                            value={form.name}
                            className={
                                errors.tipoDeIdFiscalConyuge && "errorCampo"
                            }
                        >
                            {datosP.TipoDeIdFiscalConyuge === "CUIT/CUIL" && (
                                <>
                                    <option value="DEFAULT">CUIT/CUIL</option>
                                    <option
                                        disabled={subsanar}
                                    >
                                        Otro
                                    </option>
                                </>
                            )}
                            {datosP.TipoDeIdFiscalConyuge === "Otro" && (
                                <>
                                    <option value="DEFAULT">Otro</option>
                                    <option
                                        disabled={subsanar}
                                    >
                                        CUIT/CUIL
                                    </option>
                                </>
                            )}
                        </Select>
                    ) : (
                        <Select
                            onChange={(e) => handleChange(e)}
                            defaultValue={"DEFAULT"}
                            name="TipoDeIdFiscalConyuge"
                            value={form.name}
                            className={
                                errors.tipoDeIdFiscalConyuge && "errorCampo"
                            }
                        >
                            <option
                                value="DEFAULT"
                                className={s.option}
                                disabled
                            >
                                Selecciona
                            </option>
                            <option>CUIT/CUIL</option>
                            <option>Otro</option>
                        </Select>
                    )}

                    {errors.tipoDeIdFiscalConyuge && (
                        <p className={s.error}>
                            {errors.tipoDeIdFiscalConyuge}
                        </p>
                    )}
                </OptionContainer>

                <OptionContainer>
                    <label className={s.labelForm}>
                        Número de identificación fiscal
                    </label>
                    <Input
                        type="text"
                        name="NumeroDeIdFiscalConyuge"
                        value={form.name}
                        defaultValue={
                            datosP?.NumeroDeIdFiscalConyuge &&
                            datosP.NumeroDeIdFiscalConyuge
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Sin guiones"
                        {...(subsanar.length > 0 && {
                            readOnly: true,
                        })}
                        className={
                            errors.numeroDeIdFiscalConyuge && "errorCampo"
                        }
                    />

                    {errors.numeroDeIdFiscalConyuge && (
                        <p className={s.error}>
                            {errors.numeroDeIdFiscalConyuge}
                        </p>
                    )}
                </OptionContainer>
            </div>
        </div>
    );
};
