import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import {
	cambiarPantallaActual,
	getDatosPersonales,
} from "../../redux/actions";
import s from "./DatosPersonales.module.css";
import { useFormDatos } from "../../hooks/useFormDatos";
import { validationsForm } from "../../helpers/validacionesDatos";
import {
	DatosContainer,
	OptionContainer,
	Input,
	Select,
	Link,
	ContactoContainer,
} from "./datosElements";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { opciones } from "../../helpers/helperPaíses";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Icon } from "@iconify/react";
import { CotitularesForm } from "../../components/DatosPersonales/CotitularesForm";
import {
	arrayGenero,
	arrayGeneroFem,
	arrayGeneroMasc,
	arrayGeneroX,
	estadoCivil,
	estadoCivilCasado,
	estadoCivilDivorciado,
	estadoCivilSoltero,
	estadoCivilViudo,
	tipoDocumento,
	tipoDocumentoDNI,
	tipoDocumentoLC,
	tipoDocumentoLE,
	tipoDocumentoPasaporte,
} from "../../helpers/helperDatos";
import { ConyugeForm } from "../../components/DatosPersonales/ConyugeForm";
import { Contacto } from "../../components/Contacto/Contacto";

let fechaActual = new Date();
let añoActual = parseInt(fechaActual.getFullYear());
let mesActual = parseInt(fechaActual.getMonth() + 1);
let diaActual = parseInt(fechaActual.getDate());

if (diaActual < 10) {
	diaActual = "0" + diaActual;
}

if (mesActual < 10) {
	mesActual = "0" + mesActual;
}

let fechaLimite = `${añoActual - 18}-${mesActual}-${diaActual}`;

export const DatosPersonales = () => {
	const dispatch = useDispatch();
	const esReanudacion = useSelector(
		(state) => state.reanudarReducer.esReanudacion
	);
	const datosP = useSelector(
		(state) => state.datosEtapasReducer.datosPersonalesPrevios
	);
	const usuario = useSelector((state) => state.userDataReducer.usuario);
	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
	const { tipoCuenta } = useSelector((state) => state.userDataReducer);

	const usuarioNuevo = useSelector(
		(state) => state.userDataReducer.usuarioNuevo
	);

	const [loading, setLoading] = useState(true);

	const initialForm = {
		nombre: "",
		apellido: "",
		Genero: "",
		Nacionalidad: datosP?.Nacionalidad ? datosP.Nacionalidad : "",
		FechaNacimiento: "",
		LugarNacimiento: "",
		TipoDocumento: "",
		NumeroDNI: datosP.NumeroDNI ? datosP.NumeroDNI : "",
		TipoIdFiscal: "",
		NumeroIdFiscal: "",
		Calle: "",
		Numero: "",
		Piso: "",
		Localidad: "",
		Provincia_estado: "",
		CodigoPostal: "",
		Pais: "",
		EstadoCivil: "",
		NombreConyuge: "",
		ApellidoConyuge: "",
		GeneroConyuge: "",
		FechaDeNacimientoConyuge: "",
		TipoDeDocumentoConyuge: "",
		NumeroDeDocumentoConyuge: "",
		TipoDeIdFiscalConyuge: "",
		NumeroDeIdFiscalConyuge: "",
		Contacto: "",
		email_cotitular: [],
	};
	const { form, errors, handleBlur, handleChange, handleSubmit } =
		useFormDatos(initialForm, validationsForm, tipoCuenta);

	useEffect(() => {
		if (subsanar.length > 0) {
			dispatch(getDatosPersonales());
			if (datosP?._id) {
				setLoading(false);
			}
		} else if (usuario?.name) {
			setLoading(false);
		}
        
		dispatch(cambiarPantallaActual(0));
	}, [dispatch, subsanar, datosP?._id, usuario?.name]);

	const filterOptions = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option,
	});
	return (
        <>
            <DatosContainer>
                <h3 className={s.titulo}>DATOS PERSONALES</h3>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress style={{ color: "#004B6F" }} />
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit} autoComplete={"off"}>
                        <div className={s.seccion}>
                            {usuarioNuevo && (
                                <>
                                    <OptionContainer>
                                        <label className={s.labelForm}>
                                            Nombre/s
                                        </label>
                                        <Input
                                            type="text"
                                            name="nombre"
                                            value={form.name}
                                            defaultValue={
                                                subsanar?.includes("Nombre")
                                                    ? ""
                                                    : usuario?.name &&
                                                      usuario.name
                                            }
                                            onChange={handleChange}
                                            {...(subsanar.length > 0 &&
                                                !subsanar.includes(
                                                    "Nombre"
                                                ) && {
                                                    readOnly: true,
                                                })}
                                            className={
                                                errors.nombre && "errorCampo"
                                            }
                                        />
                                        {errors.nombre && (
                                            <p className={s.error}>
                                                {errors.nombre}
                                            </p>
                                        )}
                                    </OptionContainer>

                                    <OptionContainer>
                                        <label className={s.labelForm}>
                                            Apellido/s
                                        </label>

                                        <Input
                                            type="text"
                                            name="apellido"
                                            value={form.name}
                                            defaultValue={
                                                subsanar &&
                                                subsanar.includes("Apellido")
                                                    ? ""
                                                    : (usuario?.last_name ||
                                                          usuario?.lastName) &&
                                                      (usuario?.last_name ||
                                                          usuario?.lastName)
                                            }
                                            onChange={handleChange}
                                            {...(subsanar.length > 0 &&
                                                !subsanar.includes(
                                                    "Apellido"
                                                ) && {
                                                    readOnly: true,
                                                })}
                                            className={
                                                errors.apellido && "errorCampo"
                                            }
                                        />
                                        {errors.apellido && (
                                            <p className={s.error}>
                                                {errors.apellido}
                                            </p>
                                        )}
                                    </OptionContainer>
                                </>
                            )}

                            <OptionContainer>
                                <label className={s.labelForm}>Sexo</label>

                                {datosP?.Genero ? (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        name="Genero"
                                        defaultValue={"DEFAULT"}
                                        value={form.name}
                                        className={
                                            errors.genero && "errorCampo"
                                        }
                                    >
                                        {datosP.Genero === "Femenino" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Femenino
                                                </option>
                                                {arrayGeneroFem.map((e) => (
                                                    <option
                                                        disabled={subsanar}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.Genero === "Masculino" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Masculino
                                                </option>
                                                {arrayGeneroMasc.map((e) => (
                                                    <option
                                                        disabled={subsanar}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.Genero === "X" && (
                                            <>
                                                <option value="DEFAULT">
                                                    X
                                                </option>
                                                {arrayGeneroX.map((e) => (
                                                    <option
                                                        disabled={subsanar}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </Select>
                                ) : (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={"DEFAULT"}
                                        name="Genero"
                                        value={form.name}
                                        className={
                                            errors.genero && "errorCampo"
                                        }
                                    >
                                        <option
                                            value="DEFAULT"
                                            className={s.option}
                                        >
                                            Selecciona
                                        </option>
                                        {arrayGenero.map((e) => (
                                            <option key={e}>{e}</option>
                                        ))}
                                    </Select>
                                )}
                                {errors.genero && (
                                    <p className={s.error}>{errors.genero}</p>
                                )}
                            </OptionContainer>
                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Nacionalidad
                                </label>
                                <label>
                                    <Autocomplete
                                        sx={{ width: "100%" }}
                                        id="nacionalidad"
                                        options={opciones}
                                        filterOptions={filterOptions}
                                        noOptionsText="No hay coincidencias"
                                        onChange={(e, newValue) =>
                                            handleChange(e, newValue)
                                        }
                                        name="Nacionalidad"
                                        value={form.name}
                                        {...(subsanar.length > 0 &&
                                            !subsanar.includes(
                                                "Nacionalidad"
                                            ) && {
                                                disabled: true,
                                            })}
                                        {...((!subsanar.length > 0 ||
                                            !subsanar?.includes(
                                                "Nacionalidad"
                                            )) &&
                                            datosP && {
                                                defaultValue:
                                                    datosP?.Nacionalidad,
                                            })}
                                        renderInput={(params) => (
                                            <div
                                                ref={params.InputProps.ref}
                                                style={{ width: "100%" }}
                                            >
                                                <Input
                                                    type="text"
                                                    {...params.inputProps}
                                                    style={{ width: "100%" }}
                                                    className={
                                                        errors.nacionalidad &&
                                                        "errorCampo"
                                                    }
                                                />
                                            </div>
                                        )}
                                    />
                                </label>
                                {errors.nacionalidad && (
                                    <p className={s.error}>
                                        {errors.nacionalidad}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Fecha de nacimiento
                                </label>

                                <Input
                                    type="date"
                                    max={fechaLimite}
                                    className={
                                        errors.fechaDeNacimiento && "errorCampo"
                                    }
                                    name="FechaNacimiento"
                                    value={form.name}
                                    defaultValue={
                                        subsanar?.includes(
                                            "Fecha de nacimiento"
                                        )
                                            ? ""
                                            : datosP?.FechaNacimiento &&
                                              datosP.FechaNacimiento
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes(
                                            "Fecha de nacimiento"
                                        ) && { readOnly: true })}
                                />

                                {errors.fechaDeNacimiento && (
                                    <p className={s.error}>
                                        {errors.fechaDeNacimiento}
                                    </p>
                                )}
                            </OptionContainer>
                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Lugar de nacimiento
                                </label>
                                <Input
                                    type="text"
                                    name="LugarNacimiento"
                                    value={form.name}
                                    defaultValue={
                                        subsanar?.includes(
                                            "Lugar de nacimiento (como figura en el DNI)"
                                        )
                                            ? ""
                                            : datosP?.LugarNacimiento &&
                                              datosP.LugarNacimiento
                                    }
                                    placeholder="como figura en el DNI"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes(
                                            "Lugar de nacimiento (como figura en el DNI)"
                                        ) && { readOnly: true })}
                                    className={
                                        errors.lugarDeNacimiento && "errorCampo"
                                    }
                                />
                                {errors.lugarDeNacimiento && (
                                    <p className={s.error}>
                                        {errors.lugarDeNacimiento}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Tipo de documento
                                </label>
                                {!subsanar?.includes("Documento") &&
                                datosP?.TipoDocumento ? (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        name="TipoDocumento"
                                        defaultValue={"DEFAULT"}
                                        value={form.name}
                                        className={
                                            errors.tipoDeDocumento &&
                                            "errorCampo"
                                        }
                                    >
                                        {datosP.TipoDocumento === "DNI" && (
                                            <>
                                                <option value="DEFAULT">
                                                    DNI
                                                </option>
                                                {tipoDocumentoDNI.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Documento"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.TipoDocumento ===
                                            "Pasaporte" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Pasaporte
                                                </option>
                                                {tipoDocumentoPasaporte.map(
                                                    (e) => (
                                                        <option
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Documento"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            key={e}
                                                        >
                                                            {e}
                                                        </option>
                                                    )
                                                )}
                                            </>
                                        )}
                                        {datosP.TipoDocumento === "LE" && (
                                            <>
                                                <option value="DEFAULT">
                                                    LE
                                                </option>

                                                {tipoDocumentoLE.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Documento"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.TipoDocumento === "LC" && (
                                            <>
                                                <option value="DEFAULT">
                                                    LC
                                                </option>

                                                {tipoDocumentoLC.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Documento"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </Select>
                                ) : (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={"DEFAULT"}
                                        name="TipoDocumento"
                                        value={form.name}
                                        className={
                                            errors.tipoDeDocumento &&
                                            "errorCampo"
                                        }
                                    >
                                        <option
                                            value="DEFAULT"
                                            className={s.option}
                                            disabled
                                        >
                                            Selecciona
                                        </option>

                                        {tipoDocumento.map((e) => (
                                            <option key={e}>{e}</option>
                                        ))}
                                    </Select>
                                )}

                                {errors.tipoDeDocumento && (
                                    <p className={s.error}>
                                        {errors.tipoDeDocumento}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Número de documento
                                </label>
                                <Input
                                    type="text"
                                    name="NumeroDNI"
                                    defaultValue={
                                        subsanar?.includes("Documento")
                                            ? ""
                                            : datosP?.NumeroDNI &&
                                              datosP.NumeroDNI
                                    }
                                    placeholder={
                                        subsanar?.includes("Documento")
                                            ? ""
                                            : datosP?.NumeroDNI &&
                                              datosP.NumeroDNI
                                    }
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Documento") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.numeroDeDocumento && "errorCampo"
                                    }
                                />
                                {errors.numeroDeDocumento && (
                                    <p className={s.error}>
                                        {errors.numeroDeDocumento}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Tipo de identificación fiscal
                                </label>
                                {!subsanar?.includes("Documento") &&
                                datosP?.TipoIdFiscal ? (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        name="TipoIdFiscal"
                                        defaultValue={"DEFAULT"}
                                        value={form.name}
                                        className={
                                            errors.tipoDeIdFiscal &&
                                            "errorCampo"
                                        }
                                    >
                                        {datosP.TipoIdFiscal ===
                                            "CUIT/CUIL" && (
                                            <>
                                                <option value="DEFAULT">
                                                    CUIT/CUIL
                                                </option>
                                                <option
                                                    {...(subsanar.length > 0 &&
                                                        !subsanar.includes(
                                                            "Documento"
                                                        ) && {
                                                            disabled: true,
                                                        })}
                                                >
                                                    Otro
                                                </option>
                                            </>
                                        )}
                                        {datosP.TipoIdFiscal === "Otro" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Otro
                                                </option>
                                                <option
                                                    {...(subsanar.length > 0 &&
                                                        !subsanar.includes(
                                                            "Documento"
                                                        ) && {
                                                            disabled: true,
                                                        })}
                                                >
                                                    CUIT/CUIL
                                                </option>
                                            </>
                                        )}
                                    </Select>
                                ) : (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={"DEFAULT"}
                                        name="TipoIdFiscal"
                                        value={form.name}
                                        className={
                                            errors.tipoDeIdFiscal &&
                                            "errorCampo"
                                        }
                                    >
                                        <option
                                            value="DEFAULT"
                                            className={s.option}
                                            disabled
                                        >
                                            Selecciona
                                        </option>
                                        <option>CUIT/CUIL</option>
                                        <option>Otro</option>
                                    </Select>
                                )}

                                {errors.tipoDeIdFiscal && (
                                    <p className={s.error}>
                                        {errors.tipoDeIdFiscal}
                                    </p>
                                )}
                            </OptionContainer>
                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Número de identificación fiscal
                                </label>
                                <Input
                                    type="text"
                                    name="NumeroIdFiscal"
                                    defaultValue={
                                        subsanar?.includes("Documento")
                                            ? ""
                                            : datosP?.NumeroIdFiscal &&
                                              datosP.NumeroIdFiscal
                                    }
                                    placeholder={
                                        subsanar?.includes("Documento")
                                            ? "Sin guiones"
                                            : datosP?.NumeroIdFiscal ?
                                            datosP.NumeroIdFiscal :
                                            "Sin guiones"
                                    }
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Documento") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.numeroDeIdFiscal && "errorCampo"
                                    }
                                />
                                {errors.numeroDeIdFiscal && (
                                    <p className={s.error}>
                                        {errors.numeroDeIdFiscal}
                                    </p>
                                )}
                            </OptionContainer>
                        </div>
                        <h4 className={s.subtitulo}>DOMICILIO REAL</h4>
                        <h4 className={s.sub}>
                            Domicilio en el que vivís actualmente
                        </h4>
                        <div className={s.seccion}>
                            <OptionContainer>
                                <label className={s.labelForm}>Calle</label>
                                <Input
                                    type="text"
                                    name="Calle"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Calle && datosP.Calle
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.domicilioCalle && "errorCampo"
                                    }
                                />
                                {errors.domicilioCalle && (
                                    <p className={s.error}>
                                        {errors.domicilioCalle}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>Número</label>
                                <Input
                                    type="text"
                                    name="Numero"
                                    value={form.name}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Numero && datosP.Numero
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={
                                        errors.domicilioNumero && "errorCampo"
                                    }
                                />
                                {errors.domicilioNumero && (
                                    <p className={s.error}>
                                        {errors.domicilioNumero}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Piso / Dpto
                                </label>
                                <Input
                                    type="text"
                                    name="Piso"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Piso && datosP.Piso
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                />
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>Localidad</label>
                                <Input
                                    type="text"
                                    name="Localidad"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Localidad &&
                                              datosP.Localidad
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.domicilioLocalidad &&
                                        "errorCampo"
                                    }
                                />
                                {errors.domicilioLocalidad && (
                                    <p className={s.error}>
                                        {errors.domicilioLocalidad}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Provincia/Estado
                                </label>
                                <Input
                                    type="text"
                                    name="Provincia_estado"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Provincia_estado &&
                                              datosP.Provincia_estado
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.domicilioProvinciaEstado &&
                                        "errorCampo"
                                    }
                                />
                                {errors.domicilioProvinciaEstado && (
                                    <p className={s.error}>
                                        {errors.domicilioProvinciaEstado}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Código postal
                                </label>

                                <Input
                                    type="text"
                                    name="CodigoPostal"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.CodigoPostal &&
                                              datosP.CodigoPostal
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.domicilioCD && "errorCampo"
                                    }
                                />
                                <div className={s.locationIconContainer}>
                                    <Link
                                        href="https://www.correoargentino.com.ar/formularios/cpa"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="ic:baseline-not-listed-location" />
                                    </Link>
                                </div>
                                {errors.domicilioCD && (
                                    <p
                                        className={s.error}
                                        style={{
                                            position: "relative",
                                            bottom: "1.5em",
                                        }}
                                    >
                                        {errors.domicilioCD}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>País</label>
                                <Input
                                    type="text"
                                    name="Pais"
                                    value={form.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    defaultValue={
                                        subsanar?.includes("Domicilio")
                                            ? ""
                                            : datosP?.Pais && datosP.Pais
                                    }
                                    {...(subsanar.length > 0 &&
                                        !subsanar.includes("Domicilio") && {
                                            readOnly: true,
                                        })}
                                    className={
                                        errors.domicilioPais && "errorCampo"
                                    }
                                />
                                {errors.domicilioPais && (
                                    <p className={s.error}>
                                        {errors.domicilioPais}
                                    </p>
                                )}
                            </OptionContainer>

                            <OptionContainer>
                                <label className={s.labelForm}>
                                    Estado civil
                                </label>

                                {datosP?.EstadoCivil ? (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        name="EstadoCivil"
                                        defaultValue={"DEFAULT"}
                                        value={form.name}
                                        className={
                                            errors.estadoCivil && "errorCampo"
                                        }
                                    >
                                        {datosP.EstadoCivil === "Soltero/a" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Soltero/a
                                                </option>
                                                {estadoCivilSoltero.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 && {
                                                            disabled: true,
                                                        })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.EstadoCivil === "Casado/a" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Casado/a
                                                </option>
                                                {estadoCivilCasado.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 && {
                                                            disabled: true,
                                                        })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                        {datosP.EstadoCivil ===
                                            "Divorciado/a" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Divorciado/a
                                                </option>
                                                {estadoCivilDivorciado.map(
                                                    (e) => (
                                                        <option
                                                            {...(subsanar.length >
                                                                0 && {
                                                                disabled: true,
                                                            })}
                                                            key={e}
                                                        >
                                                            {e}
                                                        </option>
                                                    )
                                                )}
                                            </>
                                        )}
                                        {datosP.EstadoCivil === "Viudo/a" && (
                                            <>
                                                <option value="DEFAULT">
                                                    Viudo/a
                                                </option>
                                                {estadoCivilViudo.map((e) => (
                                                    <option
                                                        {...(subsanar.length >
                                                            0 && {
                                                            disabled: true,
                                                        })}
                                                        key={e}
                                                    >
                                                        {e}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </Select>
                                ) : (
                                    <Select
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={"DEFAULT"}
                                        name="EstadoCivil"
                                        value={form.name}
                                        className={
                                            errors.estadoCivil && "errorCampo"
                                        }
                                    >
                                        <option
                                            value="DEFAULT"
                                            className={s.option}
                                            disabled
                                        >
                                            Selecciona
                                        </option>
                                        {estadoCivil.map((e) => (
                                            <option key={e}>{e}</option>
                                        ))}
                                    </Select>
                                )}

                                {errors.estadoCivil && (
                                    <p className={s.error}>
                                        {errors.estadoCivil}
                                    </p>
                                )}
                            </OptionContainer>
                        </div>

                        {(form.EstadoCivil === "Casado/a" ||
                            datosP?.EstadoCivil === "Casado/a") && (
                            <ConyugeForm
                                form={form}
                                datosP={datosP}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                subsanar={subsanar}
                                errors={errors}
                                fechaLimite={fechaLimite}
                            />
                        )}
                        {
                            usuarioNuevo &&
                            <CotitularesForm
                                value={form.email_cotitular}
                                onChange={handleChange}
                                defaultValue={
                                    subsanar?.includes("cotitulares")
                                        ? undefined
                                        : datosP?.email_cotitular &&
                                          datosP.email_cotitular
                                }
                                mailTitular={usuario.email}
                            />
                        }
                    
                        {errors.emailCotitular && (
                            <p
                                style={{ marginLeft: "24px" }}
                                className={s.error}
                            >
                                {errors.emailCotitular}
                            </p>
                        )}

                        <ContactoContainer>
                            <p className={s.contactoTitle}>
                                ¿CÓMO NOS CONOCISTE?
                            </p>

                            <div className={s.contactoForm}>
                                <div className={s.optionContainer}>
                                    <input
                                        className={s.input}
                                        type="radio"
                                        id="a"
                                        name="Contacto"
                                        value="referidos"
                                        defaultChecked={
                                            datosP?.Contacto === "referidos"
                                                ? true
                                                : false
                                        }
                                        onClick={handleChange}
                                    />
                                    <label htmlFor="a" className={s.label}>
                                        Referidos
                                    </label>
                                </div>
                                <div className={s.optionContainer}>
                                    <input
                                        className={s.input}
                                        type="radio"
                                        id="b"
                                        name="Contacto"
                                        value="redes"
                                        defaultChecked={
                                            datosP?.Contacto === "redes"
                                                ? true
                                                : false
                                        }
                                        onClick={handleChange}
                                    />
                                    <label htmlFor="b" className={s.label}>
                                        Redes sociales
                                    </label>
                                </div>
                                <div className={s.optionContainer}>
                                    <input
                                        className={s.input}
                                        type="radio"
                                        id="c"
                                        name="Contacto"
                                        value="prensa/tv"
                                        defaultChecked={
                                            datosP?.Contacto === "prensa/tv"
                                                ? true
                                                : false
                                        }
                                        onClick={handleChange}
                                    />
                                    <label htmlFor="c" className={s.label}>
                                        Prensa / TV
                                    </label>
                                </div>
                                <div className={s.optionContainer}>
                                    <input
                                        className={s.input}
                                        type="radio"
                                        id="d"
                                        name="Contacto"
                                        value="otros"
                                        defaultChecked={
                                            datosP?.Contacto === "otros"
                                                ? true
                                                : false
                                        }
                                        onClick={handleChange}
                                    />
                                    <label htmlFor="d" className={s.label}>
                                        Otros
                                    </label>
                                </div>
                                {errors.contacto && (
                                    <p className={s.errorContacto}>
                                        {errors.contacto}
                                    </p>
                                )}
                            </div>
                        </ContactoContainer>
                        <div className={s.botonContainer}>
                            <Button
                                className={s.boton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                Continuar
                            </Button>
                        </div>
                    </form>
                )}
            </DatosContainer>
            <Contacto />
        </>
    );
};
