import styled, { css } from "styled-components";

export const Link = styled.a`
    color: ${({theme}) => theme.fontEspecialColor};
	text-decoration: none;
    font-weight: bold;
`

export const DatosContainer = styled.div`
	margin: 1.5em;

	@media (min-width: 992px) {
		margin: 1.5em 12em;
	}
	@media (min-width: 1440px) {
		margin: 1.5em 20em;
	}
`;

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 992px) {
		width: 45%;
	}
`;

export const ContactoContainer = styled.div`
	padding: 1.5em 1em 1.5em 2em;
    margin: 1.5em 0;
	background-color: ${({theme}) => theme.backgroundResultadoTest};
	border-radius: 0.5em;

	@media (min-width: 992px) {
		margin: 1.5em;
		padding: 1.5em 1em 1.5em 2.8em;
	}
	@media (min-width: 1440px) {
		margin: 1.5em;
	}
`;

// export const Input = styled.input`
//     background: ${({theme}) => theme.background};
// 	box-shadow: inset -4px -4px 9px ${({theme}) => theme.inputShadow}, inset 4px 4px 14px ${({theme}) => theme.inputShadow2};
// 	border-radius: 5px;
// 	border:  0.1px solid ${({theme}) => theme.bordesInput};
// 	padding: 0.8em;
//     color: ${({theme}) => theme.fontColor};
//     @media (max-width:992px) {
//         font-size: 14px;
//     }
// `;

export const Input = styled.input`
background: ${({theme}) => theme.backgroundInput};
	box-shadow: 4px 4px 10px 0px ${({theme}) => theme.inputShadow2} inset;
	border-radius: 5px;
	padding: 0.8em;
	color: ${({ theme }) => theme.fontColor};	
	border-width: 0.1px;
    border-style: solid;
	${props => props.className !== 'errorCampo' ? css`border-color:  ${({theme}) => theme.bordesInput}` : css`border-color: ${({theme}) => theme.errorBorder}`};
	@media (max-width: 992px) {
		font-size: 14px;
	}
`;

export const InputTotalWidth = styled.input`
	background: ${({ theme }) => theme.background};
	box-shadow: inset -4px -4px 9px ${({ theme }) => theme.inputShadow},
		inset 4px 4px 14px ${({ theme }) => theme.inputShadow2};
	border-radius: 5px;
	border-radius: 5px;
	border-width: 0.1px;
    border-style: solid;
	${props => !props.className.includes('errorCampo') ? css`border-color:  ${({theme}) => theme.bordesInput}` : css`border-color: ${({theme}) => theme.errorBorder}`};
	padding: 0.8em;
	width: 100%;
	color: ${({ theme }) => theme.fontColor}; 
`;

export const InputTotalWidthSms = styled.input`
	background: ${({ theme }) => theme.background};
	box-shadow: inset -4px -4px 9px ${({ theme }) => theme.inputShadow},
		inset 4px 4px 14px ${({ theme }) => theme.inputShadow2};
	border-radius: 5px;
	border-radius: 5px;
	border-width: 0.1px;
    border-style: solid;
	padding: 0.8em;
	width: 100%;
	color: ${({ theme }) => theme.fontColor}; 
`;

export const Select = styled.select`
	background: ${({ theme }) => theme.background};
	box-shadow: inset -4px -4px 9px ${({ theme }) => theme.inputShadow},
		inset 4px 4px 14px ${({ theme }) => theme.inputShadow2};
	border-radius: 5px;
	border-width: 0.1px;
    border-style: solid;
	border-color: ${props => props.className === 'errorCampo' ? '#E3342' : `${({theme}) => theme.bordesInput}`};
	padding: 0.8em;
	color: ${({ theme }) => theme.fontColor};
	overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url(${({ theme }) => theme.urlIconSelect});
	// background-image: url("https://api.iconify.design/ep/arrow-down-bold.svg");
	background-repeat: no-repeat, repeat;
	background-position: right 0.9em top 50%, 0 0;
	background-size: 0.85em auto, 100%;
	${props => props.className !== 'errorCampo' ? css`border-color:  ${({theme}) => theme.bordesInput}` : css`border-color: ${({theme}) => theme.errorBorder}`};
`;

export const SelectTotalWidth = styled.select`
	background: ${({ theme }) => theme.background};
	box-shadow: inset -4px -4px 9px ${({ theme }) => theme.inputShadow},
		inset 4px 4px 14px ${({ theme }) => theme.inputShadow2};
	border-radius: 5px;
	border: 0.1px solid ${({ theme }) => theme.fontEspecialColor};
	padding: 0.8em;
	width: 100%;
	color: ${({ theme }) => theme.fontColor}; ;
`;
