import React, { useEffect } from "react";
import s from "./UsuarioValidado.module.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { cambiarPantallaActual } from "../../redux/actions";

const UsuarioValidado = () => {
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);
	const dispatch = useDispatch();

	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
	const usuarioNuevo = useSelector((state) => state.userDataReducer.usuarioNuevo);
    const cotitular = useSelector((state) => state.userDataReducer.cotitular);

	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
		//clear local storage when render this component
		// function clearLocalStorage() {
		// 	localStorage.clear();
		// }
		// clearLocalStorage();
	}, []);

	return (
        <div className={s.contenedorPrincipal}>
            <Icon
                icon="la:hands-helping"
                style={{
                    fontSize: "56px",
                    color: `${({ theme }) => theme.fontColor}`,
                }}
            />
            {usuarioNuevo ? (
                subsanar?.length > 0 ? (
                    <h3 className={s.titulo}>
                        ¡GRACIAS POR REALIZAR LOS CAMBIOS!
                    </h3>
                ) : (
                    <h3 className={s.titulo}>¡FELICITACIONES!</h3>
                )
            ) : (
                <h3 className={s.titulo}>¡LISTO!</h3>
            )}

            {usuarioNuevo ? (
                subsanar?.length > 0 ? (
                    <p className={s.texto}>
                        {" "}
                        Te volveremos a contactar cuando hayamos analizado la
                        información que nos compartiste.
                    </p>
                ) : cotitular ? (
                    <p className={s.texto}>
                        {" "}
                        Ya validaste tu número de teléfono y completaste de
                        manera exitosa la solicitud de incorporación a la cuenta
                        n° xxxxx en Rava Bursátil. Vamos a estar contactándote a
                        la brevedad para que puedas empezar a invertir.
                    </p>
                ) : (
                    <p className={s.texto}>
                        {" "}
                        Ya validaste tu número de teléfono y completaste de
                        manera exitosa la apertura de cuenta en Rava Bursátil.
                        Vamos a estar contactándote a la brevedad para que
                        puedas empezar a invertir.
                    </p>
                )
            ) : (
                <p className={s.texto}>
                    {" "}
                    Ya validaste tu número de teléfono y completaste de manera
                    exitosa la actualización de los datos de tu cuenta.
                </p>
            )}
            <a
                href="https://wa.me/5491155270400"
                target="_blank"
                rel="noreferrer"
                className={s.link}
            >
                ¿Tenés dudas o consultas? Comunicate con Pamela, nuestra coordinadora comercial.
            </a>

            <a
                href="https://www.rava.com/"
                style={
                    darkModeRedux ? { color: "white" } : { color: "#004B6F" }
                }
                className={s.retorno}
            >
                VOLVER AL INICIO
            </a>
        </div>
    );
};

export default UsuarioValidado;
