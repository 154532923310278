import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import s from "./ConfirmacionMail.module.css";
import { cambiarPantallaActual, putTipoCuenta } from "../../redux/actions";
import apiRoutes from "../../api/apiRoutes";
import { useForm } from "../../hooks/useForm";
import { Aside } from "../../components/Aside/Aside";
import { Input } from "../DatosPersonales/datosElements";
import { ErrorText } from "../DDJJ/DDJJElements";

export const ConfirmacionMail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [cargando, setCargando] = React.useState(false);
	const [errors, setErrors] = useState({});
	const user = useSelector((state) => state.userDataReducer.usuario);
	const [formValues, handleInputChange] = useForm({
		code: "",
	});
	const { code } = formValues;

	useEffect(() => {
		setCargando(true);
		dispatch(cambiarPantallaActual(-1));
	}, [dispatch]);

	useEffect(() => {
		if (user && user.email) {
			setCargando(false);
		}
	}, [user]);

	const email = () => {
		if (user && user.email) {
			return (
				<div className={s.subtitulo}>
					Revisá tu email {user.email} y seguí las instrucciones para
					continuar con la apertura.
				</div>
			);
		} else {
			return (
				<div className={s.subtitulo}>
					Revisá tu email y seguí las instrucciones para continuar con
					la apertura.
				</div>
			);
		}
	};
	const handleLogin = async (e) => {
		e.preventDefault();

		if (formValues.code) {
			try {
				const response = await apiRoutes.validation(formValues.code);
				// navigate("/tipo-cuenta/");
				navigate("/datos-personales");
			} catch (error) {
				if (
					error.response.data.msj ===
					"El codigo ingresado es incorrecto"
				) {
					setErrors({
						invalido: "Código Incorrecto",
					});
				}
			}
		} else {
			setErrors({
				invalido: "Ingresar Código",
			});
		}
	};

	const spinnerCarga = () => {
		if (cargando) {
			return (
				<Box sx={{ display: "flex", mt: 4 }}>
					<CircularProgress style={{ color: "#004B6F" }} />
				</Box>
			);
		} else {
			return (
				<>
					<Box
						sx={{
							pt: 2,
						}}
					>
						<SendOutlinedIcon
							style={{
								color: `${({ theme }) => theme.fontColor}`,
							}}
							fontSize="large"
							className={s.icono}
						/>
					</Box>
					<div className={s.contenedorTexto}>
						<span className={s.titulo}>
							{" "}
							<b> ¡TE ENVIAMOS UN MAIL!</b>
						</span>
						<Box
							sx={{
								mt: 1,
							}}
						>
							{email()}
						</Box>
					</div>
					<form onSubmit={handleLogin} className={s.contenedorMail}>
						<Box sx={{ textAlign: "left", mb: 1 }}>
							<span className={s.label}> Código </span>
						</Box>
						<Input
							type="text"
							name="code"
							autoComplete="off"
							value={code}
							onChange={handleInputChange}
						/>
						{errors.invalido && (
							<ErrorText style={{ padding: 0, marginTop: "8px" }}>
								{errors.invalido}
							</ErrorText>
						)}
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								mb: 2,

								"@media (min-width:992px)": {
									padding: "0.2em 4em",
								},
								"@media (min-width:1440px)": {
									padding: "0.2em 6em",
								},
							}}
						>
							<Button
								type="submit"
								className="boton-continuar"
								size="large"
								color="primary"
								variant="contained"
								fullWidth
							>
								Continuar
							</Button>
						</Box>
					</form>
					<span className={s.subtitulo}>
						Volver a empezar <a href="/"> acá </a>
					</span>
				</>
			);
		}
	};

	return (
		<div className={s.contenedorPrincipal}>
			{/* <Aside /> */}

			<div className={s.contenedorFormulario}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					{spinnerCarga()}
				</Box>
			</div>
		</div>
	);
};
