import React from "react";
import s from "./Aside.module.css";

export const Aside = () => {
	return (
		<div className={s.container}>
			<div className={s.imgBox}>
				<img
					src={require("../../images/slide_1.png")}
					alt="slide-13"
					className={s.imagen}
				/>
				<a href="https://www.rava.com/nuestros-servicios/aranceles">
					<img
						src={require("../../images/slide_2.png")}
						alt="slide-2"
						className={s.imagen}
					/>
				</a>
				<img
					src={require("../../images/slide_4.png")}
					alt="slide-4"
					className={s.imagen}
				/>
                <a href="http://foro.rava.com/">
				<img
					src={require("../../images/slide_3.png")}
					alt="slide-3"
					className={s.imagen}
				/>
                </a>
				
			</div>
		</div>
	);
};
