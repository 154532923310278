/**
 * Función para validar el cuit de una persona
 * @param {string} cuit Cuit de la persona
 * @returns {boolean} Verdadero o falso si está correcto o no
 */

export const validarCuit = (cuit) => {
	var c1 = cuit.substring(0, 2);
	var c2 = cuit.substring(2, 10);
	var c3 = cuit.substring(10, 11);
	if (isNaN(c1) || isNaN(c2) || isNaN(c3) || c2 < 100000 || c2 > 100000000) {
		return false;
	}

	var aMult = "6789456789";
	var aMult = aMult.split("");
	var sCUIT = c1 + c2 + c3;

	var iResult = 0;
	var aCUIT = sCUIT.split("");

	if (aCUIT.length == 11) {
		// La suma de los productos
		for (var i = 0; i <= 9; i++) {
			iResult += aCUIT[i] * aMult[i];
		}
		// El módulo de 11
		iResult = iResult % 11;

		// Se compara el resultado con el dígito verificador
		//alert(iResult + " "+ aCUIT[10]);
		if (iResult != aCUIT[10]) {
			return false;
		}
	} else {
		return false;
	}
	return true;
};
