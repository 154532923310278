const LightTheme = {
	background: "white",
	fontColor: "#004b6f",
	fontAperturaColor: "#282B30",
	fontEspecialColor: "#004b6f",
	backgroundResultadoTest: "#EBEBED",
	inputShadow: "#ffffff99",
	inputShadow2: "#EBEBED",
	disabledButton: "#CECECE",
	disabledFont: "#EBEBED",
	errorColor: "#E3342F",
	linkColor: "013d58",
	errorFondo: "#FDEDED",
	colorAutocomplete: "#FAF7F7",
	politicaFontColor: "#004B6F",
	backgroundContainerHome: "white",
	bordesInput: "#F1EFEF",
	backgroundInput: "white",
	urlIconSelect: "https://api.iconify.design/ep/arrow-down-bold.svg",
	iconDateColor: "none",
	iconArrowDeclaracionesColor: "black",
	labelStepper: "#949494",
	errorBorder: "#E3342F",
};

const DarkTheme = {
	background: "#282B30",
	fontColor: "white",
	fontAperturaColor: "white",
	fontEspecialColor: "#FFC74F",
	backgroundResultadoTest: "#404041",
	inputShadow: "#282B30",
	inputShadow2: "#161723",
	disabledButton: "#35373b",
	disabledFont: "white",
	linkColor: "#FFC74F",
	errorColor: "#FFF",
	errorFondo: "#9B1B19",
	colorAutocomplete: "#434344",
	politicaFontColor: "#f6b40e",
	backgroundContainerHome: "#FFFFFF26",
	bordesInput: "#30343C",
	backgroundInput: "#43434403",
	urlIconSelect:
		"https://api.iconify.design/ep/arrow-down-bold.svg?color=white",
	iconDateColor: "invert(1)",
	iconArrowDeclaracionesColor: "white",
	labelStepper: "white",
	errorBorder: "#E3342F",
};

export const Themes = {
	light: LightTheme,
	dark: DarkTheme,
};

