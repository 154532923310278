// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppBar_logo__7qqCO {\n\twidth: 30%;\n    margin-left: 12px;\n}\n@media screen and (min-width: 992px) {\n    .AppBar_logo__7qqCO {\n        width: 13%;\n    }\n}\n    ", "",{"version":3,"sources":["webpack://./src/components/AppBar/AppBar.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;IACP,iBAAiB;AACrB;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".logo {\n\twidth: 30%;\n    margin-left: 12px;\n}\n@media screen and (min-width: 992px) {\n    .logo {\n        width: 13%;\n    }\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "AppBar_logo__7qqCO"
};
export default ___CSS_LOADER_EXPORT___;
