import { useState } from 'react';

/**
 * Hook que manipula el formulario de confirmacion de email y Form validacion sms
 * @param {Objetc} initialForm Formulario inicial
 * @param {Object} validateForm Función validadora que devuelque los errores
 * @returns {Objetc} 
 */


export const useForm = ( initialState = {} ) => {
    
    const [values, setValues] = useState(initialState);

    const reset = () => {
        setValues( initialState );
    }


    const handleInputChange = ({ target }) => {

        setValues({
            ...values,
            [ target.name ]: target.value
        });

    }

    return [ values, handleInputChange, reset ];

}