import imageCompression from "browser-image-compression";
//comprime imagenes

/**
 * Función para comprimir imagenes
 * @param {File} fileToComp Imagen
 * @returns {File | boolean} Archivo comprimido o boolean false si hubo un error
 */
export async function reduceImageSize(fileToComp) {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		const compressedFile = await imageCompression(fileToComp, options);
		/* 		console.log(
			`compressedFile size ${compressedFile.size / 1024 / 1024} MB`
		);  */
		// smaller than maxSizeMB
		var file = new File([compressedFile], compressedFile.name);
		return file;
	} catch (error) {
		console.log(error);
		return false;
	}
}
