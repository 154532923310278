import {
	GET_RESULTADO_TEST,
	GET_USER_DATA,
	GET_DATOS_PERSONALES,
	SAVE_DATOS_PERSONALES,
	GET_TERMINOS,
	GET_DDJJ,
	GET_ETAPAS,
	GET_IMAGENES,
	POST_IMAGEN_FRENTE,
	POST_IMAGEN_SELFIE,
	POST_IMAGEN_DORSO,
	SAVE_DDJJ,
	CAMBIAR_NUMERO_TELEFONO,
	CAMBIAR_DARK_MODE,
	CAMBIAR_PANTALLA_ACTUAL,
	SAVE_RESULTADO_TEST,
	SET_REANUDAR,
	RESTART_STORE,
	LIMPIAR_FOTO_DORSO,
	LIMPIAR_FOTO_FRENTE,
	LIMPIAR_FOTO_SELFIE,
	PUT_NOMBRE,
	GET_ERRORES,
	GET_OLD_USER_DATA,
	GET_COTITULAR_DATA,
	PUT_TIPOCUENTA,
	SET_COTITULAR_NUEVOS_DATA,
	SET_DNI,
} from "../constantes";
import apiRoutes from "../../api/apiRoutes";

// User data

export function getUserData(data) {
	return async (dispach) => {
		try {
			apiRoutes.signup(data).then((res) => {
				localStorage.setItem("token", res.data.token);
				data.code = res.data.code;
				return dispach({
					type: GET_USER_DATA,
					payload: data,
				});
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function getOldUserData(data) {
	return async (dispach) => {
		try {
			apiRoutes.signupClientOld(data).then((res) => {
				localStorage.setItem("token", res.data.token);
				data.code = res.data.code;
				return dispach({
					type: GET_OLD_USER_DATA,
					payload: data,
				});
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function getCotitularData(data, code) {
	return async (dispach) => {
		try {
			// apiRoutes.signupCotitulares(data).then((res) => {
			// 	localStorage.setItem("token", res.data.token);
			// 	data.code = res.data.code;
			// });
			data.code = code;
			return dispach({
				type: GET_COTITULAR_DATA,
				payload: data,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function setCotitularNuevosData(data) {
	return async (dispach) => {
		try {
			return dispach({
				type: SET_COTITULAR_NUEVOS_DATA,
				payload: data,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function putUserData(name, last_name) {
	return async (dispach) => {
		try {
			const res = await apiRoutes.putNombre(name, last_name);

			return dispach({
				type: PUT_NOMBRE,
				payload: res.data,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function putTipoCuenta(tipoCuenta) {
	return async (dispach) => {
		try {
			return dispach({
				type: PUT_TIPOCUENTA,
				payload: tipoCuenta,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function setNuevoNumero(payload) {
	return async function (dispach) {
		try {
			return dispach({
				type: CAMBIAR_NUMERO_TELEFONO,
				payload,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function getUserDataReanudacion(data, token) {
	return async (dispach) => {
		try {
			let datos = {};
			datos.name = data.name;
			datos.lastName = data.last_name;
			datos.email = data.email;
			datos.phone = data.phone;
			datos.token = token;
			datos.tipocuenta = data.tipocuenta;
			localStorage.setItem("token", token);
			return dispach({
				type: GET_USER_DATA,
				payload: datos,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function setReanudar(boolean) {
	return (dispach) => {
		return dispach({
			type: SET_REANUDAR,
			payload: boolean,
		});
	};
}

//Subsanar

export function getEtapasCompletas(payload) {
	return function (dispach) {
		return dispach({
			type: GET_ETAPAS,
			payload,
		});
	};
}

export function getErrores(campos, rutas, rutasSubsanar) {
	return async function (dispach) {
		try {
			return dispach({
				type: GET_ERRORES,
				payload: { campos, rutas, rutasSubsanar },
			});
		} catch (error) {
			console.log(error);
		}
	};
}

//Datos Personales

export function getDatosPersonales() {
	return async function (dispach) {
		try {
			const res = await apiRoutes.getDatosPersonales();
			return dispach({
				type: GET_DATOS_PERSONALES,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};
}

export function setDni(dni) {
	return function (dispach) {
		return dispach({
			type: SET_DNI,
			payload: dni,
		});
	};
}

export function saveDatosPersonales(payload) {
	return async (dispach) => {
		try {
			return dispach({
				type: SAVE_DATOS_PERSONALES,
				payload,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

//Adjuntos

export function getImagenes() {
	return async function (dispach) {
		try {
			const res = await apiRoutes.getImagenes();

			if (res.data) {
				return dispach({
					type: GET_IMAGENES,
					payload: res.data,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
}

export function setUrlTemporal(id, titulo) {
	return async function (dispach) {
		try {
			const res = await apiRoutes.getUrlTemporal(id);
			const urlTemporal = res.data;
			let datos = { urlTemporal, titulo };
			if (titulo === "DNI-Frente") {
				return dispach({
					type: POST_IMAGEN_FRENTE,
					payload: datos,
				});
			} else if (titulo === "DNI-Dorso") {
				return dispach({
					type: POST_IMAGEN_DORSO,
					payload: datos,
				});
			} else if (titulo === "DNI-Selfie") {
				return dispach({
					type: POST_IMAGEN_SELFIE,
					payload: datos,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
}

export function limpiarFotoStore(titulo) {
	if (titulo === "DNI-Frente") {
		return function (dispach) {
			return dispach({
				type: LIMPIAR_FOTO_FRENTE,
			});
		};
	} else if (titulo === "DNI-Dorso") {
		return function (dispach) {
			return dispach({
				type: LIMPIAR_FOTO_DORSO,
			});
		};
	} else if (titulo === "DNI-Selfie") {
		return function (dispach) {
			return dispach({
				type: LIMPIAR_FOTO_SELFIE,
			});
		};
	}
}

//Perfil Inversor

export function getResultadoTest() {
	return async (dispach) => {
		try {
			const res = await apiRoutes.getTestInversor();
			return dispach({
				type: GET_RESULTADO_TEST,
				payload: res.data,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function saveResultadoTest(payload) {
	return async function (dispach) {
		try {
			return dispach({
				type: SAVE_RESULTADO_TEST,
				payload,
			});
		} catch (err) {
			console.log(err);
		}
	};
}

//Declaraciones

export function getDDJJ() {
	return async (dispach) => {
		try {
			const res = await apiRoutes.getDDJJ();
			return dispach({
				type: GET_DDJJ,
				payload: res.data,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function saveDeclaraciones(payload) {
	return async function (dispach) {
		try {
			return dispach({
				type: SAVE_DDJJ,
				payload,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

//Terminos

export function getTerminos() {
	return async (dispach) => {
		try {
			const res = await apiRoutes.getTerminos();
			return dispach({
				type: GET_TERMINOS,
				payload: res.data.Status,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

//Estética

export function cambiarDarkMode(boolean) {
	return async function (dispach) {
		try {
			return dispach({
				type: CAMBIAR_DARK_MODE,
				payload: boolean,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function cambiarPantallaActual(actual) {
	return async function (dispach) {
		try {
			return dispach({
				type: CAMBIAR_PANTALLA_ACTUAL,
				payload: actual,
			});
		} catch (error) {
			console.log(error);
		}
	};
}

//Restart

export function restartStore() {
	return function (dispach) {
		return dispach({
			type: RESTART_STORE,
		});
	};
}
