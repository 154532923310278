import React, { useEffect } from 'react'
import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { Box, Button } from "@material-ui/core";
import apiRoutes from "../../api/apiRoutes";
import {
	cambiarPantallaActual,
} from "../../redux/actions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ContenedorBox, Input } from "./reAperturaElements";
import { validacionesNuevaApertura } from "../../helpers/validacionesNuevaApertura";
import { useFormLogin } from "../../hooks/useFormLogin";
import s from "./ReAperturaDeUsuarios.module.css";


export const ReAperturaDeUsuarios = () => {
    const { height, width } = useWindowDimensions();

    let url = window.location.href;
    let token = url.substring(url.lastIndexOf("/")).slice(1);

    const dispatch = useDispatch();

    // Create a buffer from the string
    let bufferObj = Buffer.from(token, "base64");

    // Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");

    let nombre = decodedString.substring(decodedString.lastIndexOf("nombre:"), decodedString.lastIndexOf("&apellido")).slice(7);
    let apellido = decodedString.substring(decodedString.lastIndexOf("&apellido:"), decodedString.lastIndexOf("&numcuenta")).slice(10);
    let numcuenta = decodedString.substring(decodedString.lastIndexOf("&numcuenta:"), decodedString.lastIndexOf("&email")).slice(11);
    let email = decodedString.substring(decodedString.lastIndexOf("&email:"), decodedString.lastIndexOf("&manageremail")).slice(7);
    let manager = decodedString.substring(decodedString.lastIndexOf("&manageremail:")).slice(14);

    const initialForm = {
        name: nombre,
        last_name: apellido,
        phone: "",
        email: email,
        manager: manager,
    };

    let actualizacion = true;

    const { form, errors, handleChange, handleSubmit } = useFormLogin(
        initialForm,
        validacionesNuevaApertura,
        actualizacion
    );

    useEffect(() => {
        dispatch(cambiarPantallaActual(-1));
    }, []);

    const politicaPrivacidad =
        'Al enviar tus datos estás aceptando la <a href="/politica-de-privacidad" target="_blank">Politica de privacidad</a> de Rava Bursátil';

    return (
        <div className={s.contenedorPrincipal}>
            <ContenedorBox>
                <span className={s.titulo}>
                    <h1>ACTUALIZÁ LOS DATOS DE TU CUENTA NÚMERO {numcuenta}</h1>
                </span>
                <form
                    onSubmit={handleSubmit}
                    autoComplete={"off"}
                    className={s.contenedorFormulario}
                >
                    <div
                        className={
                            width > 992 ? s.containerEsp : s.containerNoEsp
                        }
                    >
                        <div
                            className={
                                width > 992
                                    ? s.optionContainerEsp
                                    : s.optionContainer
                            }
                        >
                            
                                <label className={s.labelForm}>Nombre/s</label>
                            

                            <Input
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                placeholder={
                                    width < 992
                                        ? "Nombre completo como figura en el DNI"
                                        : "Nombre/s"
                                }
                                readOnly
                            />

                            {width > 992 && (
                                <label className={s.textoGris}>
                                    Nombre completo como figura en el DNI
                                </label>
                            )}
                            {errors.name && (
                                <p className={s.error}>{errors.name}</p>
                            )}
                        </div>
                        <div
                            className={
                                width > 992
                                    ? s.optionContainerEsp
                                    : s.optionContainer
                            }
                        >
                            
                                <label className={s.labelForm}>Apellido/s</label>
                            

                            <Input
                                type="text"
                                name="last_name"
                                value={form.last_name}
                                onChange={handleChange}
                                placeholder={
                                    width < 992
                                        ? "Apellido completo como figura en el DNI"
                                        : "Apellido/s"
                                }
                                readOnly
                            />

                            {width > 992 && (
                                <label className={s.textoGris}>
                                    Nombre completo como figura en el DNI
                                </label>
                            )}
                            {errors.last_name && (
                                <p className={s.error}>{errors.last_name}</p>
                            )}
                        </div>
                    </div>
                    <div className={s.optionContainer}>
                        
                            <label className={s.labelForm}>Email</label>
                        

                        <Input
                            type="text"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            placeholder={
                                width < 992 ? "Ingrese un mail válido" : "Email"
                            }
                            readOnly
                        />
                        {errors.email && (
                            <p className={s.error}>{errors.email}</p>
                        )}
                    </div>

                    <div className={s.optionContainer}>
                        
                            <label className={s.labelForm}>Celular</label>
                        

                        <Input
                            type="text"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                        />
                        {width > 992 && (
                            <label className={s.textoGris}>
                                Ingresar con código de área (ej. 11) sin 0 ni 15
                            </label>
                        )}

                        {errors.phone && (
                            <p className={s.error}>El campo "Celular" es requerido</p>
                        )}
                    </div>
                    <div className={s.botonContainer}>
                        <Button
                            className={s.boton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            Continuar
                        </Button>
                    </div>
                </form>
                <div className={s.secondBox}>
                    <span
                        className={s.privacidad}
                        dangerouslySetInnerHTML={{ __html: politicaPrivacidad }}
                    />
                </div>
            </ContenedorBox>
        </div>
    );
};
