/**
 * Función validadora para abrir una nueva apertura
 * @param {Objetc} form Formulario
 * @param {Objetc} datosP En caso de ya haber completado el formulario anteriormente
 * @param {Array<String>} subsanar Etapas a subsanar
 * @returns {Object} Errores en caso de haberlos
 */

export const validacionesCotitulares = (form, cotitularesNuevos) => {
	let errors = {};
	let reEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

	let reTel = /^([0-9]){2,}$/;

	if (!form.name) {
		errors.name = "El campo 'Nombre' es requerido";
	}
	if (!form.last_name) {
		errors.last_name = "El campo 'Apellido' es requerido";
	}
	if (!form.phone) {
		errors.phone = "El campo 'Celular' es requerido";
	} else if (!reTel.test(form.phone)) {
		errors.phone = "El celular debe ser de caractéres númericos";
	} else if (form.phone.length < 10) {
		errors.phone = "El celular debe tener al menos 10 caracteres";
	}
	if (!form.email && !cotitularesNuevos) {
		errors.email = "El campo 'Email' es requerido";
	} else if (!reEmail.test(form.email) && !cotitularesNuevos) {
		errors.email = "El email debe ser válido";
	}
	if (!form.email && !cotitularesNuevos) {
		errors.email = "El campo 'Email' es requerido";
	}
	if (!form.numCuenta && !cotitularesNuevos) {
		errors.numCuenta = "El campo 'Número de cuenta' es requerido";
	}

	return errors;
};
