import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { Input } from "../DatosPersonales/datosElements";
import s from "./SubsanarApertura.module.css";
import { ContenedorBox } from "./subsanarElements";
import { useNavigate } from "react-router-dom";
import {
	cambiarPantallaActual,
	getDatosPersonales,
	getErrores,
	getEtapasCompletas,
	getImagenes,
	getUserDataReanudacion,
	restartStore,
	getDDJJ
} from "../../redux/actions";
import apiRoutes from "../../api/apiRoutes";
import { redireccion } from "../../helpers/helperReanudarApertura";
import { get } from "react-hook-form";

function validate(form) {
	let errors = {};

	let reCodigo = /^([0-9]){4}$/;

	if (!form.codigo) {
		errors.codigo = "El campo 'Código' es requerido";
	} else if (!reCodigo.test(form.codigo.trim())) {
		errors.codigo = "El código debe ser de cutro caractéres númericos";
	}

	return errors;
}
//Esta pantalla se renderiza en /subsanar/mail:mail, reanudar/mail:mail y reanudar-incorporacion/mail:mail
//Los textos cambian según en que view estoy

export const SubsanarApertura = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const [errors, setErrors] = useState({});

	let url = window.location.href;
	let instancia = url.includes("incorporacion") ? "incorporacion" : url.includes("reanudar") ? "reanudar" :  "subsanar";
	let email = url.substring(url.lastIndexOf("/mail:")).slice(6);

	const [form, setForm] = useState({
		email: email,
		codigo: "",
	});

	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
	}, []);

	function handleChange(e) {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});

		if (Object.keys(errors).length > 0) {
			setErrors(
				validate({
					...form,
					[name]: value,
				})
			);
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const { name, value } = e.target;

		setErrors(
			validate({
				...form,
				[name]: value,
			})
		);

		if (form.codigo && Object.keys(errors).length === 0) {
			try {
				dispatch(restartStore());
				const response = await apiRoutes.reanudarApertura(
					form.email,
					form.codigo
				);
				if (response.data.token) {
					let dataReanudacion = response.data.restart;
					if(dataReanudacion.status_field !== "INFORMADO" && dataReanudacion.phase.length >= 6){
						setError("Tu apertura de cuenta se encuentra en proceso de análisis. Si tenés dudas o consultas, por favor comunicate al teléfono +54 11 7700-1888 o por WhatsApp al +54 9 11 5219-7791");
					}else{
					let token = response.data.token;
					setError("");
					dispatch(getUserDataReanudacion(dataReanudacion, token));
					const res = await redireccion(dataReanudacion);
					const ruta = res.ruta;
					
					let campos;
					let rutasSubsanar;
					res.campos ? (campos = res.campos) : (campos = "");
					res.rutasSubsanar
						? (rutasSubsanar = res.rutasSubsanar)
						: (rutasSubsanar = "");
						if (rutasSubsanar !== "") {
                            if (rutasSubsanar.includes("ddjj")) {
                                dispatch(getDDJJ());
                                dispatch(getDatosPersonales());
                                dispatch(getImagenes());
                            } else if (
                                rutasSubsanar?.length > 1 ||
                                rutasSubsanar.find(
                                    (element) => element === "datos"
                                ) === undefined
                            ) {
                                dispatch(getDatosPersonales());
                                dispatch(getImagenes());
                            } else {
                                if (rutasSubsanar[0] === "datos") {
                                    dispatch(getDatosPersonales());
                                }
                            }
                        }
					dispatch(getErrores(campos, ruta, rutasSubsanar));
					navigate(ruta);
				}
			}
			} catch (err) {
				console.log("Error : ", err);
				setError("Error en el código ingresado");
			}
		}
	}

	const politicaPrivacidad =
		'Al enviar tus datos estás aceptando la <a href="/politica-de-privacidad" target="_blank">Politica de privacidad</a> de Rava Bursátil';

	return (
        <div className={s.contenedorPrincipal}>
            <ContenedorBox>
                <span className={s.titulo}>
					{
						instancia === "reanudar" ? 
						<h1>CONTINUÁ EL PROCESO DE ACTUALIZACIÓN DE DATOS</h1>
						:
						instancia === "incorporacion" ? 
						<h1>CONTINUA EL PROCESO DE INCORPORACIÓN A LA CUENTA</h1>
						: 
						<h1>CONTINUA EL PROCESO DE APERTURA DE CUENTA</h1>
					}
                    
                </span>
                <form
                    onSubmit={handleSubmit}
                    className={s.contenedorFormulario}
                >
                    <div className={s.optionContainer}>
                        <Input
                            type="text"
                            name="codigo"
                            value={form.codigo}
                            onChange={handleChange}
                            placeholder="Código"
                        />

                        {errors.codigo && (
                            <p className={s.error}>{errors.codigo}</p>
                        )}

                        {error && (
                            <p className={s.error}>{error}</p>
                        )}

                        <p className={s.texto}>
                            Ingresa el código de 4 dígitos que recibiste en tu
                            mail
                        </p>
                    </div>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,

                            "@media (min-width:992px)": {
                                padding: "0.2em 4em",
                            },
                            "@media (min-width:1440px)": {
                                padding: "0.2em 6em",
                            },
                        }}
                    >
                        <Button
                            type="submit"
                            className="boton-continuar"
                            size="large"
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            Continuar
                        </Button>
                    </Box>
                </form>
                <div className={s.secondBox}>
                    <span
                        className={s.privacidad}
                        dangerouslySetInnerHTML={{ __html: politicaPrivacidad }}
                    />
                </div>
            </ContenedorBox>
        </div>
    );
};
