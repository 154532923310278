import apiRoutes from "../api/apiRoutes";

/**
 * Te redirecciona a la primer etapa pendiente en caso de reanudacion
 * y a la primer pantalla con errores en caso de subsanacion (Caso 0)
 * @param {Object} data datos del usuario
 */
export const redireccion = async (data) => {
	let email = data.email;
	let code = data.code;
	let tamanio_array = data.phase.length - 1;
	let ruta;
	let campos;
	let rutasSubsanar;
	let numero_fase;
	if (tamanio_array === -1) {
		ruta = "/datos-personales";
	} else {
		numero_fase = data.phase[tamanio_array].numEtapa;
		switch (numero_fase) {
			case 1:
				ruta = "/adjuntos";
				break;
			case 2:
				// ruta = verificarAdjuntos(data.phase);
				ruta = "/perfil-inversor";
				break;
			case 3:
				ruta = "/declaraciones";
				break;
			case 4:
				ruta = "/terminos";
				break;
			case 5:
				ruta = "/verificacion-sms";
				break;
			case 0:
				let res = await getSubsanadosData(email,code)
				if(res){
					ruta = res.ruta;
					campos = res.campos;
					rutasSubsanar = res.rutasSubsanar;
				}
				break;
			default:
				ruta = "/";
				break;
		}
	}
	return {ruta, campos, rutasSubsanar};
};

/**
 * Verifica y almacena las rutas a recorrer en caso de subsanacion y los campos con errores
 * @param {String} email 
 * @param {String} code 
 */
const getSubsanadosData = async(email, code) => {
	try {
		let res = await apiRoutes.subsanadosData(email, code);
		res = res.data.info
		let datos = res?.find(e => e.etapa === "Datos personales")
		let adjuntos = res?.find( e => e.etapa === "Adjuntos")
		let declaraciones = res?.find( e => e.etapa === "Declaraciones")
		let ruta
		let rutasSubsanar 
		
		
		// hay los tres
		if (datos && adjuntos && declaraciones){
			ruta = '/datos-personales'
			rutasSubsanar = ["datos", "adjuntos", "ddjj"]
		}
		// no hay datos pero si adjuntos y delcaraciones
		else if (!datos && adjuntos && declaraciones){
			ruta = '/adjuntos'
			rutasSubsanar = ["adjuntos", "ddjj"]
		} 
		// no hay declaraciones pero si datos y adjuntos
		else if(datos && adjuntos && !declaraciones) {
			ruta = "/datos-personales"
			rutasSubsanar  = ["datos", "adjuntos"]
		} 
		// no hay adjuntos pero si datos y declaraciones
		else if(datos && !adjuntos && declaraciones) {
			ruta = "/datos-personales"
			rutasSubsanar  = ["datos", "ddjj"]
		} 
		//solo hay declaraciones 
		else if (!datos && !adjuntos && declaraciones){
			ruta = '/declaraciones'
			rutasSubsanar = ["ddjj"]
		} 
		//solo hay adjuntos
		else if (!datos && !declaraciones && adjuntos) {
			ruta = "/adjuntos"
			rutasSubsanar  = ["adjuntos"]
		} 
		// solo hay datos
		else {
			ruta = "/datos-personales"
			rutasSubsanar  = ["datos"]
		}
		let campos = res.map( (e) => 
			e.error
		)

		return {campos, ruta, rutasSubsanar}
	} catch (error) {
		console.log(error);
	}
}

function verificarAdjuntos(data) {
	let ruta;
	let length = data.length;
	let ultimoNoAdjunto = data[length - 4].numEtapa;
	switch (ultimoNoAdjunto) {
		case 1:
			ruta = "/perfil-inversor";
			break;
		case 2:
			ruta = "/perfil-inversor";
			break;
		case 3:
			ruta = "/declaraciones";
			break;
		case 4:
			ruta = "/terminos";
			break;
		case 5:
			ruta = "/verificacion-sms";
			break;
		default:
			ruta = "/";
			break;
	}

	return ruta;
}
