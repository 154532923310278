import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import s from "./VerificacionSms.module.css";
import {
	cambiarPantallaActual,
	getTerminos,
	getEtapasCompletas,
} from "../../redux/actions";
import FormValidacionSms from "../../components/FormValidacionSms/FormValidacionSms";
import { etapaCompletaAnteriormente } from "../../helpers/helperEtapaCompleta";
import { Contacto } from "../../components/Contacto/Contacto";

const VerificacionSms = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userDataReducer.usuario);
	const [cellphone] = React.useState(user ? " " + user.phone : "");
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

	useEffect(() => {
		dispatch(cambiarPantallaActual(5));
		dispatch(getTerminos());
		if (!etapaCompletaAnteriormente("Terminos", etapasCompletadas)) {
			dispatch(getEtapasCompletas("Terminos"));
		}
	}, [dispatch]);

/* 	window.addEventListener("beforeunload", function (e) {
		// Cancel the event
		e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
		// Chrome requires returnValue to be set
		e.returnValue = "";
	}); */
	return (
		<>
		<div className={s.container}>
			<div className={s.contenedorPrincipal}>
				<div className={s.contenedorIconoTexto}>
					<Icon
						icon="mdi:cellphone"
						style={{
							color: `${({ theme }) => theme.fontColor}`,
							width: "40px",
							height: "38px",
							transform: "rotate(45deg)",
						}}
					/>
					<h3 className={s.tituloPrincipal}>¡TE ENVIAMOS UN SMS!</h3>
					<span className={s.texto}>
						Ingresá el código de 6 dígitos que recibiste en el
						celular
						{cellphone} para poder validarlo. ¡No te olvides! El
						código estará disponible solo por 24 hs.
					</span>
				</div>
				<FormValidacionSms />
			</div>
		</div>
		<Contacto/>
		</>
	);
};

export default VerificacionSms;
