import React from "react";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./NuevaAperturaForm.module.css";
import { redireccion } from "../../helpers/helperReanudarApertura";
import apiRoutes from "../../api/apiRoutes";
import { useDispatch } from "react-redux";
import {
	getUserDataReanudacion,
	setReanudar,
	restartStore,
	getDatosPersonales,
	getResultadoTest,
	getDDJJ,
	getImagenes,
	getTerminos,
	getEtapasCompletas,
	getErrores,
} from "../../redux/actions";
import { Input } from "../../views/DatosPersonales/datosElements";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function validate(form) {
	let errors = {};

	let reEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

	let reCodigo = /^([0-9]){4}$/;

	if (!form.email) {
		errors.email = "El campo 'Email' es requerido";
	} else if (!reEmail.test(form.email.trim())) {
		errors.email = "El email debe ser válido";
	}
	if (!form.codigo) {
		errors.codigo = "El campo 'Código' es requerido";
	} else if (!reCodigo.test(form.codigo.trim())) {
		errors.codigo = "El código debe ser de cutro caractéres númericos";
	}

	return errors;
}

const NuevaAperturaForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const [errors, setErrors] = useState({});

	const { height, width } = useWindowDimensions();

	const [form, setForm] = useState({
		email: "",
		codigo: "",
	});

	function handleChange(e) {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});

		if (Object.keys(errors).length > 0) {
			setErrors(
				validate({
					...form,
					[name]: value,
				})
			);
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const { name, value } = e.target;

		setErrors(
			validate({
				...form,
				[name]: value,
			})
		);

		if (form.email && form.codigo && Object.keys(errors).length === 0) {
			try {
				dispatch(restartStore());
				const response = await apiRoutes.reanudarApertura(
					form.email,
					form.codigo
				);
		
				if (response.data.token) {
					let dataReanudacion = response.data.restart;
					let token = response.data.token;
					setError("");
					dispatch(getUserDataReanudacion(dataReanudacion, token));
					const res = await redireccion(dataReanudacion);
					const ruta = res.ruta;

					switch (ruta) {
						case "/datos-personales":
							apiRoutes
								.validation(dataReanudacion.code)
								.then((res) => {
									// console.log("msg res", res.data.msg);
								});
							break;
						case "/adjuntos":
							dispatch(getDatosPersonales());
							dispatch(getEtapasCompletas("Datos Personales"));
							break;
						case "/perfil-inversor":
							dispatch(getDatosPersonales());
							dispatch(getImagenes());
							dispatch(getEtapasCompletas("Datos Personales"));
							dispatch(getEtapasCompletas("Adjuntos"));
							break;
						case "/declaraciones":
							dispatch(getDatosPersonales());
							dispatch(getImagenes());
							dispatch(getResultadoTest());
							dispatch(getEtapasCompletas("Datos Personales"));
							dispatch(getEtapasCompletas("Adjuntos"));
							dispatch(getEtapasCompletas("Perfil Inversor"));
							break;
						case "/terminos":
							dispatch(getDatosPersonales());
							dispatch(getImagenes());
							dispatch(getResultadoTest());
							dispatch(getDDJJ());
							dispatch(getEtapasCompletas("Datos Personales"));
							dispatch(getEtapasCompletas("Adjuntos"));
							dispatch(getEtapasCompletas("Perfil Inversor"));
							dispatch(getEtapasCompletas("DDJJ"));
							break;
						case "/verificacion-sms":
							dispatch(getDatosPersonales());
							dispatch(getImagenes());
							dispatch(getResultadoTest());
							dispatch(getDDJJ());
							dispatch(getTerminos());
							dispatch(getEtapasCompletas("Datos Personales"));
							dispatch(getEtapasCompletas("Adjuntos"));
							dispatch(getEtapasCompletas("Perfil Inversor"));
							dispatch(getEtapasCompletas("DDJJ"));
							dispatch(getEtapasCompletas("Terminos"));
							break;
						case "/":
							setError(
								"Tu apertura de cuenta se encuentra en proceso de análisis. Si tenés dudas o consultas, por favor comunicate al teléfono +54 11 7700-1888 o por WhatsApp al +54 9 11 5219-7791."
							);
							break;
						default:
							break;
					}

					navigate(ruta);
				}
			} catch (err) {
				console.log("Error : ", err.response.data.msj);
				setError("Error en el email o código ingresado");
			}
		}
	}

	return (
        <form onSubmit={handleSubmit} className={s.container} autoComplete={"off"}>
            <div className={s.optionContainer}>
                {width < 992 && <label className={s.labelForm}>Email</label>}
                <Input
                    type="text"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder={
                        width < 992 ? "Ingrese un mail válido" : "Email"
                    }
                />
                {errors.email && <p className={s.error}>{errors.email}</p>}
            </div>
            <div className={s.optionContainer}>
                {width < 992 && <label className={s.labelForm}>Código</label>}
                <Input
                    type="text"
                    name="codigo"
                    value={form.codigo}
                    onChange={handleChange}
                    placeholder={width < 992 ? "" : "Código"}
                />

                {width > 992 && (
                    <label className={s.textoGrisGrande}>
                        Ingresá el Código de 4 dígitos que recibiste en tu email cuando comenzaste el proceso
                    </label>
                )}
                {errors.codigo && <p className={s.error}>{errors.codigo}</p>}
                {error && <p className={s.error}>{error}</p>}
            </div>
            <div className={s.botonContainer}>
                <Button
                    className={s.boton}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                >
                    Continuar
                </Button>
            </div>
        </form>
    );
};

export default NuevaAperturaForm;
