import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import NuevaAperturaForm from "./NuevaAperturaForm";
import ReanudarAperturaForm from "./ReanudarAperturaForm";
import s from "./NuevaApertura.module.css";
import { ContenedorPrincipal } from "./aperturaElements";

const NuevaApertura = () => {
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);
	const titulo = () => {
		if (esApertura) {
			return (
				<span className={s.titulo}>
					<b> ABRÍ TU CUENTA </b>
				</span>
			);
		} else {
			return (
				<span className={s.titulo}>
					<b> CONTINUAR APERTURA </b>
				</span>
			);
		}
	};
	const subtitulo = () => {
		if (esApertura) {
			return (
				<span className={s.subtitulo}>
					Operá lo que quieras, fácil y seguro/a desde tu cuenta
					comitente.
				</span>
			);
		} else {
			return "";
		}
	};
	const politicaPrivacidad =
		'Al enviar tus datos estás aceptando la <a href="/politica-de-privacidad" target="_blank">Politica de privacidad</a> de Rava Bursátil';
	function tipoApertura() {
		if (esApertura) {
			return <NuevaAperturaForm />;
		} else {
			return <ReanudarAperturaForm />;
		}
	}
	const [esApertura, setEsApertura] = React.useState(true);
	const cambiarApertura = () => {
		setEsApertura(true);
	};
	const cambiarReanudar = () => {
		setEsApertura(false);
	};
	const colorFirstButton = () => {
		if (esApertura) {
			return (
				<div onClick={cambiarApertura} className={s.abrirCuentaAzul}>
					<span> Abrí tu cuenta </span>
				</div>
			);
		} else {
			if(darkModeRedux){
			return (
				<div onClick={cambiarApertura} className={s.abrirCuentaGrisDarkMode}>
					<span> Abrí tu cuenta </span>
				</div>
			);
			}else{
			return (
				<div onClick={cambiarApertura} className={s.abrirCuentaGris}>
					<span> Abrí tu cuenta </span>
				</div>
			);
			}
		}
	};
	const colorSecondButton = () => {
		if (esApertura) {
			if (darkModeRedux) {
			return (
				<div
					onClick={cambiarReanudar}
					className={s.reanudarAperturaGrisDarkMode}
				>
					<span> Reanudar apertura </span>
				</div>
			);
			}else{
			return (
				<div
					onClick={cambiarReanudar}
					className={s.reanudarAperturaGris}
				>
					<span> Reanudar apertura </span>
				</div>
			);
			}
		} else {
			return (
				<div
					onClick={cambiarReanudar}
					className={s.reanudarAperturaAzul}
				>
					<span> Reanudar apertura </span>
				</div>
			);
		}
	};

	return (
		<>
			<ContenedorPrincipal>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-around",
					}}
				>
					{colorFirstButton()}
					{colorSecondButton()}
				</Box>
				<div className={s.firstBox}>
					{titulo()}
					<Box
						sx={{
							mt: 1,
							pb: 0,
						}}
					>
						{subtitulo()}
					</Box>
				</div>

				{tipoApertura()}

				<div className={s.secondBox}>
					<span
						className={s.privacidad}
						dangerouslySetInnerHTML={{ __html: politicaPrivacidad }}
					/>
				</div>
			</ContenedorPrincipal>
		</>
	);
};

export default NuevaApertura;
// politicaPrivacidad
