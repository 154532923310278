// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width: 768px) {\n\t.CarruselFotos_container__nccV2 {\n\t\tdisplay: none;\n\t}\n}\n.CarruselFotos_container__nccV2{\n\t/* width: 90%; */\n\t/* margin: 0 auto; */\n\tmargin-top: 4px;\n}\n.CarruselFotos_imagen__-JMl5{\n\twidth: 90%;\n\t/* margin: 0 auto !important; */\n\tmargin-right: 16px;\n\tmargin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/CarruselFotos/CarruselFotos.module.css"],"names":[],"mappings":"AAAA;CACC;EACC,aAAa;CACd;AACD;AACA;CACC,gBAAgB;CAChB,oBAAoB;CACpB,eAAe;AAChB;AACA;CACC,UAAU;CACV,+BAA+B;CAC/B,kBAAkB;CAClB,iBAAiB;AAClB","sourcesContent":["@media screen and (min-width: 768px) {\n\t.container {\n\t\tdisplay: none;\n\t}\n}\n.container{\n\t/* width: 90%; */\n\t/* margin: 0 auto; */\n\tmargin-top: 4px;\n}\n.imagen{\n\twidth: 90%;\n\t/* margin: 0 auto !important; */\n\tmargin-right: 16px;\n\tmargin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CarruselFotos_container__nccV2",
	"imagen": "CarruselFotos_imagen__-JMl5"
};
export default ___CSS_LOADER_EXPORT___;
