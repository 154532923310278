import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import s from "./Contacto.module.css";

export const Contacto = () => {

    const { width, height } = useWindowDimensions();
    const [showText, setShowText] = useState(false);

    const handleBlurMouseEnter = () => {
        setShowText(true);
    }

    const handleMouseLeave = () => {
        setShowText(false);
    }
    

    const icon = (
        <div className={s.iconContainer}>
            <Icon
                icon="logos:whatsapp-icon"
                style={{ width: "2.9em", height: "2.9em" }}
            />
            {
                showText &&
                <p className={s.textoIcon}>¿Tenés dudas con la apertura?</p>
            }
            
        </div>
    );

    return (
        <button
            onMouseEnter = {handleBlurMouseEnter} 
            onMouseLeave= {handleMouseLeave}
            className={s.container}>
            <a
                href="https://wa.me/5491155270400"
                target="_blank"
                rel="noreferrer"
                className={s.link}
            >
                {width > 992 ? (
                    <>{icon}</>
                ) : (
                    <div className={s.iconContainer}>
                        <Icon
                            icon="logos:whatsapp-icon"
                            style={{ width: "2.5em", height: "2.5em" }}
                        />
                    </div>
                )}
            </a>
        </button>
    );
};
