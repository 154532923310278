import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@material-ui/core/Button";
import { useFormDeclaraciones } from "../../hooks/useFormDeclaraciones";
import { validacionesDeclaraciones } from "../../helpers/validacionesDeclaraciones.js";
import { styled } from "@mui/material/styles";
import s from "./DDJJ.module.css";
import {
	cambiarPantallaActual,
	getDDJJ,
	getEtapasCompletas,
} from "../../redux/actions";
import { InputTotalWidth } from "../../views/DatosPersonales/datosElements";
import { Link, ErrorContainer, ErrorText, Error } from "./DDJJElements";
import { etapaCompletaAnteriormente } from "../../helpers/helperEtapaCompleta";
import { Icon } from "@iconify/react";
import { Box, CircularProgress } from "@mui/material";
import { Contacto } from "../../components/Contacto/Contacto.js";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	display:"flex",
	flexDirection:"column",
	borderRadius: "5px",
	border: `1px solid ${theme.palette.divider}`,
	margin: "0.3em",
	"& .MuiButtonBase-root": {
		display: "flex",
	},
	// "&:not(:last-child)": {
	// 	borderBottom: 0,
	// },
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem"}} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .03)",
	flexDirection: "row",
	display: "flex",

	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
		alignItem: "spaceBetween",
	},
}));
// const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
// 	color: "white",
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
	color: `${({ theme }) => theme.fontColor}`,
	fontFamily:
		"Open Sans, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
	fontSize: "0.9em",
	flexDirection: "colum",
}));

export const DDJJ = () => {
	const dispatch = useDispatch();
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);
	const datosP = useSelector((state) => state.datosEtapasReducer.declaracionesPrevias);
	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);

    
    const [loading, setLoading] = useState(true);


	const ExpandIcon = styled(ExpandMoreIcon)(() => ({
		color: darkModeRedux ? "#fff" : "rgba(0, 0, 0, 0.54);",
	}));

	useEffect(() => {
		if(subsanar.length > 0){
            dispatch(getDDJJ());
            if(datosP?.CondicionSujetoObligado){
                setLoading(false)
            } 
        } else {
            setLoading(false)
        }
		dispatch(cambiarPantallaActual(3));
		if (!etapaCompletaAnteriormente("Perfil Inversor", etapasCompletadas)) {
			dispatch(getEtapasCompletas("Perfil Inversor"));
		}
	}, [dispatch, datosP?.CondicionSujetoObligado]);

	const initialForm = {
		VinculoClienteAgente: "",
		FondosEnPesosDisponibles: datosP?.FondosEnPesosDisponibles
			? datosP.FondosEnPesosDisponibles
			: false,
		InvertirExterior: "",
		ClienteAutoriza: datosP?.ClienteAutoriza
			? datosP.ClienteAutoriza
			: false,
		ClienteDeclara: datosP?.ClienteDeclara ? datosP.ClienteDeclara : false,
		OrigenLicitudFondos: "",
		OtrosDescripcion: datosP?.OtrosDescripcion ? datosP.OtrosDescripcion : "",
		CondicionSujetoObligado: "",
		ExpuestaPoliticamente: "",
		ResidenciaFiscal: "",
		PaisRecidenciaFiscal1: datosP?.PaisRecidenciaFiscal1 ? datosP.PaisRecidenciaFiscal1 : "",
		NumeroIdFiscal1: datosP?.NumeroIdFiscal1 ? datosP.NumeroIdFiscal1 : "",
		NumeroIdFiscal1Check: datosP?.NumeroIdFiscal1Check
			? datosP.NumeroIdFiscal1Check
			: false,
		SinIdFiscalMotivo1: datosP?.SinIdFiscalMotivo1 ? datosP.SinIdFiscalMotivo1 : "",
		SinIdFiscalMotivo1Descripcion: datosP?.SinIdFiscalMotivo1Descripcion ? datosP.SinIdFiscalMotivo1Descripcion : "",
		PaisRecidenciaFiscal2: datosP?.PaisRecidenciaFiscal2 ? datosP.PaisRecidenciaFiscal2 : "",
		NumeroIdFiscal2: datosP?.NumeroIdFiscal2 ? datosP.NumeroIdFiscal2 : "",
		NumeroIdFiscal2Check: datosP?.NumeroIdFiscal2Check
			? datosP.NumeroIdFiscal2Check
			: false,
		SinIdFiscalMotivo2: datosP?.SinIdFiscalMotivo2 ? datosP.SinIdFiscalMotivo2 : "",
		SinIdFiscalMotivo2Descripcion: datosP?.SinIdFiscalMotivo2Descripcion ? datosP.SinIdFiscalMotivo2Descripcion : "",
		InversorCalificado: "",
		FATCA: "",
		FATCAClienteAutoriza: datosP?.FATCACienteAutoriza
			? datosP.FATCACienteAutoriza
			: false,
		FATCAClienteInformado: datosP?.FATCACienteInformado
			? datosP.FATCACienteInformado
			: false,
		SSN: datosP?.SSN ? datosP.SSN : "",
		ITIN: datosP?.ITIN ? datosP.ITIN : "",
		EIN: datosP?.EIN ? datosP.EIN : "",
	};

	const [expanded, setExpanded] = useState(false);

	const { form, errors, handleClick, handleCheck, handleSubmit } =
		useFormDeclaraciones(initialForm, validacionesDeclaraciones, datosP);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};


	return (
        <>
        <div className={s.container}>
            <h3 className={s.titulo}>DECLARACIONES</h3>
            <h6 className={s.sub}>Declaración jurada de los siguiente items</h6>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 16,
                    }}
                >
                    <CircularProgress style={{ color: "#004B6F" }} />
                </Box>
            ) : (
                <>
                    {errors &&
                        Object.keys(errors).length > 0 &&
                        !(subsanar.length > 0) && (
                            <ErrorContainer>
                                <Icon
                                    icon="bx:error-circle"
                                    style={{ fontSize: "1.7em" }}
                                />
                                {"    "}
                                <ErrorText>Campos incompletos</ErrorText>
                            </ErrorContainer>
                        )}
                    <form onSubmit={(e) => handleSubmit(e)} className={s.form}>
                        <div className={s.accordionsContainer}>
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.VinculoClienteAgente
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        VÍNCULO ENTRE EL CLIENTE Y EL AGENTE
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    {errors.VinculoClienteAgente && (
                                        <Error>
                                            {errors.VinculoClienteAgente}
                                        </Error>
                                    )}
                                    <div className={s.accordionContent}>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="1a"
                                                name="VinculoClienteAgente"
                                                value="ClienteOpera"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Vínculo entre el cliente y el agente"
                                                    )
                                                        ? false
                                                        : datosP?.VinculoClienteAgente ===
                                                          "ClienteOpera"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Vínculo entre el cliente y el agente"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="1a"
                                                className={s.label}
                                            >
                                                El Cliente operará mediante
                                                instrucciones específicas.
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="1b"
                                                name="VinculoClienteAgente"
                                                value="AgenteAdministra"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Vínculo entre el cliente y el agente"
                                                    )
                                                        ? false
                                                        : datosP?.VinculoClienteAgente ===
                                                          "AgenteAdministra"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Vínculo entre el cliente y el agente"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="1b"
                                                className={s.label}
                                            >
                                                El Agente ejercerá la
                                                administración discrecional
                                                total o parcial de la cartera de
                                                inversión. En el marco del
                                                convenio de administración de
                                                cartera que suscribo, autorizo
                                                al Agente a adquirir por mi
                                                cuenta y orden, y sin solicitar
                                                mi autorización previa, acciones
                                                emitidas por entes del exterior
                                                y negociadas en mercados no
                                                sujetos al control de la
                                                Comisión Nacional de Valores,
                                                aun cuando no sean acordes a mi
                                                perfil inversor. Dejo constancia
                                                de haber sido informado y
                                                advertido de los riesgos que
                                                dichas operaciones conllevan.
                                            </label>
                                        </div>
                                        {(form.VinculoClienteAgente ===
                                            "AgenteAdministra" ||
                                            (!form.VinculoClienteAgente &&
                                                datosP?.VinculoClienteAgente ===
                                                    "AgenteAdministra" &&
                                                subsanar &&
                                                !subsanar.includes(
                                                    "Vínculo entre el cliente y el agente"
                                                ))) && (
                                            <div className={s.optionContainer}>
                                                <input
                                                    className={s.input}
                                                    type="checkbox"
                                                    id="1bCheck"
                                                    name="FondosEnPesosDisponibles"
                                                    defaultChecked={
                                                        subsanar &&
                                                        subsanar.includes(
                                                            "Vínculo entre el cliente y el agente"
                                                        )
                                                            ? false
                                                            : datosP?.FondosEnPesosDisponibles ===
                                                              "true"
                                                            ? true
                                                            : false
                                                    }
                                                    {...(subsanar.length > 0 &&
                                                        !subsanar.includes(
                                                            "Vínculo entre el cliente y el agente"
                                                        ) && {
                                                            disabled: true,
                                                        })}
                                                    onChange={(e) =>
                                                        handleCheck(e)
                                                    }
                                                />
                                                <label
                                                    htmlFor="1bCheck"
                                                    className={s.labelCheck}
                                                >
                                                    Manifiesto mi intención de
                                                    que los fondos líquidos en
                                                    pesos puedan quedar
                                                    disponibles en cuenta para
                                                    futuras operaciones.
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.InvertirExterior ||
                                            errors.ClienteAutoriza ||
                                            errors.ClienteDeclara
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        INVERTIR EN EL EXTERIOR
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.texto}>
                                            ¿Vas a invertir en el exterior?
                                        </h6>
                                        {errors.InvertirExterior && (
                                            <Error>
                                                {errors.InvertirExterior}
                                            </Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="2a"
                                                name="InvertirExterior"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Invertir en el exterior"
                                                    )
                                                        ? false
                                                        : datosP?.InvertirExterior ===
                                                          "No"
                                                        ? true
                                                        : false
                                                    // datosP?.InvertirExterior === "No"
                                                    // 	? true
                                                    // 	: false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Invertir en el exterior"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="2a"
                                                className={s.label}
                                            >
                                                No
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="2b"
                                                name="InvertirExterior"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Invertir en el exterior"
                                                    )
                                                        ? false
                                                        : datosP?.InvertirExterior ===
                                                          "Si"
                                                        ? true
                                                        : false
                                                    // datosP?.InvertirExterior === "Si"
                                                    // 	? true
                                                    // 	: false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Invertir en el exterior"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="2b"
                                                className={s.label}
                                            >
                                                Si
                                            </label>
                                        </div>
                                        {(form.InvertirExterior === "Si" ||
                                            (datosP?.InvertirExterior ===
                                                "Si" &&
                                                subsanar &&
                                                !subsanar.includes(
                                                    "Invertir en el exterior"
                                                ) &&
                                                !form.InvertirExterior)) && (
                                            <div>
                                                {errors.ClienteAutoriza && (
                                                    <Error>
                                                        {errors.ClienteAutoriza}
                                                    </Error>
                                                )}
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="2bCheck1"
                                                        name="ClienteAutoriza"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Invertir en el exterior"
                                                            )
                                                                ? false
                                                                : datosP?.ClienteAutoriza &&
                                                                  datosP.ClienteAutoriza
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Invertir en el exterior"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="2bCheck1"
                                                        className={s.labelCheck}
                                                    >
                                                        El Cliente autoriza a
                                                        RAVA BURSÁTIL S.A. a
                                                        adquirir en su interés,
                                                        sin necesidad de
                                                        solicitar autorización
                                                        expresa previa,
                                                        instrumentos financieros
                                                        no acordes a su perfil
                                                        inversor, consistentes
                                                        en títulos públicos y/o
                                                        privados emitidos por
                                                        entes del exterior y
                                                        negociados en mercados
                                                        no sujetos al control de
                                                        la Comisión Nacional de
                                                        Valores.
                                                    </label>
                                                </div>
                                                {errors.ClienteDeclara && (
                                                    <Error>
                                                        {errors.ClienteDeclara}
                                                    </Error>
                                                )}
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="2bCheck2"
                                                        name="ClienteDeclara"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Invertir en el exterior"
                                                            )
                                                                ? false
                                                                : datosP?.ClienteDeclara &&
                                                                  datosP.ClienteDeclara
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Invertir en el exterior"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="2bCheck2"
                                                        className={s.labelCheck}
                                                    >
                                                        El Cliente declara
                                                        conocer y asumir que la
                                                        emisión de órdenes y el
                                                        perfeccionamiento de
                                                        operaciones se
                                                        encuentran afectadas por
                                                        determinados riesgos,
                                                        los cuales pueden variar
                                                        de tiempo en tiempo.
                                                        Algunos de tales riesgos
                                                        son: Riesgo de Mercado,
                                                        entendido como la
                                                        posibilidad de que el
                                                        Cliente incurra en
                                                        pérdidas asociadas a la
                                                        disminución del valor de
                                                        su portafolio por efecto
                                                        de cambios en el precio
                                                        de los instrumentos
                                                        financieros en los
                                                        cuales se mantienen
                                                        posiciones; Riesgo de
                                                        liquidez, entendido como
                                                        la contingencia de no
                                                        poder cumplir de manera
                                                        plena y oportuna con las
                                                        obligaciones de pago en
                                                        las fechas
                                                        correspondientes, debido
                                                        a la insuficiencia de
                                                        recursos líquidos o a la
                                                        necesidad de asumir
                                                        costos inusuales de
                                                        fondeo o liquidar
                                                        posiciones con el objeto
                                                        de reducir sus faltantes
                                                        y esto, a su vez, puede
                                                        llevar a que tales
                                                        activos deban venderse a
                                                        precios inferiores a los
                                                        contabilizados,
                                                        incurriendo en pérdidas;
                                                        Riesgo de crédito,
                                                        entendido como la
                                                        posible pérdida y
                                                        posterior consecuencia
                                                        de disminución del valor
                                                        de los activos debido al
                                                        incumplimiento de las
                                                        obligaciones contraídas
                                                        o un deterioro en la
                                                        calidad crediticia con
                                                        un tercero (contraparte
                                                        y/o el emisor de los
                                                        instrumentos
                                                        financieros) ya sea por
                                                        iliquidez o insolvencia;
                                                        Riesgo operacional,
                                                        entendido como la
                                                        posibilidad de incurrir
                                                        en pérdidas por
                                                        deficiencias, fallas o
                                                        inadecuaciones, en el
                                                        recurso humano, los
                                                        procesos, la tecnología,
                                                        la infraestructura o por
                                                        la ocurrencia de
                                                        acontecimientos
                                                        externos.
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.OrigenLicitudFondos ||
                                            errors.OtrosDescripcion
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        ORIGEN Y LICITUD DE FONDOS
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={s.accordionDetails}>
                                        <div className={s.accordionContent}>
                                            <h6 className={s.textoBold}>
                                                En cumplimiento de la normativa
                                                relacionada a la Prevención del
                                                Lavado de Activos y Financiación
                                                del Terrorismo (Ley 25.246 y
                                                modificatorias, como también las
                                                Resoluciones emitidas por la
                                                Unidad de Información
                                                Financiera), quien suscribe
                                                declara bajo juramento que:
                                            </h6>
                                            <h6 className={s.textoGris}>
                                                1.Los fondos y/o activos que se
                                                aportan son propios y no de
                                                terceras personas, provienen de
                                                actividades lícitas y se
                                                originan en:
                                            </h6>
                                            {errors.OrigenLicitudFondos && (
                                                <Error>
                                                    {errors.OrigenLicitudFondos}
                                                </Error>
                                            )}
                                            <div className={s.optionContainer}>
                                                <input
                                                    className={s.input}
                                                    type="radio"
                                                    id="3a"
                                                    name="OrigenLicitudFondos"
                                                    value="ActividadPrincipal"
                                                    defaultChecked={
                                                        subsanar &&
                                                        subsanar.includes(
                                                            "Origen y licitud de fondos"
                                                        )
                                                            ? false
                                                            : datosP?.OrigenLicitudFondos ===
                                                              "ActividadPrincipal"
                                                            ? true
                                                            : false
                                                    }
                                                    {...(subsanar.length > 0 &&
                                                        !subsanar.includes(
                                                            "Origen y licitud de fondos"
                                                        ) && {
                                                            disabled: true,
                                                        })}
                                                    onChange={(e) =>
                                                        handleClick(e)
                                                    }
                                                />
                                                <label
                                                    htmlFor="3a"
                                                    className={s.label}
                                                >
                                                    Actividad principal
                                                </label>
                                            </div>
                                            <div className={s.optionContainer}>
                                                <input
                                                    className={s.input}
                                                    type="radio"
                                                    id="3b"
                                                    name="OrigenLicitudFondos"
                                                    value="Otros"
                                                    defaultChecked={
                                                        subsanar &&
                                                        subsanar.includes(
                                                            "Origen y licitud de fondos"
                                                        )
                                                            ? false
                                                            : datosP?.OrigenLicitudFondos ===
                                                              "Otros"
                                                            ? true
                                                            : false
                                                    }
                                                    {...(subsanar.length > 0 &&
                                                        !subsanar.includes(
                                                            "Origen y licitud de fondos"
                                                        ) && {
                                                            disabled: true,
                                                        })}
                                                    onChange={(e) =>
                                                        handleClick(e)
                                                    }
                                                />
                                                <label
                                                    htmlFor="3b"
                                                    className={s.label}
                                                >
                                                    Otros
                                                </label>
                                            </div>

                                            {(form.OrigenLicitudFondos ===
                                                "Otros" ||
                                                (datosP?.OrigenLicitudFondos ===
                                                    "Otros" &&
                                                    subsanar &&
                                                    !subsanar.includes(
                                                        "Origen y licitud de fondos"
                                                    ) &&
                                                    !form.OrigenLicitudFondos)) && (
                                                <>
                                                    {errors.OtrosDescripcion && (
                                                        <Error>
                                                            {
                                                                errors.OtrosDescripcion
                                                            }
                                                        </Error>
                                                    )}
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <InputTotalWidth
                                                            className={`${s.input} ${errors.OtrosDescripcion && 'errorCampo'}`}
                                                            type="text"
                                                            id="OtrosDescripcion"
                                                            name="OtrosDescripcion"
                                                            defaultValue={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Origen y licitud de fondos"
                                                                )
                                                                    ? ""
                                                                    : datosP?.OtrosDescripcion
                                                            }
                                                            value={form.name}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Origen y licitud de fondos"
                                                                ) && {
                                                                    readOnly: true,
                                                                })}
                                                            
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <h6 className={s.textoGris}>
                                                2.La totalidad de los datos (y
                                                en especial los correspondientes
                                                a estado civil y actividad
                                                principal) contenidos en el
                                                presente son exactos y
                                                verdaderos, y que posee
                                                conocimiento de la ley 25.246,
                                                mencionada anteriormente. De ser
                                                necesario, quien suscribe se
                                                compromete a aportar
                                                documentación que respalde la
                                                presente declaración y su
                                                actualización, en caso de
                                                corresponder.
                                            </h6>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h6
                                        className={
                                            errors.CondicionSujetoObligado
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        CONDICIÓN DE SUJETO OBLIGADO
                                    </h6>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.texto}>
                                            ¿Sos un Sujeto Obligado ante la UIF?
                                        </h6>
                                        <h6 className={s.textoBold}>
                                            Los Sujetos Obligados se encuentra
                                            comprendido en lo dispuesto por el
                                            artículo 20 de la Ley Nº 25.246 y
                                            sus modificatorias, la cual enumera
                                            quiénes son los Sujetos Obligados a
                                            informar ante la Unidad de
                                            Información Financiera. Más
                                            informacion{" "}
                                            <Link
                                                href="https://www.argentina.gob.ar/uif/normativa"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                acá
                                            </Link>
                                            .
                                        </h6>
                                        {errors.CondicionSujetoObligado && (
                                            <Error>
                                                {errors.CondicionSujetoObligado}
                                            </Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="4a"
                                                name="CondicionSujetoObligado"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Condición de sujeto obligado"
                                                    )
                                                        ? false
                                                        : datosP?.CondicionSujetoObligado ===
                                                          "No"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Condición de sujeto obligado"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="4a"
                                                className={s.label}
                                            >
                                                No
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="4b"
                                                name="CondicionSujetoObligado"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Condición de sujeto obligado"
                                                    )
                                                        ? false
                                                        : datosP?.CondicionSujetoObligado ===
                                                          "Si"
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => handleClick(e)}
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Condición de sujeto obligado"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                            />
                                            <label
                                                htmlFor="4b"
                                                className={s.label}
                                            >
                                                Si
                                            </label>
                                        </div>
                                        <h6 className={s.textoGris}>
                                            Quien suscribe informa que la
                                            actividad que realiza conforme el
                                            inciso del artículo mencionado que
                                            la comprende es su actividad
                                            principal.
                                        </h6>
                                        <h6 className={s.textoGris}>
                                            Quien suscribe informa que cumple
                                            adecuadamente con las obligaciones
                                            establecidas por la Unidad de
                                            Información Financiera y acompaña a
                                            la presente copia la constancia de
                                            inscripción correspondiente.
                                        </h6>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel5"}
                                onChange={handleChange("panel5")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.ExpuestaPoliticamente
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        CONDICIÓN DE PERSONA EXPUESTA
                                        POLÍTICAMENTE (PEP)
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.textoBold}>
                                            ¿Sos una persona expuesta
                                            políticamente (PEP)?
                                        </h6>
                                        <h6 className={s.texto}>
                                            Nómina de Personas Expuestas
                                            Políticamente{" "}
                                            <Link
                                                href="https://www.argentina.gob.ar/uif/normativa/resoluciones"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                (ver acá)
                                            </Link>
                                            :
                                        </h6>
                                        <p className={s.textoBold}>
                                            <i>
                                                Quien suscribe declara bajo
                                                juramento que ha leído y tomado
                                                conocimiento de la "Nómina de
                                                Personas Expuestas
                                                Políticamente" aprobada por la
                                                Unidad de Información
                                                Financiera, que los datos
                                                consignados en la presente son
                                                correctos, completos y fiel
                                                expresión de la verdad.
                                            </i>
                                        </p>
                                        {errors.ExpuestaPoliticamente && (
                                            <Error>
                                                {errors.ExpuestaPoliticamente}
                                            </Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="5a"
                                                name="ExpuestaPoliticamente"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Condición de persona expuesta políticamente (PEP)"
                                                    )
                                                        ? false
                                                        : datosP?.ExpuestaPoliticamente ===
                                                          "No"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Condición de persona expuesta políticamente (PEP)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="5a"
                                                className={s.label}
                                            >
                                                No soy una persona politicamente
                                                expuesta.
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="5b"
                                                name="ExpuestaPoliticamente"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Condición de persona expuesta políticamente (PEP)"
                                                    )
                                                        ? false
                                                        : datosP?.ExpuestaPoliticamente ===
                                                          "Si"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Condición de persona expuesta políticamente (PEP)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="5b"
                                                className={s.label}
                                            >
                                                Soy una persona politicamente
                                                expuesta.
                                            </label>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel6"}
                                onChange={handleChange("panel6")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.ResidenciaFiscal ||
                                            errors.PaisRecidenciaFiscal1 ||
                                            errors.NumeroIdFiscal1 ||
                                            errors.SinIdFiscalMotivo1 ||
                                            errors.SinIdFiscalMotivo1Descripcion ||
                                            errors.SinIdFiscalMotivo2Descripcion
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        RESIDENCIA FISCAL (OCDE)
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.textoBold}>
                                            ¿Tenés residencia fiscal en países
                                            extranjeros?
                                        </h6>
                                        <h6 className={s.texto}>
                                            Información Financiera de Sujetos no
                                            Residentes (AFIP).{" "}
                                            <Link
                                                href="https://www.afip.gob.ar/InformacionFinancieradeSujetosnoResidentes/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Ver acá
                                            </Link>
                                            .
                                        </h6>
                                        <p
                                            className={s.texto}
                                            style={{ fontSize: "0.9em" }}
                                        >
                                            Quien suscribe declara bajo
                                            juramento que posee residencia
                                            fiscal en países extranjeros, a los
                                            efectos del intercambio de
                                            información de cuentas financieras
                                            de la Organización para la
                                            Cooperación y el Desarrollo
                                            Económico (OCDE).
                                        </p>
                                        {errors.ResidenciaFiscal && (
                                            <Error>
                                                {errors.ResidenciaFiscal}
                                            </Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="6a"
                                                name="ResidenciaFiscal"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Residencia fiscal (OCDE)"
                                                    )
                                                        ? false
                                                        : datosP?.ResidenciaFiscal ===
                                                          "No"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Residencia fiscal (OCDE)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="6a"
                                                className={s.label}
                                            >
                                                No
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="6b"
                                                name="ResidenciaFiscal"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Residencia fiscal (OCDE)"
                                                    )
                                                        ? false
                                                        : datosP?.ResidenciaFiscal ===
                                                          "Si"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Residencia fiscal (OCDE)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="6b"
                                                className={s.label}
                                            >
                                                Si
                                            </label>
                                        </div>
                                        {/* (form.ExpuestaPoliticamente === "Si" ||
									(datosP?.ExpuestaPoliticamente === "Si" 
									&& (subsanar && !subsanar.includes("Condición de persona expuesta políticamente (PEP)"))
									&& !form.ExpuestaPoliticamente)) && */}
                                        {(form.ResidenciaFiscal === "Si" ||
                                            (!form.ResidenciaFiscal &&
                                                // && (subsanar && !subsanar.includes("Residencia fiscal (OCDE)"))
                                                datosP?.ResidenciaFiscal ===
                                                    "Si")) && (
                                            <div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            País de Residencia
                                                            Fiscal 1
                                                        </label>
                                                    </div>
                                                    {errors.PaisRecidenciaFiscal1 && (
                                                        <Error>
                                                            {
                                                                errors.PaisRecidenciaFiscal1
                                                            }
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.PaisRecidenciaFiscal1 && 'errorCampo'}`}
                                                        type="text"
                                                        id="PaisRecidenciaFiscal1"
                                                        name="PaisRecidenciaFiscal1"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.PaisRecidenciaFiscal1
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Número de
                                                            identificación
                                                            fiscal 1
                                                        </label>
                                                    </div>
                                                    {errors.NumeroIdFiscal1 && (
                                                        <Error>
                                                            {
                                                                errors.NumeroIdFiscal1
                                                            }
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.NumeroIdFiscal1 && 'errorCampo'}`}
                                                        type="text"
                                                        id="NumeroIdFiscal1"
                                                        name="NumeroIdFiscal1"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.NumeroIdFiscal1
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="6bCheck"
                                                        name="NumeroIdFiscal1Check"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? false
                                                                : datosP?.NumeroIdFiscal1Check
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="6bCheck"
                                                        className={s.labelCheck}
                                                    >
                                                        No poseo número de
                                                        identificación fiscal
                                                    </label>
                                                </div>
                                                <h6 className={s.textoBold}>
                                                    En caso de no informar el
                                                    Número de Identificación
                                                    Fiscal, indicar el motivo:
                                                </h6>
                                                <div>
                                                    {errors.SinIdFiscalMotivo1 && (
                                                        <Error>
                                                            {
                                                                errors.SinIdFiscalMotivo1
                                                            }
                                                        </Error>
                                                    )}
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.a"
                                                            name="SinIdFiscalMotivo1"
                                                            value="PaisNoEmiteNIF"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo1 ===
                                                                      "PaisNoEmiteNIF"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.a"
                                                            className={s.label}
                                                        >
                                                            El país de
                                                            residencia fiscal no
                                                            emite número NIF a
                                                            sus residentes
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.b"
                                                            name="SinIdFiscalMotivo1"
                                                            value="PaisNoRequiereNIF"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo1 ===
                                                                      "PaisNoRequiereNIF"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.b"
                                                            className={s.label}
                                                        >
                                                            El país de
                                                            residencia fiscal no
                                                            requiere el número
                                                            NIF
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.c"
                                                            name="SinIdFiscalMotivo1"
                                                            value="OtroMotivo"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo1 ===
                                                                      "OtroMotivo"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.c"
                                                            className={s.label}
                                                        >
                                                            Número de TIN no
                                                            disponible por otros
                                                            motivos
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Motivo del número de
                                                            TIN no disponible:
                                                        </label>
                                                    </div>
                                                    {errors.SinIdFiscalMotivo1Descripcion && (
                                                        <Error>
                                                            {
                                                                errors.SinIdFiscalMotivo1Descripcion
                                                            }
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.SinIdFiscalMotivo1Descripcion && 'errorCampo'}`}
                                                        type="text"
                                                        id="SinIdFiscalMotivo1Descripcion"
                                                        name="SinIdFiscalMotivo1Descripcion"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.SinIdFiscalMotivo1Descripcion
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div className={s.box}>
                                                    <p className={s.textoGris}>
                                                        Nota: Su número de
                                                        Identificación Fiscal,
                                                        en caso de tener
                                                        residencia fiscal en
                                                        Argentina, es su CUIT o
                                                        CUIL. En caso de tener
                                                        otra residencia fiscal,
                                                        es el número de
                                                        identificación
                                                        tributaria que aplique
                                                        para dicha jurisdicción.
                                                    </p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            País de Residencia
                                                            Fiscal 2
                                                        </label>
                                                    </div>
                                                    <InputTotalWidth
                                                        className={s.input}
                                                        type="text"
                                                        id="PaisRecidenciaFiscal2"
                                                        name="PaisRecidenciaFiscal2"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.PaisRecidenciaFiscal2
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Número de
                                                            identificación
                                                            fiscal 2
                                                        </label>
                                                    </div>
                                                    <InputTotalWidth
                                                        className={s.input}
                                                        type="text"
                                                        id="NumeroIdFiscal2"
                                                        name="NumeroIdFiscal2"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.NumeroIdFiscal2
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                    style={{
                                                        marginTop: 5,
                                                        marginBottom: 15,
                                                    }}
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="6bCheck2"
                                                        name="NumeroIdFiscal2Check"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? false
                                                                : datosP?.NumeroIdFiscal2Check
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="6bCheck2"
                                                        className={s.labelCheck}
                                                    >
                                                        No poseo número de
                                                        identificación fiscal
                                                    </label>
                                                </div>
                                                <h6 className={s.textoBold}>
                                                    En caso de no informar el
                                                    Número de Identificación
                                                    Fiscal, indicar el motivo:
                                                </h6>
                                                <div>
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.d"
                                                            name="SinIdFiscalMotivo2"
                                                            value="PaisNoEmiteNIF"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo2 ===
                                                                      "PaisNoEmiteNIF"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.d"
                                                            className={s.label}
                                                        >
                                                            El país de
                                                            residencia fiscal no
                                                            emite número NIF a
                                                            sus residentes
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.e"
                                                            name="SinIdFiscalMotivo2"
                                                            value="PaisNoRequiereNIF"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo2 ===
                                                                      "PaisNoRequiereNIF"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.e"
                                                            className={s.label}
                                                        >
                                                            El país de
                                                            residencia fiscal no
                                                            requiere el número
                                                            NIF
                                                        </label>
                                                    </div>
                                                    <div
                                                        className={
                                                            s.optionContainer
                                                        }
                                                    >
                                                        <input
                                                            className={s.input}
                                                            type="radio"
                                                            id="6.f"
                                                            name="SinIdFiscalMotivo2"
                                                            value="OtroMotivo"
                                                            defaultChecked={
                                                                subsanar &&
                                                                subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                )
                                                                    ? false
                                                                    : datosP?.SinIdFiscalMotivo2 ===
                                                                      "OtroMotivo"
                                                                    ? true
                                                                    : false
                                                            }
                                                            {...(subsanar.length >
                                                                0 &&
                                                                !subsanar.includes(
                                                                    "Residencia fiscal (OCDE)"
                                                                ) && {
                                                                    disabled: true,
                                                                })}
                                                            onChange={(e) =>
                                                                handleClick(e)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="6.f"
                                                            className={s.label}
                                                        >
                                                            Número de TIN no
                                                            disponible por otros
                                                            motivos
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Motivo del número de
                                                            TIN no disponible:
                                                        </label>
                                                    </div>
                                                    {errors.SinIdFiscalMotivo2Descripcion && (
                                                        <Error>
                                                            {
                                                                errors.SinIdFiscalMotivo2Descripcion
                                                            }
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={s.input}
                                                        type="text"
                                                        id="SinIdFiscalMotivo2Descripcion"
                                                        name="SinIdFiscalMotivo2Descripcion"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            )
                                                                ? ""
                                                                : datosP?.SinIdFiscalMotivo2Descripcion
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Residencia fiscal (OCDE)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div className={s.box}>
                                                    <p className={s.textoGris}>
                                                        Nota: Su número de
                                                        Identificación Fiscal,
                                                        en caso de tener
                                                        residencia fiscal en
                                                        Argentina, es su CUIT o
                                                        CUIL. En caso de tener
                                                        otra residencia fiscal,
                                                        es el número de
                                                        identificación
                                                        tributaria que aplique
                                                        para dicha jurisdicción.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel7"}
                                onChange={handleChange("panel7")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel7bh-content"
                                    className={s.accordionTitle}
                                    id="panel7bh-header"
                                >
                                    <h3
                                        className={
                                            errors.InversorCalificado
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        INVERSOR CALIFICADO
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.textoBold}>
                                            ¿Sos un inversor calificado según
                                            CNV?
                                        </h6>
                                        <h6 className={s.texto}>
                                            Los inversores calificados se
                                            encuentran definidos en lo dispuesto
                                            por el artículo 12 del Capítulo VI
                                            del Título II de las Normas CNV
                                            (N.T. 2013 y modificatorias). Más
                                            información{" "}
                                            <Link
                                                href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/215000-219999/219405/texact-TituloII-CapVI.htm"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                acá
                                            </Link>
                                            .
                                        </h6>
                                        {errors.InversorCalificado && (
                                            <Error>
                                                {errors.InversorCalificado}
                                            </Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="7a"
                                                name="InversorCalificado"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Inversor calificado"
                                                    )
                                                        ? false
                                                        : datosP?.InversorCalificado ===
                                                          "No"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Inversor calificado"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="7a"
                                                className={s.label}
                                            >
                                                No
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="7b"
                                                name="InversorCalificado"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Inversor calificado"
                                                    )
                                                        ? false
                                                        : datosP?.InversorCalificado ===
                                                          "Si"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Inversor calificado"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="7b"
                                                className={s.label}
                                            >
                                                Si
                                            </label>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel8"}
                                onChange={handleChange("panel8")}
                                className={s.accordion}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandIcon />}
                                    aria-controls="panel8bh-content"
                                    id="panel8bh-header"
                                    className={s.accordionTitle}
                                >
                                    <h3
                                        className={
                                            errors.FATCA ||
                                            errors.FATCACienteAutoriza ||
                                            errors.FATCACienteInformado ||
                                            errors.SSN ||
                                            errors.ITIN ||
                                            errors.EIN
                                                ? s.subtituloError
                                                : s.subtitulo
                                        }
                                    >
                                        FOREIGN ACCOUNT TAX COMPLIANCE ACT
                                        (FATCA)
                                    </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={s.accordionDetails}
                                >
                                    <div className={s.accordionContent}>
                                        <h6 className={s.textoBold}>
                                            ¿Sos un persona estadounidense (US
                                            Person)?
                                        </h6>
                                        <h6
                                            className={s.texto}
                                            style={{ fontSize: "0.9em" }}
                                        >
                                            El Foreign Account Tax Compliance
                                            Act (FATCA) de los Estados Unidos de
                                            América establece que una persona
                                            estadounidense (US Person) es un
                                            individuo que reúne alguna de las
                                            siguientes características: es un
                                            ciudadano norteamericano con
                                            Pasaporte estadounidense; es
                                            residente estadounidense con tarjeta
                                            de residencia (Green Card); es
                                            residente parcial de los Estados
                                            Unidos (183 días en un año
                                            calendario o 122 días de promedio en
                                            los últimos 3 años calendario). Una
                                            declaración falsa en este sentido
                                            puede ser pasible de penas en virtud
                                            de las leyes de Estados Unidos de
                                            América.
                                            <Link
                                                href="https://www.irs.gov/businesses/corporations/foreign-account-tax-compliance-act-fatca"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Información sobre el Foreign
                                                Account Tax Compliance Act
                                                (FATCA)
                                            </Link>
                                            .
                                        </h6>
                                        {errors.FATCA && (
                                            <Error>{errors.FATCA}</Error>
                                        )}
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="8a"
                                                name="FATCA"
                                                value="No"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Foreign account tax compliance act (FATCA)"
                                                    )
                                                        ? false
                                                        : datosP?.FATCA === "No"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Foreign account tax compliance act (FATCA)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="8a"
                                                className={s.label}
                                            >
                                                No
                                            </label>
                                        </div>
                                        <div className={s.optionContainer}>
                                            <input
                                                className={s.input}
                                                type="radio"
                                                id="8b"
                                                name="FATCA"
                                                value="Si"
                                                defaultChecked={
                                                    subsanar &&
                                                    subsanar.includes(
                                                        "Foreign account tax compliance act (FATCA)"
                                                    )
                                                        ? false
                                                        : datosP?.FATCA === "Si"
                                                        ? true
                                                        : false
                                                }
                                                {...(subsanar.length > 0 &&
                                                    !subsanar.includes(
                                                        "Foreign account tax compliance act (FATCA)"
                                                    ) && {
                                                        disabled: true,
                                                    })}
                                                onChange={(e) => handleClick(e)}
                                            />
                                            <label
                                                htmlFor="8b"
                                                className={s.label}
                                            >
                                                Si
                                            </label>
                                        </div>
                                        {(form.FATCA === "Si" ||
                                            (!form.FATCA &&
                                                datosP?.FATCA === "Si" &&
                                                subsanar &&
                                                !subsanar.includes(
                                                    "Foreign account tax compliance act (FATCA)"
                                                ))) && (
                                            <div>
                                                {errors.FATCAClienteAutoriza && (
                                                    <Error>
                                                        {
                                                            errors.FATCAClienteAutoriza
                                                        }
                                                    </Error>
                                                )}
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="8bCheck1"
                                                        name="FATCAClienteAutoriza"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            )
                                                                ? false
                                                                : datosP?.FATCAClienteAutoriza
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="8bCheck1"
                                                        className={s.labelCheck}
                                                    >
                                                        Quien suscribe presta
                                                        consentimiento y
                                                        autoriza expresamente,
                                                        en los términos de la
                                                        Ley Nº 25.326 y su
                                                        Reglamentación; y la Ley
                                                        Nº 26.831, a Rava
                                                        Bursátil S.A. para que
                                                        proporcione al Internal
                                                        Revenue Service (IRS) de
                                                        los Estados Unidos
                                                        información respecto de
                                                        su persona y/o cuenta
                                                        comitente. Asimismo,
                                                        reconoce expresamente
                                                        que el envío de dicha
                                                        información al IRS en el
                                                        marco de la presente
                                                        autorización no se
                                                        encuentra protegido por
                                                        el secreto bursátil,
                                                        privacidad y protección
                                                        de la información,
                                                        facilitando de este
                                                        modo, el cumplimiento de
                                                        la normativa FATCA a
                                                        Rava Bursátil S.A..
                                                        Quien suscribe autoriza
                                                        a Rava Bursátil S.A. a
                                                        retener un porcentaje
                                                        sobre créditos
                                                        imponibles que pudieran
                                                        ser recibidos en su
                                                        cuenta, en el caso de
                                                        que no le haya
                                                        proporcionado la
                                                        información que le sea
                                                        requerida para
                                                        documentar mi status de
                                                        contribuyente
                                                        estadounidense.
                                                    </label>
                                                </div>
                                                {errors.FATCAClienteInformado && (
                                                    <Error>
                                                        {
                                                            errors.FATCAClienteInformado
                                                        }
                                                    </Error>
                                                )}
                                                <div
                                                    className={
                                                        s.optionContainer
                                                    }
                                                >
                                                    <input
                                                        className={s.input}
                                                        type="checkbox"
                                                        id="8bCheck2"
                                                        name="FATCAClienteInformado"
                                                        defaultChecked={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            )
                                                                ? false
                                                                : datosP?.FATCAClienteInformado
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            ) && {
                                                                disabled: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleCheck(e)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="8bCheck2"
                                                        className={s.labelCheck}
                                                    >
                                                        Quien suscribe reconoce
                                                        que ha sido previamente
                                                        informado y que podrá
                                                        ejercer los derechos de
                                                        acceso, rectificación y
                                                        supresión de los datos
                                                        previstos por la Ley
                                                        25.326 ante Rava
                                                        Bursátil S.A. Asimismo,
                                                        asume el compromiso de
                                                        informar si su situación
                                                        impositiva se modifica y
                                                        pasa a ser una persona
                                                        estadounidense o deja de
                                                        serlo, notificando de
                                                        este hecho en un plazo
                                                        máximo de 30 días de
                                                        ocurrido mediante la
                                                        presentación de una
                                                        nueva declaración
                                                        jurada.
                                                    </label>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: 15,
                                                        marginBotton: 15,
                                                    }}
                                                >
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Social Security
                                                            Number (SSN)
                                                        </label>
                                                    </div>
                                                    {errors.SSN && (
                                                        <Error>
                                                            {errors.SSN}
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.SSN && 'errorCampo'}`}
                                                        type="text"
                                                        id="SSN"
                                                        name="SSN"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            )
                                                                ? ""
                                                                : datosP?.SSN
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: 15,
                                                        marginBotton: 15,
                                                    }}
                                                >
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Individual Taxpayer
                                                            Identification
                                                            Number (ITIN)
                                                        </label>
                                                    </div>
                                                    {errors.ITIN && (
                                                        <Error>
                                                            {errors.ITIN}
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.ITIN && 'errorCampo'}`}
                                                        type="text"
                                                        id="ITIN"
                                                        name="ITIN"
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            )
                                                                ? ""
                                                                : datosP?.ITIN
                                                        }
                                                        value={form.name}
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: 15,
                                                        marginBotton: 15,
                                                    }}
                                                >
                                                    <div>
                                                        <label
                                                            className={
                                                                s.labelForm
                                                            }
                                                        >
                                                            Employer
                                                            Identification
                                                            Number (EIN)
                                                        </label>
                                                    </div>
                                                    {errors.EIN && (
                                                        <Error>
                                                            {errors.EIN}
                                                        </Error>
                                                    )}
                                                    <InputTotalWidth
                                                        className={`${s.input} ${errors.EIN && 'errorCampo'}`}
                                                        type="text"
                                                        id="EIN"
                                                        name="EIN"
                                                        value={form.name}
                                                        defaultValue={
                                                            subsanar &&
                                                            subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            )
                                                                ? ""
                                                                : datosP?.EIN
                                                        }
                                                        {...(subsanar.length >
                                                            0 &&
                                                            !subsanar.includes(
                                                                "Foreign account tax compliance act (FATCA)"
                                                            ) && {
                                                                readOnly: true,
                                                            })}
                                                        onChange={(e) =>
                                                            handleClick(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className={s.botonContainer}>
                            <Button
                                className={s.boton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                Continuar
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </div>
        <Contacto/>
        </>
    );
};
