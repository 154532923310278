import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cambiarPantallaActual } from "../../redux/actions";
import s from "./Home.module.css";
import NuevaApertura from "../../components/NuevaApertura/NuevaApertura";
import CarruselFotos from "../../components/CarruselFotos/CarruselFotos";
import { Aside } from "../../components/Aside/Aside";

export const Home = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
	}, [dispatch]);

	/*     function clearLocalStorage() {
        localStorage.clear();
    }
    clearLocalStorage(); */

	const subtitulo = "Acompañamos tu crecimiento";
	const titulo = "Animate a invertir en tu futuro";

	return (
		<div className={s.container}>
			{/* <CarruselFotos />
			<Aside /> */}
			<div className={s.aperturaContainer}>
				<div className={s.apertura}>
					<div className={s.box}>
						<div>
							<span className={s.subtitulo}>{subtitulo}</span>
						</div>
						<span className={s.titulo}>
							<b> {titulo.toLocaleUpperCase()}</b>
						</span>
					</div>
					<NuevaApertura />
				</div>
			</div>
		</div>
	);
};

export default Home;
