import React from "react";
import { Slide } from "react-slideshow-image";
import s from "./CarruselFotos.module.css";
import "react-slideshow-image/dist/styles.css";

// const images = [
// 	"../images/slide_1.png",
// 	"../images/slide_2.png",
// 	"../images/slide_3.png",
// 	"../images/slide_4.png",
// ];

export const CarruselFotos = () => {
	return (
		<div className={s.container}>
			<Slide arrows={false} scale={0.4} duration={4000}>
				<img
					className={s.imagen}
					src={require("../../images/slide_fondo_1.png")}
					alt="slide_1"
				/>

				<img
					className={s.imagen}
					src={require("../../images/slide_fondo_2.png")}
					alt="slide_2"
				/>

				<img
					className={s.imagen}
					src={require("../../images/slide_fondo_3.png")}
					alt="slide_3"
				/>

				<img
					className={s.imagen}
					src={require("../../images/slide_fondo_4.png")}
					alt="slide_4"
				/>
			</Slide>
		</div>
	);
};
export default CarruselFotos;
