import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
	cambiarPantallaActual,
	getEtapasCompletas,
	getResultadoTest,
	getImagenes,
} from "../../redux/actions";
import s from "./PerfilInversor.module.css";
import { TituloItem } from "../../components/PerfilInversor/inversorElements"; 
import { etapaCompletaAnteriormente } from "../../helpers/helperEtapaCompleta";
import { InversorTest } from "../../components/PerfilInversor/InversorTest"; 
import { Contacto } from "../../components/Contacto/Contacto";

export const PerfilInversor = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const datosP = useSelector((state) => state.datosEtapasReducer.resultadoTestPrevio);
	const [input, setInput] = useState({
		HaInvertido: "",
		GradoConocimiento: "",
		ActitudInversiones: "",
		Reserva: "",
		PlazoEstimado: "",
		PorcentajeAhorros: "",
	});
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

	useEffect(() => {
		dispatch(cambiarPantallaActual(2));
		// dispatch(getImagenes());
		if (!etapaCompletaAnteriormente("Adjuntos", etapasCompletadas)) {
			dispatch(getEtapasCompletas("Adjuntos"));
		}
	}, [dispatch]);

	async function handleChange(e) {
		setInput({
			...input,
			[e.target.name]: e.target.value,
		});
		if (e.target.value === input.name) {
			return true;
		} else {
			return false;
		}
	}

	function handleSubmit(e) {
		e.preventDefault();
		setInput({
			HaInvertido: "",
			GradoConocimiento: "",
			ActitudInversiones: "",
			Reserva: "",
			PlazoEstimado: "",
			PorcentajeAhorros: "",
		});
		dispatch(getResultadoTest());
		navigate("../declaraciones");
	}

	return (
		<>
		<div className={s.container}>
			<h3 className={s.titulo}> TEST DEL INVERSOR </h3>
			<form onSubmit={(e) => handleSubmit(e)} className={s.form}>
				<ul className={s.lista}>
					<li>
						<div>
							<TituloItem className={s.tituloItem}>
								1. Experiencia en inversiones:{" "}
							</TituloItem>
							<h6 className={s.subtituloItem}>
								{" "}
								¿Ha invertido alguna vez en el mercado de
								capitales?{" "}
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="1a"
									name="HaInvertido"
									value="Si"
									defaultChecked={
										datosP?.ExperenciasInversiones === "Si"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="1a" className={s.label}>
									Si
								</label>
							</div>

							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="1b"
									name="HaInvertido"
									value="No"
									defaultChecked={
										datosP?.ExperenciasInversiones === "No"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="1b" className={s.label}>
									No
								</label>
							</div>
						</div>
					</li>
					<li>
						<div>
							<TituloItem>
								2. Grado de conocimiento del mercado de
								capitales:{" "}
							</TituloItem>
							<h6 className={s.subtituloItem}>
								{" "}
								¿Cuál seria la canasta de inversiones que más lo
								identifica?{" "}
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="2a"
									name="GradoConocimiento"
									value="menor"
									defaultChecked={
										datosP?.ConocimientoMercadosCapitales ===
										"menor"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="2a" className={s.label}>
									Bonos, caución colocadora
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="2b"
									name="GradoConocimiento"
									value="intermedios"
									defaultChecked={
										datosP?.ConocimientoMercadosCapitales ===
										"intermedios"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="2b" className={s.label}>
									Bonos, caución colocadora, fondo comun de
									inversión, cheque de pago diferido,
									acciones, Cedears
								</label>
							</div>

							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="2c"
									name="GradoConocimiento"
									value="mayor"
									defaultChecked={
										datosP?.ConocimientoMercadosCapitales ===
										"mayor"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="2c" className={s.label}>
									Bonos, caución colocadora, fondo comun de
									inversión, cheque de pago diferido,
									acciones, Cedears, caución tomadora,
									futuros, opciones e índices
								</label>
							</div>
						</div>
					</li>
					<li>
						<div>
							<TituloItem>
								3. Objetivo de la inversión:
							</TituloItem>
							<h6 className={s.subtituloItem}>
								¿Cuál de las siguientes afirmaciones identifica
								mejor su actitud hacia las inversiones?
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="3a"
									name="ActitudInversiones"
									value="menorRiesgo"
									defaultChecked={
										datosP?.ObjetivoInversion ===
										"menorRiesgo"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="3a" className={s.label}>
									Busco la protección del capital pero acepto
									riesgo bajo si puedo obtener mayor
									rentabilidad
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="3b"
									name="ActitudInversiones"
									value="riesgoIntermedio"
									defaultChecked={
										datosP?.ObjetivoInversion ===
										"riesgoIntermedio"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="3b" className={s.label}>
									Admito un riesgo medio a cambio de una
									posibilidad de mayor rentabilidad en el
									mediano / largo plazo
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="3c"
									name="ActitudInversiones"
									value="mayorRiesgo"
									defaultChecked={
										datosP?.ObjetivoInversion ===
										"mayorRiesgo"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="3c" className={s.label}>
									Admito un riesgo elevado de la inversión a
									cambio de una posibilidad de alta
									rentabilidad
								</label>
							</div>
						</div>
					</li>
					<li>
						<div>
							<TituloItem>4. Situación financiera: </TituloItem>
							<h6 className={s.subtituloItem}>
								¿Dispone de alguna reserva económica asignada a
								cubrir imprevistos?
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="4a"
									name="Reserva"
									value="elevada"
									defaultChecked={
										datosP?.SituacionFinanciera ===
										"elevada"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="4a" className={s.label}>
									Sí, una cantidad elevada
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="4b"
									name="Reserva"
									value="tresMesesDeIngresos"
									defaultChecked={
										datosP?.SituacionFinanciera ===
										"tresMesesDeIngresos"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="4b" className={s.label}>
									Sí, alrededor de 3 meses de mis ingresos
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="4c"
									name="Reserva"
									value="nula"
									defaultChecked={
										datosP?.SituacionFinanciera === "nula"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="4c" className={s.label}>
									No
								</label>
							</div>
						</div>
					</li>
					<li>
						<div>
							<TituloItem>
								5. Horizonte de inversión previsto:{" "}
							</TituloItem>
							<h6 className={s.subtituloItem}>
								¿Cuál es el plazo estimado en el que espera
								obtener resultados en el mercado de capitales?
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="5a"
									name="PlazoEstimado"
									value="largoPlazo"
									defaultChecked={
										datosP?.HorizontePrevisto ===
										"largoPlazo"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="5a" className={s.label}>
									Más de 2 años
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="5b"
									name="PlazoEstimado"
									value="plazoIntermedio"
									defaultChecked={
										datosP?.HorizontePrevisto ===
										"plazoIntermedio"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="5b" className={s.label}>
									Entre 6 meses y 2 años
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="5c"
									name="PlazoEstimado"
									value="cortoPlazo"
									defaultChecked={
										datosP?.HorizontePrevisto ===
										"cortoPlazo"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="5c" className={s.label}>
									Menos de 6 meses
								</label>
							</div>
						</div>
					</li>
					<li>
						<div>
							<TituloItem>6. Ahorro:</TituloItem>
							<h6 className={s.subtituloItem}>
								¿Qué porcentaje de sus ahorros está dispuesto a
								destinar a inversiones en el mercado de
								capitales?
							</h6>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="6a"
									name="PorcentajeAhorros"
									value="25"
									defaultChecked={
										datosP?.Ahorro === "25" ? true : false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="6a" className={s.label}>
									Menos del 25%
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="6b"
									name="PorcentajeAhorros"
									value="25/60"
									defaultChecked={
										datosP?.Ahorro === "25/60"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="6b" className={s.label}>
									Entre el 25% y el 60%
								</label>
							</div>
							<div className={s.optionContainer}>
								<input
									className={s.input}
									type="radio"
									id="6c"
									name="PorcentajeAhorros"
									value="masDel60"
									defaultChecked={
										datosP?.Ahorro === "masDel60"
											? true
											: false
									}
									onClick={(e) => handleChange(e)}
								/>
								<label htmlFor="6c" className={s.label}>
									Más del 60%
								</label>
							</div>
						</div>
					</li>
				</ul>

				{(input.HaInvertido || datosP?.ExperenciasInversiones) &&
					(input.GradoConocimiento ||
						datosP?.ConocimientoMercadosCapitales) &&
					(input.ActitudInversiones || datosP?.ObjetivoInversion) &&
					(input.Reserva || datosP?.SituacionFinanciera) &&
					(input.PlazoEstimado || datosP?.HorizontePrevisto) &&
					(input.PorcentajeAhorros || datosP?.Ahorro) && (
						<InversorTest
							HaInvertido={
								input.HaInvertido
									? input.HaInvertido
									: datosP?.ExperenciasInversiones
							}
							GradoConocimiento={
								input.GradoConocimiento
									? input.GradoConocimiento
									: datosP?.ConocimientoMercadosCapitales
							}
							ActitudInversiones={
								input.ActitudInversiones
									? input.ActitudInversiones
									: datosP?.ObjetivoInversion
							}
							Reserva={
								input.Reserva
									? input.Reserva
									: datosP?.SituacionFinanciera
							}
							PlazoEstimado={
								input.PlazoEstimado
									? input.PlazoEstimado
									: datosP?.HorizontePrevisto
							}
							PorcentajeAhorros={
								input.PorcentajeAhorros
									? input.PorcentajeAhorros
									: datosP?.Ahorro
							}
						/>
					)}

				{(input.HaInvertido || datosP?.ExperenciasInversiones) &&
				(input.GradoConocimiento ||
					datosP?.ConocimientoMercadosCapitales) &&
				(input.ActitudInversiones || datosP?.ObjetivoInversion) &&
				(input.Reserva || datosP?.SituacionFinanciera) &&
				(input.PlazoEstimado || datosP?.HorizontePrevisto) &&
				(input.PorcentajeAhorros || datosP?.Ahorro) ? (
					<div className={s.botonContainer}>
						<Button
							className={s.boton}
							type="submit"
							variant="contained"
							color="primary"
							size="large"
							fullWidth
						>
							Continuar
						</Button>
					</div>
				) : (
					<div className={s.botonContainer}>
						<Button
							className={s.boton}
							type="submit"
							disabled
							variant="contained"
							// color="primary"
							size="large"
							fullWidth
						>
							Continuar
						</Button>
					</div>
				)}
			</form>
		</div>
		<Contacto/>
		</>
	);
};
