import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector } from "react-redux";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import s from "./Stepper.module.css";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#00B7CE",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#00B7CE",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	fontWeight: "bold",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		background: "#00B7CE",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		background: "#00B7CE",
	}),
}));

const ColorlibStepIconRootColorRed = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	fontWeight: "bold",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		background: "#B00020",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		background: "#B00020",
	}),
}));

const steps = [
	"Datos personales",
	"Adjuntos",
	"Perfil inversor",
	"Declaracion jurada",
	"Términos y condiciones",
	"Verificación SMS",
];

export default function CustomizedSteppers() {
	const pantallaActual = useSelector((state) => state.esteticaReducer.pantallaActual);
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);
	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);

	function ColorlibStepIcon(props) {
		const { active, completed, className } = props;
		const icons = {
			1: "01",
			2: "02",
			3: "03",
			4: "04",
			5: "05",
			6: "06",
		};
		if (subsanar?.length === 0) {
			return (
				<ColorlibStepIconRoot
					ownerState={{ completed, active }}
					className={className}
				>
					{icons[String(props.icon)]}
				</ColorlibStepIconRoot>
			);
		} else {
			return (
				<ColorlibStepIconRootColorRed
					ownerState={{ completed, active }}
					className={className}
				>
					{icons[String(pantallaActual + 1)]}
				</ColorlibStepIconRootColorRed>
			);
		}
	}

	ColorlibStepIcon.propTypes = {
		/**
		 * Whether this step is active.
		 * @default false
		 */
		active: PropTypes.bool,
		className: PropTypes.string,
		/**
		 * Mark the step as completed. Is passed to child components.
		 * @default false
		 */
		completed: PropTypes.bool,
		/**
		 * The label displayed in the step icon.
		 */
		icon: PropTypes.node,
	};

	const redireccion = (numeroRutaElegida) => {
		let path;
		let rutaActualReal = etapasCompletadas.length;
		switch (numeroRutaElegida) {
			case 0:
				path = "/datos-personales";
				break;
			case 1:
				path = "/adjuntos";
				break;
			case 2:
				path = "/perfil-inversor";
				break;
			case 3:
				path = "/declaraciones";
				break;
			case 4:
				path = "/terminos";
				break;
			case 5:
				path = "/verificacion-sms";
				break;
			default:
				path = "/datos-personales";
				break;
		}
		if (numeroRutaElegida <= rutaActualReal) {
			window.location.href = path;
		}
	};

	if (subsanar?.length === 0) {
		return (
			<Stack spacing={4} className={s.stepper}>
				<Stepper
					alternativeLabel
					activeStep={pantallaActual}
					connector={<ColorlibConnector />}
				>
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel
								StepIconComponent={ColorlibStepIcon}
								onClick={() => redireccion(index)}
							>
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Stack>
		);
	} else {
		return (
			<Stack spacing={4} className={s.stepper}>
				{
					<Stepper
						alternativeLabel
						activeStep={pantallaActual}
						connector={<ColorlibConnector />}
					>
						<Step
							style={
								darkModeRedux
									? {
											backgroundColor: "#484B4F",
											display: "flex",
											justifyContent: "space-around",
											alignItems: "center",
											padding: 12,
									  }
									: {
											backgroundColor: "#F3F3F3",
											display: "flex",
											justifyContent: "space-around",
											alignItems: "center",
											padding: 12,
									  }
							}
						>
							<StepLabel
								StepIconComponent={ColorlibStepIcon}
								onClick={() => redireccion(pantallaActual)}
							>
								{steps[pantallaActual]}
							</StepLabel>
							<span
								style={{
									width: "40%",
									textAlign: "right",
									margin: 0,
									color: darkModeRedux ? "white" : "#004B6F",
									fontWeight: "bold",
								}}
							>
								Completá los campos marcados en rojo
							</span>
						</Step>
					</Stepper>
				}
			</Stack>
		);
	}
}
