import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 12,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#00B7CE",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#00B7CE",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderRadius: 1,
	},
}));
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	width: 25,
	height: 25,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		background: "#00B7CE",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		background: "#00B7CE",
	}),
}));

const ColorlibStepIconRootColorRed = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	width: 25,
	height: 25,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		background: "#B00020",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		background: "#B00020",
	}),
}));

const steps = [
	"Datos personales",
	"Adjuntos",
	"Perfil inversor",
	"Declaracion jurada",
	"Términos y condiciones",
	"Verificación SMS",
];

export default function HorizontalLinearStepper() {
	const pantallaActual = useSelector((state) => state.esteticaReducer.pantallaActual);
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);
	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);

	const redireccion = (numeroRutaElegida) => {
		let path;
		let rutaActualReal = etapasCompletadas.length;
		switch (numeroRutaElegida) {
			case 0:
				path = "/datos-personales";
				break;
			case 1:
				path = "/adjuntos";
				break;
			case 2:
				path = "/perfil-inversor";
				break;
			case 3:
				path = "/declaraciones";
				break;
			case 4:
				path = "/terminos";
				break;
			case 5:
				path = "/verificacion-sms";
				break;
			default:
				path = "/datos-personales";
				break;
		}
		if (numeroRutaElegida <= rutaActualReal) {
			window.location.href = path;
		}
	};

	function ColorlibStepIcon(props) {
		const { active, completed, className } = props;

		const icons = {
			1: "01",
			2: "02",
			3: "03",
			4: "04",
			5: "05",
			6: "06",
		};

		if (subsanar?.length === 0) {
			return (
				<ColorlibStepIconRoot
					ownerState={{ completed, active }}
					className={className}
				>
					{icons[String(props.icon)]}
				</ColorlibStepIconRoot>
			);
		} else {
			return (
				<ColorlibStepIconRootColorRed
					ownerState={{ completed, active }}
					className={className}
				>
					{icons[String(pantallaActual + 1)]}
				</ColorlibStepIconRootColorRed>
			);
		}
	}

	if (subsanar?.length === 0) {
		return (
			<Box sx={{ width: "100%" }}>
				<Stepper
					alternativeLabel
					activeStep={pantallaActual}
					connector={<ColorlibConnector />}
				>
					{steps.map((label, index) => {
						return (
							<Step key={label}>
								<StepLabel
									StepIconComponent={ColorlibStepIcon}
									onClick={() => redireccion(index)}
								></StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: "90%", margin: "0 auto" }}>
				<Step
					style={
						darkModeRedux
							? {
									backgroundColor: "#484B4F",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									padding: 18,
							  }
							: {
									backgroundColor: "#F3F3F3",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									padding: 18,
							  }
					}
				>
					<StepLabel
						StepIconComponent={ColorlibStepIcon}
						onClick={() => redireccion(pantallaActual)}
					></StepLabel>
					<span
						style={{
							width: "40%",
							textAlign: "left",
							color: darkModeRedux ? "white" : "#004B6F",
							fontWeight: "bold",
							fontSize: 12,
						}}
					>
						Completá los campos marcados en rojo
					</span>
				</Step>
			</Box>
		);
	}
}
