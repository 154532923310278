import {
	CAMBIAR_DARK_MODE,
	CAMBIAR_PANTALLA_ACTUAL,
} from "../constantes";

const initialState = {
    darkMode: false,
    pantallaActual: -1,
};

function esteticaReducer(state = initialState, action) {
	switch (action.type) {
        case CAMBIAR_DARK_MODE:
			return {
				...state,
				darkMode: action.payload,
			};

		case CAMBIAR_PANTALLA_ACTUAL:
			return {
				...state,
				pantallaActual: action.payload,
			};
        default:
            return state;
    }
}

export default esteticaReducer;
