import apiInstance from "../utils/Axios";
import FormData from "form-data";
import { reduceImageSize } from "../helpers/helperCompresorDeImagenes";

//Nueva Apertura

const signup = async (params) => {
	return await apiInstance.post("/auth/signup", { ...params });
};
const validation = async (code) => {
	return await apiInstance.post("/auth/validate", { code });
};

// Nueva apertura de usuarios viejos

const signupClientOld = async (params) => {
	return await apiInstance.post("/auth/signupClientOld", { ...params });
};

// Apertura cotitulares

const duplicateEmail = async (email, numCuenta) => {
	return await apiInstance.post("/cotitulares/duplicateemail", {
		email,
		numCuenta,
	});
};

const signUpCotitularPluripersonal = async (
	name,
	last_name,
	email,
	phone,
	idTitular
) => {
	return await apiInstance.post("/cotitulares/signUpCotitularPluripersonal", {
		name,
		last_name,
		email,
		phone,
		idTitular,
	});
};

const signupCotitulares = async (params) => {
	return await apiInstance.post("/cotitulares/signup", { ...params });
};

const conformidadIncorporacion = async (params) => {
	return await apiInstance.post("/cotitulares/confirmacionCotitular", {
		...params,
	});
};

//Reanudar Apertura

const reanudarApertura = async (email, code) => {
	return await apiInstance.post("/auth/restart", { code, email });
};

//Datos apertura

const putNombre = async (name, last_name) => {
	return await apiInstance.put("/auth/edit", { name, last_name });
};

//Datos de subsanados

const subsanadosData = async (email, code) => {
	return await apiInstance.get("/auth/informe", { email, code });
};

//Datos Personales

const getDatosPersonales = async (params) => {
	return await apiInstance.get("/datospersonales", { ...params });
};

const saveDatosPersonales = async (params) => {
	return await apiInstance.post("/datospersonales", { ...params });
};

const updateDatosPersonales = async (params) => {
	return await apiInstance.put("/datospersonales", { ...params });
};

const subsanarDatosPersonales = async (params) => {
	return await apiInstance.put("/datospersonales/subsanar", { ...params });
};

export async function checkDatos(dni) {
	return await apiInstance.post(`/datospersonales/checkdatos`, {dni});
}


//Adjuntos

const adjuntarImagen = async (imagenes) => {
	let formData = new FormData();
	for (let i = 0; i < imagenes.length; i++) {
		let imagenComprimida = await reduceImageSize(imagenes[i]);
		formData.append("imagenes", imagenComprimida);
	}
	return await apiInstance.post("/adjuntos/upload", formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
};
const putImagen = async (id, order, imagen) => {
	let formData = new FormData();
	let imagenComprimida = await reduceImageSize(imagen);
	formData.append("file", imagenComprimida);
	formData.append("order", order);
	return await apiInstance.put(`/adjuntos/upload/${id}`, formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
};
const subsanarImagen = async (id, order, imagen) => {
	let formData = new FormData();
	let imagenComprimida = await reduceImageSize(imagen);
	formData.append("file", imagenComprimida);
	formData.append("order", order);
	return await apiInstance.put(`/adjuntos/subsanar/${id}`, formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});
};
const getImagenes = async () => {
	return await apiInstance.get("/adjuntos/files");
};
const getUrlTemporal = async (id) => {
	return await apiInstance.get(`/adjuntos/${id}`);
};
const deleteImagen = async (id) => {
	return await apiInstance.delete(`/adjuntos/${id}`);
};

//Perfil Inversor
const getTestInversor = async (params) => {
	return await apiInstance.get("/test", { ...params });
};

const testInversor = async (params) => {
	return await apiInstance.post("/test/add", { ...params });
};

const updateTestInversor = async (params) => {
	return await apiInstance.put("/test", { ...params });
};

//DDJJ

const declaracionJurada = async (params) => {
	return await apiInstance.post("/declaracion", { ...params });
};

const getDDJJ = async (params) => {
	return await apiInstance.get("/declaracion", { ...params });
};

const updateDDJJ = async (params) => {
	return await apiInstance.put("/declaracion", { ...params });
};

const subsanarDeclaraciones = async (params) => {
	return await apiInstance.put("/declaracion/subsanar", { ...params });
};

//Terminos

const aceptarTerminos = async () => {
	return await apiInstance.post("/terminos", { Status: true });
};

const getTerminos = async () => {
	return await apiInstance.get("/terminos");
};

//SMS

const enviarMensaje = async () => {
	return await apiInstance.post("/sendsms");
};
const validarCodigoSms = async (code) => {
	return await apiInstance.put("/sendsms/validate", { code });
};
const cambiarNumero = async (phone) => {
	return await apiInstance.put("/sendsms/setphone", {
		phone,
	});
};
const setearTipoDeCuenta = async (tipocuenta) => {
	return await apiInstance.put("/auth/settipocuenta", { tipocuenta });
};

export default {
	saveDatosPersonales,
	signup,
	validation,
	adjuntarImagen,
	declaracionJurada,
	testInversor,
	aceptarTerminos,
	enviarMensaje,
	validarCodigoSms,
	cambiarNumero,
	reanudarApertura,
	getDatosPersonales,
	updateDatosPersonales,
	getTestInversor,
	updateTestInversor,
	getImagenes,
	getUrlTemporal,
	deleteImagen,
	getDDJJ,
	updateDDJJ,
	getTerminos,
	putImagen,
	putNombre,
	subsanadosData,
	subsanarDatosPersonales,
	subsanarDeclaraciones,
	subsanarImagen,
	signupClientOld,
	signupCotitulares,
	conformidadIncorporacion,
	duplicateEmail,
	setearTipoDeCuenta,
	signUpCotitularPluripersonal,
};
