import { combineReducers } from "redux";
import userDataReducer from './userDataReducer'
import datosEtapasReducer from './datosEtapasReducer'
import adjuntosReducer from './adjuntosReducer'
import reanudarReducer from "./reanudarReducer";
import subsanarReducer from "./subsanarReducer";
import esteticaReducer from "./esteticaReducer";
import {
	RESTART_STORE,
} from "../constantes";

const allReducers = combineReducers({
    userDataReducer,
    datosEtapasReducer,
    adjuntosReducer,
    reanudarReducer,
    subsanarReducer,
    esteticaReducer,
});

function rootReducer(state, action){
    if (action.type === RESTART_STORE) {
        state = undefined;
    }
    return allReducers(state, action);
};

export default rootReducer;