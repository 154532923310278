import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	saveDatosPersonales,
	getEtapasCompletas,
	putUserData
} from "../redux/actions";
import { useNavigate } from "react-router-dom";
import { etapaCompletaAnteriormente } from "../helpers/helperEtapaCompleta";
import apiRoutes, { checkDatos } from "../api/apiRoutes";
import { from } from "form-data";


/**
 * Hook que manipula el formulario para agregar y modificar los datos personales
 * @param {Objetc} initialForm Formulario inicial
 * @param {Object} validateForm Función validadora que devuelque los errores
 * @param {Object} tipoCuenta String que indica si es pluripersonal
 * @returns {Objetc} 
 */


export const useFormDatos = (initialForm, validateForm, tipoCuenta) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const usuario = useSelector((state) => state.userDataReducer.usuario);

	const datosP = useSelector((state) => state.datosEtapasReducer.datosPersonalesPrevios);

	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);

	const rutasSubsanar = useSelector((state) => state.subsanarReducer.rutasSubsanar)
	
	const [form, setForm] = useState(initialForm);

	const [datosDNI, setDatosDNI] = useState(null);

    const [errors, setErrors] = useState({});

    const datosCheck = async (dni) => {
        try {
            const res = await checkDatos(dni);
			console.log('FIRST RES', res.data)
            return res.data;
        } catch (error) {
            console.log(error);
        }
    };


	useEffect(() => {
		// Ir comprobando los errores mientras el usuario modifica los campos,
		// una vez que ya haya errores, después del primer intento de submit
		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form, datosP, subsanar, tipoCuenta, datosDNI));
		}
		
	}, [form]);

	useEffect(() => {
		// Chek de errores cuando los datosDNI fueron seteados
		if(datosDNI){
			setErrors(validateForm(form, datosP, subsanar, tipoCuenta, datosDNI));
		}

	}, [datosDNI]);
	
	useEffect(() => {
		// Para que ya esten los campos de errores marcados aunque no hayan intentando
		// hacer submit (ES SOLO EN CASO DE SUBSANACION)
		if(subsanar?.length > 0){
			setErrors(validateForm(form, datosP, subsanar, tipoCuenta));
		}
	}, [datosP]);

	const handleChange = (e, newValue) => {
		const { name, value } = e.target;
		if (newValue && newValue !== undefined) {
			// Esta seccion es solo para el input de Nacionalidad
			// (es un componente de librería externa)
            setForm({
                ...form,
                Nacionalidad: newValue.toString(),
            });
        } else {
			// Acá se maneja el resto del form
            setForm({
                ...form,
                [name]: value,
            });
        }

		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form, datosP, subsanar, tipoCuenta));
		}
	};

	const changeName = async(name, last_name) => {
		await apiRoutes.putNombre(name, last_name);
	}

	const handleBlur = (e) => {
		handleChange(e);
	};
	const handleSubmit = async (e) => {
		e.preventDefault()

		//Se le asigna para comprobar el form con los datos conseguidos con NOSIS
		form.nombre = form.nombre ? form.nombre : usuario?.name;
        form.apellido = form.apellido ? form.apellido : usuario.last_name;

		const resDatosDNI = form.NumeroDNI && form.TipoDocumento === "DNI"? await datosCheck(form.NumeroDNI) : null;
		setDatosDNI(resDatosDNI)
		

		let errorsVariableEstatica = validateForm(form, datosP, subsanar, tipoCuenta, resDatosDNI);
		setErrors(validateForm(form, datosP, subsanar, tipoCuenta, resDatosDNI));

		// Se comprueba que no haya errores
			if (
				Object.keys(errors).length === 0 &&
				Object.keys(errorsVariableEstatica).length === 0 
			) {
				try {

					// Se asignan al nombre y apellido los datos anteriores en caso
					// de no agregar nuevos
					changeName(
						form.nombre ? form.nombre : usuario?.name,
						form.apellido ? form.apellido : usuario.last_name,
					)

					if (etapasCompletadas?.includes("Datos Personales") || subsanar?.length > 0) {
					// En caso de modificacion de datos ya subidos:
					// Se asignan los datos anteriores en caso de no modificarlos para que no se envié 
					// al back un string vació

					form.Genero = form.Genero ? form.Genero : datosP?.Genero;
					form.Nacionalidad = form.Nacionalidad ? form.Nacionalidad : datosP?.Nacionalidad;
					form.FechaNacimiento = form.FechaNacimiento ? form.FechaNacimiento : datosP?.FechaNacimiento;
					form.LugarNacimiento = form.LugarNacimiento ? form.LugarNacimiento : datosP?.LugarNacimiento;
					form.TipoDocumento = form.TipoDocumento ? form.TipoDocumento : datosP?.TipoDocumento;
					form.NumeroDNI = form.NumeroDNI ? form.NumeroDNI : datosP?.NumeroDNI;
					form.TipoIdFiscal = form.TipoIdFiscal ? form.TipoIdFiscal : datosP.TipoIdFiscal;
					form.NumeroIdFiscal = form.NumeroIdFiscal ? form.NumeroIdFiscal : datosP?.NumeroIdFiscal;
					form.Calle = form.Calle ? form.Calle : datosP?.Calle;
					form.Numero = form.Numero ? form.Numero : datosP?.Numero;
					form.Piso = form.Piso ? form.Piso : datosP?.Piso;
					form.Localidad = form.Localidad ? form.Localidad : datosP?.Localidad;
					form.Provincia_estado = form.Provincia_estado ? form.Provincia_estado.toUpperCase() : datosP?.Provincia_estado;
					form.CodigoPostal = form.CodigoPostal ? form.CodigoPostal : datosP?.CodigoPostal;
					form.Pais = form.Pais ? form.Pais : datosP?.Pais;
					form.EstadoCivil = form.EstadoCivil ? form.EstadoCivil : datosP?.EstadoCivil;
					form.NombreConyuge = form.NombreConyuge ? form.NombreConyuge : datosP?.NombreConyuge;
					form.ApellidoConyuge = form.ApellidoConyuge ? form.ApellidoConyuge : datosP?.ApellidoConyuge;
					form.GeneroConyuge = form.GeneroConyuge ? form.GeneroConyuge : datosP?.GeneroConyuge;
					form.FechaDeNacimientoConyuge = form.FechaDeNacimientoConyuge ? form.FechaDeNacimientoConyuge : datosP?.FechaDeNacimientoConyuge;
					form.TipoDeDocumentoConyuge = form.TipoDeDocumentoConyuge ? form.TipoDeDocumentoConyuge : datosP?.TipoDeDocumentoConyuge;
					form.NumeroDeDocumentoConyuge = form.NumeroDeDocumentoConyuge ? form.NumeroDeDocumentoConyuge : datosP?.NumeroDeDocumentoConyuge;
					form.TipoDeIdFiscalConyuge = form.TipoDeIdFiscalConyuge ? form.TipoDeIdFiscalConyuge : datosP?.TipoDeIdFiscalConyuge;
					form.NumeroDeIdFiscalConyuge = form.NumeroDeIdFiscalConyuge ? form.NumeroDeIdFiscalConyuge : datosP?.NumeroDeIdFiscalConyuge;
					form.Contacto = form.Contacto ? form.Contacto : datosP?.Contacto;
					form.email_cotitular = form.email_cotitular ? form.email_cotitular : datosP?.email_cotitular;
					

						if(subsanar?.length > 0){ 
							// Si es subsanacion se hace la peticion correspondiente al back
							await apiRoutes.subsanarDatosPersonales(form).then(
								function (value) {
									// Success!
									dispatch(saveDatosPersonales(form));
									if (
										!etapaCompletaAnteriormente(
											"Datos Personales",
											etapasCompletadas
										)
									) {
										dispatch(
											getEtapasCompletas("Datos Personales")
										);
									}
									
									// Dependiendo de las etapas que tenga que subsanar se redirecciona
									// a diferentes screens
									if(subsanar?.length > 0){ 
										rutasSubsanar?.includes("adjuntos") ? navigate("/adjuntos/") :
										rutasSubsanar?.includes("ddjj") ? navigate("/declaraciones/") :
										navigate("/usuario-validado/");
									} else {
										navigate("/adjuntos/");
									}
									
								},
								function (error) {
									console.log(error.response);
								}
	
							);
						} else {
							// Si es solo una actualizacion producto de ir hacía atrás con el stepper 
							// se hace la peticion correspondiente al back
							await apiRoutes.updateDatosPersonales(form).then(
								function (value) {
									// Success!
									dispatch(saveDatosPersonales(form));
									if (
										!etapaCompletaAnteriormente(
											"Datos Personales",
											etapasCompletadas
										)
									) {
										dispatch(
											getEtapasCompletas("Datos Personales")
										);
									}

									navigate("/adjuntos/");
									
								},
								function (error) {
									console.log(error.response);
								}
	
							);
						}
						
					} else {
						// Si es la primera vez que se envían los datos se hace la peticion correspondiente al back
						form.Provincia_estado = form.Provincia_estado.toUpperCase();
						await apiRoutes.saveDatosPersonales(form).then(
							function (value) {
								// Success!
								dispatch(saveDatosPersonales(form));
								if (
									!etapaCompletaAnteriormente(
										"Datos Personales",
										etapasCompletadas
									)
								) {
									dispatch(
										getEtapasCompletas("Datos Personales")
									);
								}

								navigate("/adjuntos/");
							},
							function (error) {
								console.log(error.response);
							}
						);
					}
				} catch (error) {
					console.log(error);
				}
			}
	};

	return {
		form,
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
	};
};
