import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cambiarDarkMode } from "../../redux/actions";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import IconButton from "@mui/material/IconButton";
import s from "./SwitchLightDarkMode.module.css";
// .MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root styled hover yellow

const SwitchLightDarkMode = (props) => {
	const dispatch = useDispatch();
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);
	const [darkMode, setDarkMode] = React.useState(estadoInicial);

	function estadoInicial() {
		if (
			darkModeRedux === undefined ||
			darkModeRedux === null ||
			darkModeRedux === false
		) {
			return false;
		} else {
			return true;
		}
	}
	const handleSwitchChange = (e) => {
		e.preventDefault();
		dispatch(cambiarDarkMode(!darkMode));
		setDarkMode(!darkMode);
		if (props.theme === "light") {
			props.setTheme("dark");
		} else {
			props.setTheme("light");
		}
	};
	const renderIcono = () => {
		if (darkMode === false) {
			return (
				<IconButton onClick={handleSwitchChange} className={s.button}>
					<LightModeOutlinedIcon style={{ marginTop: "14px"}} />
				</IconButton>
			);
		} else {
			return (
				<IconButton onClick={handleSwitchChange} className={s.button}>
					<DarkModeOutlinedIcon style={{ marginTop: "14px" }} />
				</IconButton>
			);
		}
	};

	return <>{renderIcono()}</>;
};

export default SwitchLightDarkMode;
