import styled from "styled-components";

export const ContenedorCard = styled.div`
	box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1), inset 4px 4px 14px ${({theme}) => theme.background}, 0.3em 0.3em 1em rgba(0, 0, 0, 0);
	border:  1px solid ${({theme}) => theme.backgroundResultadoTest};
    margin-bottom: 16px;
	display: flex;
	align-items: center;
	height: 100px;
	/* box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1),
		0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0); */
	/* border: 1px solid #d9d9d9; */
    border-radius: 0.4em;
	-webkit-border-radius: 0.4em;
	-moz-border-radius: 0.4em;
	-ms-border-radius: 0.4em;
	-o-border-radius: 0.4em;
`;

export const ContenedorCardAmarillo = styled.div`
    box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1), inset 4px 4px 14px ${({theme}) => theme.background}, 0.3em 0.3em 1em rgba(0, 0, 0, 0);
	border: 1px solid #FFC74F;
    margin-bottom: 16px;
	display: flex;
	align-items: center;
    padding: 2px 3px;
	height: 100px;
	/* box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1),
		0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0); */
	/* border: 1px solid #d9d9d9; */
    border-radius: 0.4em;
	-webkit-border-radius: 0.4em;
	-moz-border-radius: 0.4em;
	-ms-border-radius: 0.4em;
	-o-border-radius: 0.4em;
`;

export const ContenedorCardRojo = styled.div`
    box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1), inset 4px 4px 14px ${({theme}) => theme.background}, 0.3em 0.3em 1em rgba(0, 0, 0, 0);
	border: 1px solid #B00020;
    margin-bottom: 16px;
	display: flex;
	align-items: center;
    padding: 2px 3px;
	height: 100px;
	/* box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1),
		0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0); */
	/* border: 1px solid #d9d9d9; */
    border-radius: 0.4em;
	-webkit-border-radius: 0.4em;
	-moz-border-radius: 0.4em;
	-ms-border-radius: 0.4em;
	-o-border-radius: 0.4em;
`;
export const ContenedorCardGris = styled.div`
    box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.1), inset 4px 4px 14px ${({theme}) => theme.background}, 0.3em 0.3em 1em rgba(0, 0, 0, 0);
	border: 1px solid #CECECE;
    margin-bottom: 16px;
	display: flex;
	align-items: center;
    padding: 2px 3px;
	height: 100px;
    border-radius: 0.4em;
	-webkit-border-radius: 0.4em;
	-moz-border-radius: 0.4em;
	-ms-border-radius: 0.4em;
	-o-border-radius: 0.4em;
`;