import styled from 'styled-components'

export const TituloItem = styled.h5`
    margin: 1.5em 0 0.2em 0;
    font-size: 1em;
    font-weight: bold;
    color: ${({ theme }) => theme.fontEspecialColor};
`;

export const Box = styled.div`
    background-color: ${({theme}) => theme.backgroundResultadoTest};
    text-align: center;
    padding: 0.1em 1em 1em 1em;
    margin: 0 0.5em;
`;

export const Linea = styled.div`
    border-top: 1px solid ${({theme}) => theme.fontColor};
    max-width: 15em;
    height: 2px;
    padding: 0;
    margin: 1em auto 1em auto;
`