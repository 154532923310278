import Box from "@mui/material/Box";
import s from "./FormValidacionSms.module.css";
import React from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { useForm } from "../../hooks/useForm";
import apiRoutes from "../../api/apiRoutes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNuevoNumero } from "../../redux/actions";
import { ErrorText } from "../../views/DDJJ/DDJJElements";
import { InputTotalWidthSms } from "../../views/DatosPersonales/datosElements";

import { useSelector } from "react-redux";

//constantes de estilos para el componente de verificacion de codigo
const StyledReactInputVerificationCode = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;

	--ReactInputVerificationCode-itemWidth: 50px;
	--ReactInputVerificationCode-itemHeight: 48px;
	--ReactInputVerificationCode-itemSpacing: 8px;

	.ReactInputVerificationCode__item {
		font-size: 20px;
		font-weight: bold;
		color: ${({ theme }) => theme.fontColor};

		border: 1px solid
			${({ isInvalid }) =>
				isInvalid ? "#EF6C65" : ({ theme }) => theme.fontColor};
		border-radius: 4px;
		box-shadow: none;
	}

	.ReactInputVerificationCode__item.is-active {
		box-shadow: none;
		border: 1px solid #36c6d9;
	}
`;

const FormValidacionSms = () => {
	//variables de estado del codigo. cuando no tiene "·" es porque esta lleno
	const [codigoCompleto, setCodigoCompleto] = React.useState(false);
	const [tiempoCompleto, setTiempoCompleto] = React.useState(false);

	const [tiempo, setTiempo] = useState("02:00");

	const dispatch = useDispatch();
	const user = useSelector((state) => state.userDataReducer.usuario);
	//variable del codigo ingresado por el usuario
	const [value, setValue] = useState("");
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});

	useEffect(() => {
		let timeOut;
		if (!tiempoCompleto) {
			timeOut = setTimeout(() => {
				subtractOneSecond();
			}, 1000);
		}
		return () => {
			clearTimeout(timeOut);
		};
	}, [tiempo]);

	function subtractOneSecond() {
		//separar string en dos partes con el :
		const [minutos, segundos] = tiempo.split(":");
		//convertir string a numero
		let minutosNumber = parseInt(minutos);
		let segundosNumber = parseInt(segundos);
		//restar un segundo
		if (segundosNumber > 0) {
			segundosNumber--;
		} else if (segundosNumber === 0 && minutosNumber === 0) {
			setTiempoCompleto(true);
		} else if (segundosNumber === 0) {
			segundosNumber = 59;
			minutosNumber--;
		}
		//convertir numero a string
		if (segundosNumber < 10) {
			segundosNumber = "0" + segundosNumber;
		}
		if (minutosNumber < 10) {
			minutosNumber = "0" + minutosNumber;
		}
		const newSegundos = segundosNumber.toString();
		const newMinutos = minutosNumber.toString();
		//unir minutos y segundos con : entre medio
		const newTiempo = `${newMinutos}:${newSegundos}`;
		//setear el tiempo
		setTiempo(newTiempo);
	}

	useEffect(() => {
		if (value.includes("·")) {
			setCodigoCompleto(false);
		} else {
			setCodigoCompleto(true);
		}
	}, [value]);

	//valida el codigo sms con la api
	const handleVerificarCodigo = async (e) => {
		e.preventDefault();
		try {
			const response = await apiRoutes.validarCodigoSms(value);
			if (response.status === 200) {
				navigate("/usuario-validado");
			}
		} catch (error) {
			if (
				error.response.data.msj === "El codigo ingresado es incorrecto"
			) {
				setErrors({
					invalido: "Código Incorrecto",
				});
			}
		}
	};

	//funcion para reenviar el codigo sms
	const handleReenviarSms = (e) => {
		e.preventDefault();
		try {
			dispatch(setNuevoNumero(user.phone));
			document.location.reload(false);
		} catch (error) {
			console.log(error);
		}
	};
	//state para mostrar u ocultar el input de cambio de numero
	const [cambiarNumero, setCambiarNumero] = useState(false);
	const handleCambiarNumero = () => {
		if (cambiarNumero) {
			setCambiarNumero(false);
		} else {
			setCambiarNumero(true);
		}
	};
	//form para nuevo numero de telefono
	const [formValues, handleInputChange] = useForm({
		nuevoTelefono: "",
	});

	//cambio de numero de telefono
	const handleFormCambioDeNumero = (e) => {
		e.preventDefault();
		if (tiempoCompleto) {
			try {
				apiRoutes
					.cambiarNumero(formValues.nuevoTelefono)
					.then((res) => {
						dispatch(setNuevoNumero(formValues.nuevoTelefono));
						document.location.reload(false);
					});
			} catch (error) {
				console.log(error);
			}
		} else {
			setErrors({
				tiempoCumplido: "Todavia no se completó el tiempo",
			});
		}
	};

	const { nuevoTelefono } = formValues;

	//a partir de aca renderizados de botones
	const renderBotonReenviar = () => {
		if (tiempoCompleto) {
			return (
				<Button
					variant="contained"
					color="primary"
					size="small"
					className={s.botonReenviar}
					onClick={handleReenviarSms}
				>
					No me llegó el SMS <br /> Reenviar
				</Button>
			);
		} else {
			return (
				<Button
					variant="contained"
					disabled
					size="small"
					className={s.botonReenviar}
				>
					No me llegó el SMS <br /> {tiempo}
				</Button>
			);
		}
	};
	const renderBotonContinuar = () => {
		if (codigoCompleto) {
			return (
				<Button
					variant="contained"
					color="primary"
					size="large"
					fullWidth
					onClick={handleVerificarCodigo}
				>
					Continuar
				</Button>
			);
		} else {
			return (
				<Button
					variant="contained"
					size="large"
					className={s.botonContinuar}
					fullWidth
					disabled
				>
					Continuar
				</Button>
			);
		}
	};
	const renderCambiarNumero = () => {
		if (cambiarNumero) {
			return (
				<form onSubmit={handleFormCambioDeNumero}>
					<Box sx={{ textAlign: "left", mb: 1 }}>
						<span className={s.label}> Teléfono </span>
					</Box>
					<InputTotalWidthSms
						name="nuevoTelefono"
						value={nuevoTelefono}
						onChange={handleInputChange}
						placeholder="El celular debe ir con código de área (ej. 11) sin 0 ni 15"
						style={{ widht: "100%" }}
					/>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mb: 2,
						}}
					>
						<Button
							type="submit"
							className="boton-continuar"
							size="large"
							color="primary"
							variant="contained"
							fullWidth
						>
							REENVIAR CÓDIGO
						</Button>
					</Box>
				</form>
			);
		}
	};

	return (
		<div className={s.contenedorFormulario}>
			<span className={s.subtitulo}>Código de SMS</span>
			<StyledReactInputVerificationCode>
				<ReactInputVerificationCode
					length={6}
					onChange={(newValue) => {
						setValue(newValue);
					}}
				/>
			</StyledReactInputVerificationCode>
			{errors.invalido && (
				<ErrorText style={{ padding: 0, marginTop: 8 }}>
					{errors.invalido}
				</ErrorText>
			)}
			<div className={s.botones}>
				{renderBotonReenviar()}
				{renderBotonContinuar()}
			</div>
			<div className={s.texto} onClick={handleCambiarNumero}>
				<span>
					¿Necesitás cambiar tu número de teléfono?
					<b> Modificalo acá</b>
				</span>
			</div>
			<Box sx={{ mt: 4 }}>{renderCambiarNumero()}</Box>
			{errors.tiempoCumplido && (
				<ErrorText style={{ padding: 0 }}>
					{errors.tiempoCumplido}
				</ErrorText>
			)}
		</div>
	);
};

export default FormValidacionSms;
