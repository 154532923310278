/**
 * Función validadora declaraciones
 * @param {Objetc} form Formulario
 * @param {Objetc} datosP En caso de ya haber completado el formulario anteriormente
 * @param {Array<String>} subsanar Etapas a subsanar
 * @returns {Object} Errores en caso de haberlos 
 */

export const validacionesDeclaraciones = (form, datosP, subsanar) =>{
    let errors = {};

    // Errores de subsanacion:

    if(!form.VinculoClienteAgente && subsanar?.includes("Vínculo entre el cliente y el agente")){
        errors.VinculoClienteAgente = "Debe seleccionar una de las opciones: "
    }
    if(!form.InvertirExterior && subsanar?.includes("Invertir en el exterior")){
        errors.InvertirExterior = "Debe seleccionar una de las opciones: "
    }
    if(!form.OrigenLicitudFondos && subsanar?.includes("Origen y licitud de fondos")){
        errors.OrigenLicitudFondos = "Debe seleccionar una de las opciones: "
    }
    if(!form.CondicionSujetoObligado && subsanar?.includes("Condición de sujeto obligado")){
        errors.CondicionSujetoObligado = "Debe seleccionar una de las opciones: "
    }
    if(!form.ExpuestaPoliticamente && subsanar?.includes("Condición de persona expuesta políticamente (PEP)")){
        errors.ExpuestaPoliticamente = "Debe seleccionar una de las opciones: "
    }
    if(!form.ResidenciaFiscal && subsanar?.includes("Residencia fiscal (OCDE)")){
        errors.ResidenciaFiscal = "Debe seleccionar una de las opciones: "
    }
    if(!form.InversorCalificado && subsanar?.includes("Inversor calificado")){
        errors.InversorCalificado = "Debe seleccionar una de las opciones: "
    }
    if(!form.FATCA && subsanar?.includes("Foreign account tax compliance act (FATCA)")){
        errors.FATCA = "Debe seleccionar una de las opciones: "
    }

    // Otros errores: 

    if(!form.VinculoClienteAgente && !(datosP?.VinculoClienteAgente)){
        errors.VinculoClienteAgente = "Debe seleccionar una de las opciones: "
    }
    if(!form.InvertirExterior && !(datosP?.InvertirExterior)){
        errors.InvertirExterior = "Debe seleccionar una de las opciones: "
    } else if(form.InvertirExterior === "Si" || datosP?.InvertirExterior === "Si"){
        if(!form.ClienteAutoriza  && !(datosP?.ClienteAutoriza)) {
            errors.ClienteAutoriza = "El siguiente campo debe estar seleccionado: "
        }
        if(!form.ClienteDeclara  && !(datosP?.ClienteDeclara)) {
            errors.ClienteDeclara = "El siguiente campo debe estar seleccionado: "
        }
    }
    if(!form.OrigenLicitudFondos && !(datosP?.OrigenLicitudFondos)){
        errors.OrigenLicitudFondos = "Debe seleccionar una de las opciones: "
    } else if(form.OrigenLicitudFondos === "Otros" || datosP?.OrigenLicitudFondos === "Otros"){
        if(!form.OtrosDescripcion && !(datosP?.OtrosDescripcion)){
            errors.OtrosDescripcion = "Campo requerido: "
        }
    }
    if(!form.CondicionSujetoObligado && !(datosP?.CondicionSujetoObligado)){
        errors.CondicionSujetoObligado = "Debe seleccionar una de las opciones: "
    }
    if(!form.ExpuestaPoliticamente && !(datosP?.ExpuestaPoliticamente)){
        errors.ExpuestaPoliticamente = "Debe seleccionar una de las opciones: "
    }
    if(!form.ResidenciaFiscal && !(datosP?.ResidenciaFiscal)){
        errors.ResidenciaFiscal = "Debe seleccionar una de las opciones: "
    } else if (form.ResidenciaFiscal === "Si") {
        if (!form.PaisRecidenciaFiscal1 && !(datosP?.PaisRecidenciaFiscal1)) {
            errors.PaisRecidenciaFiscal1 = "Campo requerido: " 
        }
        if((!form.NumeroIdFiscal1 && !datosP?.NumeroIdFiscal1)  && (!form.NumeroIdFiscal1Check && !datosP?.NumeroIdFiscal1Check) ){
            errors.NumeroIdFiscal1 = "Campo requerido: "
        }
        if(form.NumeroIdFiscal1Check || datosP?.NumeroIdFiscal1Check){
            if(!form.SinIdFiscalMotivo1 && !(datosP?.SinIdFiscalMotivo1)){
                errors.SinIdFiscalMotivo1 = "Debe seleccionar una de las opciones: "
            } else if (form.SinIdFiscalMotivo1 === "OtroMotivo" || datosP?.SinIdFiscalMotivo1 === "OtroMotivo") {
                if (!form.SinIdFiscalMotivo1Descripcion && !(datosP.SinIdFiscalMotivo1Descripcion)) {
                    errors.SinIdFiscalMotivo1Descripcion = "Campo requerido: ";
                }
            }
        }
        if (form.SinIdFiscalMotivo2 === "OtroMotivo" || datosP?.SinIdFiscalMotivo2 === "OtroMotivo") {
            if (!form.SinIdFiscalMotivo2Descripcion && !(datosP?.SinIdFiscalMotivo2Descripcion)) {
                errors.SinIdFiscalMotivo2Descripcion = "Campo requerido";
            }
        }
    }
    
    if(!form.InversorCalificado && !(datosP?.InversorCalificado)){
        errors.InversorCalificado = "Debe seleccionar una de las opciones: "
    }
    if(!form.FATCA && !(datosP?.FATCA)){
        errors.FATCA = "Debe seleccionar una de las opciones: "
    } else if (form.FATCA === "Si" || datosP?.FATCA === "Si") {
        if(!form.FATCAClienteAutoriza && !(datosP?.FATCAClienteAutoriza)){
            errors.FATCAClienteAutoriza = "El siguiente campo debe estar seleccionado: "
        }
        if(!form.FATCAClienteInformado && !(datosP?.FATCAClienteInformado)){
            errors.FATCAClienteInformado = "El siguiente campo debe estar seleccionado: "
        }
        if(!form.SSN && !(datosP?.SSN)){
            errors.SSN = "Campo requerido: "
        }
        if(!form.ITIN && !(datosP?.ITIN)){
            errors.ITIN = "Campo requerido: "
        }
        if(!form.EIN && !(datosP?.EIN)){
            errors.EIN = "Campo requerido: "
        }
    }

    return errors;
}

