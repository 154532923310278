import React, { useState } from "react";
import s from "./Cotitulares.module.css";
import {
	OptionContainer,
	Input as InputCotitulares,
} from "../../views/DatosPersonales/datosElements";
import { Icon } from "@iconify/react";
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect } from "react";

export const CotitularesForm = (props) => {
	// const [emails, setEmails] = useState([]);
	const [nameValue, setNameValue] = useState("");
	const [lastNameValue, setLastName] = useState("");
	const [dniValue, setDniValue] = useState("");
	const [emailValue, setEmailValue] = useState("");
	// const { isMobile } = useWindowDimensions();
	const [error, setError] = useState("");
	let { value: email_cotitular, onChange, defaultValue, mailTitular } = props;

	useEffect(() => {
		if (defaultValue) {
			const e = {
				target: { name: "email_cotitular", value: defaultValue },
			};
			onChange(e);
		}
	}, [defaultValue]);

	const agregarCotitular = () => {
		if (emailValido() && !existeEmail() && emailValue.length <= 100) {
			let actuales = [...email_cotitular];
			actuales.push({
				email: emailValue,
				nombre: nameValue,
				apellido: lastNameValue,
				dni: dniValue,
			});
			const e = { target: { name: "email_cotitular", value: actuales } };
			onChange(e);
			resetearCampos();
		}
	};

	function resetearCampos() {
		setEmailValue("");
		setError("");
		setNameValue("");
		setLastName("");
		setDniValue("");
	}

	function emailValido() {
		// if (mailTitular === emailValue) {
		// 	setError(
		// 		"No puede poner el mismo email que al titular de la cuenta"
		// 	);
		// 	return false;
		// }
		const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const resp = regex.test(emailValue);
		if (!resp) {
			setError("El email no es válido");
		}
		return resp;
	}
	function existeEmail() {
		const exist =
			email_cotitular.find((e) => e.email === emailValue) === undefined
				? false
				: true;
		if (exist) {
			setError(
				"Este email ya está dentro de la lista de emails de cotitulares"
			);
		}
		return exist;
	}
	const handleChangeState = (v, variable) => {
		v.preventDefault();
		let { value } = v.target;
		switch (variable) {
			case "name":
				setNameValue(value);
				break;
			case "lastName":
				setLastName(value);
				break;
			case "dni":
				setDniValue(value);
				break;
			case "email":
				setEmailValue(value);
				break;
			default:
				setEmailValue(value);
				break;
		}
	};
	const deleteEmail = (email) => {
		let actuales = [...email_cotitular];
		let idx = actuales.findIndex((i) => i.email === email);
		actuales.splice(idx, 1);
		const e = { target: { name: "email_cotitular", value: actuales } };
		onChange(e);
	};

	return (
		<div>
			<h4 className={s.subtitulo}>COTITULARES</h4>
			<h4 className={s.sub}>
				Completá los datos personales de los cotitulares para que puedan
				iniciar el proceso de apertura de cuenta en el enlace que les
				llegará por correo electrónico
			</h4>
			<div className={s.seccion}>
				<OptionContainer>
					<label className={s.labelForm}>Nombre del cotitular</label>
					<InputCotitulares
						type="text"
						name="NameCotitulares"
						value={nameValue}
						// onBlur={handleBlur}
						onChange={(e) => handleChangeState(e, "name")}
						// defaultValue={defaultValue}
						// className={errors.domicilioCalle && "errorCampo"}
					/>
				</OptionContainer>

				<OptionContainer>
					<label className={s.labelForm}>
						Apellido del cotitular
					</label>
					<InputCotitulares
						type="text"
						name="LastNameCotitulares"
						value={lastNameValue}
						// onBlur={handleBlur}
						onChange={(e) => handleChangeState(e, "lastName")}
						// defaultValue={defaultValue}
						// className={errors.domicilioCalle && "errorCampo"}
					/>
				</OptionContainer>

				<OptionContainer>
					<label className={s.labelForm}>
						Documento de identidad del cotitular
					</label>
					<InputCotitulares
						type="text"
						name="DniCotitulares"
						value={dniValue}
						// onBlur={handleBlur}
						onChange={(e) => handleChangeState(e, "dni")}
						// defaultValue={defaultValue}
						// className={errors.domicilioCalle && "errorCampo"}
					/>
				</OptionContainer>

				<OptionContainer>
					<label className={s.labelForm}>Email del cotitular</label>
					<InputCotitulares
						type="text"
						name="EmailCotitulares"
						value={emailValue}
						// onBlur={handleBlur}
						onChange={(e) => handleChangeState(e, "email")}
						// defaultValue={defaultValue}
						// className={errors.domicilioCalle && "errorCampo"}
					/>
				</OptionContainer>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
					marginRight: "24px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={() => agregarCotitular()}
				>
					<Icon icon="uil:plus" fontSize={"1.8em"} />
					<p className={s.contactoTitle}>AGREGAR COTITULAR</p>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					marginTop: "4px",
					marginLeft: "24px",
				}}
			>
				<p
					style={{
						fontSize: "0.9em",
						marginBottom: "16px",
						fontWeight: "bold",
					}}
				>
					Cotitulares agregados
				</p>
				{email_cotitular.map((v, index) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={() => deleteEmail(v)}
						key={index}
					>
						<span style={{ color: "#949494", fontSize: "0.9em" }}>
							{v.nombre +
								" " +
								v.apellido +
								" " +
								v.dni +
								" " +
								v.email}
						</span>
						<Icon icon="charm:cross" fontSize={"1.5em"} />
					</div>
				))}
				{email_cotitular.length === 0 ? (
					<span style={{ color: "#949494", fontSize: "0.9em" }}>
						-
					</span>
				) : null}
				<p className={s.error}>{error}</p>
			</div>
		</div>
	);
};
