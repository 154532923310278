import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setReanudar,
	restartStore,
	getCotitularData,
	setCotitularNuevosData,
	setDni,
} from "../redux/actions";
import { useNavigate } from "react-router-dom";
import apiRoutes from "../api/apiRoutes";

/**
 * Hook que manipula los formularios para que los cotitulares se incorporen a una cuenta
 * @param {Objetc} initialForm Formulario inicial
 * @param {Object} validateForm Función validadora que devuelque los errores
 * @returns {Objetc}
 */

export const useFormIntegracion = (
	initialForm,
	validateForm,
	mailTitular,
	idTitular
) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [form, setForm] = useState(initialForm);

	const [errors, setErrors] = useState({});
	const { datosPersonalesPrevios } = useSelector(
		(state) => state.datosEtapasReducer
	);

	useEffect(() => {
		// Ir comprobando los errores mientras el usuario modifica los campos,
		// una vez que ya haya errores, después del primer intento de submit
		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form));
		}
	}, [form]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});

		if (Object.keys(errors).length > 0) {
			setErrors(validateForm(form));
		}
	};

	const setParams = (name, last_name) => {
		setForm({
			...form,
			name,
			last_name,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let errorsVariableEstatica = validateForm(
			form,
			mailTitular ? true : false
		);
		setErrors(validateForm(form, mailTitular ? true : false));

		// Se comprueba que no haya errores
		if (
			Object.keys(errors).length === 0 &&
			Object.keys(errorsVariableEstatica).length === 0
		) {
			try {
				if (!mailTitular || !idTitular) {
					const res = await apiRoutes.duplicateEmail(
                        form.email,
                        form.numCuenta
                    );

                    if (res?.data?.msg === "email valido") {
                        dispatch(restartStore());
                        try {
                            const res = await apiRoutes.signupCotitulares({
                                name: form.name,
                                last_name: form.last_name,
                                email: form.email,
                                phone: Number(form.phone),
                                numCuenta: Number(form.numCuenta),
                            });
                            if (res.status === 200) {
                                localStorage.setItem("token", res.data.token);
                                let code = res.data.code;
                                dispatch(getCotitularData(form, code));

                                dispatch(setReanudar(false));

                                //navega a la pagina de confirmacion?
                                navigate("/confirmacion");
                            }
                        } catch (error) {
							setErrors({
								request: "Ocurrió un error",
							});
                            console.log(error);
                        }
                    } else {
                        setErrors({
                            email: "Ya existe un titular con este email",
                        });
                    }
				} else {
					const res = await apiRoutes.signUpCotitularPluripersonal(
						form.name,
						form.last_name,
						mailTitular,
						form.phone,
						idTitular
					);
					localStorage.setItem("token", res.data.token);
					let dni = "";
					if (datosPersonalesPrevios.NumeroDNI) {
						dni = datosPersonalesPrevios.NumeroDNI;
					}
					dispatch(restartStore());
					let obj = {
						name: form.name,
						last_name: form.last_name,
						email: mailTitular,
						phone: form.phone,
					};
					dispatch(setCotitularNuevosData(obj));
					dispatch(setReanudar(false));
					dispatch(setDni(dni));
					//navega a la pagina de confirmacion?
					navigate("/datos-personales");
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	return {
		form,
		errors,
		handleChange,
		handleSubmit,
		setParams,
	};
};
