// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitchLightDarkMode_button__ZeL-l:hover {\n\tcolor: #f6b40e;\n\tbackground-color: transparent !important;\n}\n.SwitchLightDarkMode_button__ZeL-l {\n\tbox-shadow: none !important;\n\tpadding-right: 24px !important;\n\tmargin-bottom: 1em !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppBar/SwitchLightDarkMode.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,wCAAwC;AACzC;AACA;CACC,2BAA2B;CAC3B,8BAA8B;CAC9B,6BAA6B;AAC9B","sourcesContent":[".button:hover {\n\tcolor: #f6b40e;\n\tbackground-color: transparent !important;\n}\n.button {\n\tbox-shadow: none !important;\n\tpadding-right: 24px !important;\n\tmargin-bottom: 1em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "SwitchLightDarkMode_button__ZeL-l"
};
export default ___CSS_LOADER_EXPORT___;
