import { validarCuit } from "./validarCuit";

/**
 * Función validadora de datos personales
 * @param {Objetc} form Formulario
 * @param {Objetc} datosP En caso de ya haber completado el formulario anteriormente
 * @param {Array<String>} subsanar Etapas a subsanar
 * @param {Objetc} datosDNI Los datos personales comprobados por NOSIS según el dni
 * @returns
 */

export const validationsForm = (form, datosP, subsanar, tipoCuenta, datosDNI) => {
	let errors = {};

	// Recibe la fecha de nacimiento del cliente y devuelve en la edad en años
	const getEdad = (fecha) => {
		let fechaDeNacimiento = fecha ? fecha : null;
		//formato input Date 2022-03-04
		let añoNacimiento = parseInt(String(fechaDeNacimiento).substring(0, 4));
		let mesNacimiento = parseInt(String(fechaDeNacimiento).substring(5, 7));
		let diaNacimiento = parseInt(
			String(fechaDeNacimiento).substring(8, 10)
		);

		let fechaActual = new Date();
		let añoActual = parseInt(fechaActual.getFullYear());
		let mesActual = parseInt(fechaActual.getMonth() + 1);
		let diaActual = parseInt(fechaActual.getDate());

		let edad = añoActual - añoNacimiento;
		if (mesActual < mesNacimiento) {
			edad--;
		} else if (mesActual === mesNacimiento) {
			if (diaActual < diaNacimiento) {
				edad--;
			}
		}

		return edad;
	};

	let reDNI = /^([0-9]){6,8}$/;

	let reNum = /^([0-9]){1,}$/;

	// Errores de subsanacion solo en los campos que se pueden subsanar

	if (!form.nombre && subsanar?.includes("Nombre")) {
		errors.nombre = "El campo 'Nombre' es requerido";
	}
	if (!form.apellido && subsanar?.includes("Apellido")) {
		errors.apellido = "El campo 'Apellido' es requerido";
	}
	if (!form.Genero && !datosP?.Genero) {
		errors.genero = "El campo 'Sexo' es requerido";
	}
	if (!form.Nacionalidad && !datosP?.Nacionalidad) {
		errors.nacionalidad = "El campo 'Nacionalidad' es requerido";
	}
	if (!form.Nacionalidad && subsanar?.includes("Nacionalidad")) {
		errors.nacionalidad = "El campo 'Nacionalidad' es requerido";
	}
	if (!form.Calle && subsanar?.includes("Domicilio")) {
		errors.domicilioCalle = "El campo 'Calle' es requerido";
	}
	if (!form.Numero && subsanar?.includes("Domicilio")) {
		errors.domicilioNumero = "El campo 'Número' es requerido";
	}
	if (!form.Localidad && subsanar?.includes("Domicilio")) {
		errors.domicilioLocalidad = "El campo 'Localidad' es requerido";
	}
	if (!form.Provincia_estado && subsanar?.includes("Domicilio")) {
		errors.domicilioProvinciaEstado =
			"El campo 'Provincia/Estado' es requerido";
	}
	if (!form.CodigoPostal && subsanar?.includes("Domicilio")) {
		errors.domicilioCD = "El campo 'Código postal' es requerido";
	}
	if (!form.Pais && subsanar?.includes("Domicilio")) {
		errors.domicilioPais = "El campo 'País' es requerido";
	}
	if (!form.FechaNacimiento && !datosP?.FechaNacimiento) {
		errors.fechaDeNacimiento =
			"El campo 'Fecha de nacimiento' es requerido";
	} else if (getEdad(form.FechaNacimiento) < 18) {
		errors.fechaDeNacimiento = "Debe ser mayor de 18 años";
	}
	if (!form.FechaNacimiento && subsanar?.includes("Fecha de nacimiento")) {
		errors.fechaDeNacimiento =
			"El campo 'Fecha de nacimiento' es requerido";
	} else if (getEdad(form.FechaNacimiento) < 18) {
		errors.fechaDeNacimiento = "Debe ser mayor de 18 años";
	}
	if (!form.LugarNacimiento && !datosP?.LugarNacimiento) {
		errors.lugarDeNacimiento =
			"El campo 'Lugar de nacimiento' es requerido";
	}
	if (
		!form.LugarNacimiento &&
		subsanar?.includes("Lugar de nacimiento (como figura en el DNI)")
	) {
		errors.lugarDeNacimiento =
			"El campo 'Lugar de nacimiento' es requerido";
	}
	if (!form.TipoDocumento && !datosP?.TipoDocumento) {
		errors.tipoDeDocumento = "El campo 'Tipo de documento' es requerido";
	}
	if (!form.TipoDocumento && subsanar?.includes("Documento")) {
		errors.tipoDeDocumento = "El campo 'Tipo de documento' es requerido";
	}
	if (!form.NumeroDNI && !datosP?.NumeroDNI) {
		errors.numeroDeDocumento =
			"El campo 'Número de documento' es requerido";
	} else if (form.NumeroDNI && form.TipoDocumento === "DNI") {
		if (!reDNI.test(form.NumeroDNI.trim())) {
			errors.numeroDeDocumento =
				"El campo 'Número de documento' debe contener de 6 a 8 dígitos numéricos";
		}
	}
	if (!form.NumeroDNI && subsanar?.includes("Documento")) {
		errors.numeroDeDocumento =
			"El campo 'Número de documento' es requerido";
	}
	if (!form.TipoIdFiscal && !datosP?.TipoIdFiscal) {
		errors.tipoDeIdFiscal =
			"El campo 'Tipo de identificación fiscal' es requerido";
	}
	if (!form.TipoIdFiscal &&  subsanar?.includes("Documento")) {
		errors.tipoDeIdFiscal = "El campo 'Tipo de identificación fiscal' es requerido";
	}
	if (!form.NumeroIdFiscal && !datosP?.NumeroIdFiscal) {
        errors.numeroDeIdFiscal =
            "El campo 'Número de identificación fiscal' es requerido";
    } else if (form.TipoIdFiscal === "CUIT/CUIL") {
        if (!form.NumeroIdFiscal.toString().includes(form.NumeroDNI.trim())) {
            errors.numeroDeIdFiscal =
                "El número de identificación fiscal debe coincidir con el número de documento";
        }
        if (!validarCuit(form.NumeroIdFiscal)) {
            errors.numeroDeIdFiscal =
                "El número de identificación fiscal es erroneo";
        }
    }
    if (!form.NumeroIdFiscal && subsanar?.includes("Documento")) {
        errors.numeroDeIdFiscal =
            "El campo 'Número de identificación fiscal' es requerido";
    }
    if (!form.Calle && !datosP?.Calle) {
        errors.domicilioCalle = "El campo 'Calle' es requerido";
    }
	if (!form.Numero && !datosP?.Numero) {
		errors.domicilioNumero = "El campo 'Número' es requerido";
	}

	if (!form.Localidad && !datosP?.Localidad) {
		errors.domicilioLocalidad = "El campo 'Localidad' es requerido";
	}
	if (!form.Provincia_estado && !datosP?.Provincia_estado) {
		errors.domicilioProvinciaEstado =
			"El campo 'Provincia/Estado' es requerido";
	}
	if (!form.CodigoPostal && !datosP?.CodigoPostal) {
		errors.domicilioCD = "El campo 'Código postal' es requerido";
	}
	if (!form.Pais && !datosP?.Pais) {
		errors.domicilioPais = "El campo 'País' es requerido";
	}
	if (!form.EstadoCivil && !datosP?.EstadoCivil) {
		errors.estadoCivil = "El campo 'Estado Civil' es requerido";
	}
	if (!form.Contacto && !datosP?.Contacto) {
		errors.contacto = "Este campo es requerido";
	}
	if(datosDNI){
		if(datosDNI?.cuit?.trim() !== form.NumeroIdFiscal.trim()){
			errors.numeroDeIdFiscal =
                "El número de identificación fiscal no coincide con el DNI ingresado";
		}
		if(datosDNI?.nombre?.trim().toUpperCase() !== form.nombre.trim().toUpperCase()){
			errors.nombre = "El nombre no coincide con el DNI ingresado";
		}
		if(datosDNI?.apellido?.trim().toUpperCase() !== form.apellido.trim().toUpperCase()){
			errors.apellido = "El apellido no coincide con el DNI ingresado";
		}
	}

	if (form.EstadoCivil === "Casado/a") {
		if (!form.NombreConyuge) {
			errors.nombreConyuge = "El campo 'Nombre' es requerido";
		}
		if (!form.ApellidoConyuge) {
			errors.apellidoConyuge = "El campo 'Apellido' es requerido";
		}
		if (!form.GeneroConyuge) {
			errors.generoConyuge = "El campo 'Sexo' es requerido";
		}
		if (!form.FechaDeNacimientoConyuge) {
			errors.fechaDeNacimientoConyuge =
				"El campo 'Fecha de nacimiento' es requerido";
		} else if (getEdad(form.FechaDeNacimientoConyuge) < 18) {
			errors.fechaDeNacimientoConyuge = "Debe ser mayor de 18 años";
		}
		if (!form.TipoDeDocumentoConyuge) {
			errors.tipoDeDocumentoConyuge =
				"El campo 'Tipo de documento' es requerido";
		}
		if (!form.NumeroDeDocumentoConyuge) {
			errors.numeroDeDocumentoConyuge =
				"El campo 'Número de documento' es requerido";
		} else if (form.TipoDeDocumentoConyuge === "DNI") {
			if (!reDNI.test(form.NumeroDeDocumentoConyuge.trim())) {
				errors.numeroDeDocumentoConyuge =
					"El campo 'Número de documento' debe contener de 6 a 8 dígitos numéricos";
			}
		}
		if (form.NumeroDeDocumentoConyuge.trim() === form.NumeroDNI.trim()) {
			errors.numeroDeDocumentoConyuge =
				"El número de documento del cónyuge no debe ser igual al del titular";
		} 
		if (!form.TipoDeIdFiscalConyuge) {
			errors.tipoDeIdFiscalConyuge =
				"El campo 'Tipo de identificación fiscal' es requerido";
		}
		if (!form.NumeroDeIdFiscalConyuge) {
			errors.numeroDeIdFiscalConyuge =
				"El campo 'Número de identificación fiscal' es requerido";
		} else if (form.TipoDeIdFiscalConyuge === "CUIT/CUIL") {
			if (
				!form.NumeroDeIdFiscalConyuge.toString().includes(
					form.NumeroDeDocumentoConyuge.trim()
				)
			) {
				errors.numeroDeIdFiscalConyuge =
					"El número de identificación fiscal debe coincidir con el número de documento";
			}
			if (!validarCuit(form.NumeroDeIdFiscalConyuge)) {
				errors.numeroDeIdFiscalConyuge =
					"El número de identificación fiscal es erroneo";
			}
		}
		if (form.NumeroDeIdFiscalConyuge.trim() === form.NumeroIdFiscal.trim()) {
			errors.numeroDeIdFiscalConyuge =
				"El número de id fiscal del cónyuge no debe ser igual al del titular";
		} 
	}

	return errors;
};
