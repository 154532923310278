import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import { AppRouter } from "./router/AppRouter";
import AppBar from "./components/AppBar/AppBar";
import CustomizedSteppers from "./components/DesktopStepper/DesktopStepper";
import HorizontalLinearStepper from "./components/MobileStepper/MobileStepper";
import { Themes } from "./components/Theme/Themes";

const GlobalStyle = createGlobalStyle`
	body{
		background-color: ${({ theme }) => theme.background};
		color: ${({ theme }) => theme.fontColor};
	}
	::-webkit-calendar-picker-indicator {
		filter: ${({ theme }) => theme.iconDateColor};
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border:  0.1px solid ${({ theme }) => theme.bordesInput};
		border-radius: 5px;
		-webkit-text-fill-color: ${({ theme }) => theme.fontColor};
		-webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
			theme.colorAutocomplete} inset;
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: 4px 4px 10px 0px ${({ theme }) => theme.inputShadow2} inset;
		background: ${({ theme }) => theme.backgroundInput}!important;
}
`;

const Global = createGlobalStyle`
	.css-120r8wl-MuiPaper-root-MuiAppBar-root {
    background-color: ${({ theme }) => theme.background} !important;
	}
	.css-duwuv1 {
		background-color: ${({ theme }) => theme.background} !important;
	}
	.css-1u6sik0-MuiSwitch-root .MuiSwitch-thumb:before{
		background-color: transparent !important ;
		
	}
	.MuiSwitch-switchBase{
		background-color: transparent !important;
	} 
	.MuiStepLabel-labelContainer span {
		font-size:12px;
		font-weight: bold !important;
		color: ${({ theme }) => theme.labelStepper} !important;

	}
	.MuiAutocomplete-noOptions{
		color: ${({ theme }) => theme.fontColor};
	}
`;
const GlobalFix = createGlobalStyle` 
	.css-konsbs-MuiPaper-root-MuiAccordion-root{
		background-color: ${({ theme }) => theme.background};
		color: ${({ theme }) => theme.fontColor};
		box-shadow: inset -4px -4px 9px ${({ theme }) =>
			theme.inputShadow}, inset 4px 4px 14px ${({ theme }) =>
	theme.inputShadow2};
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border:  0.1px solid ${({ theme }) => theme.bordesInput};
		border-radius: 5px;
		-webkit-text-fill-color: ${({ theme }) => theme.fontColor};
		-webkit-box-shadow: 0 0 0px 1000px ${({ theme }) =>
			theme.colorAutocomplete} inset;
		transition: background-color 5000s ease-in-out 0s;
		// box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colorAutocomplete} inset;
		box-shadow: 4px 4px 10px 0px ${({ theme }) => theme.inputShadow2} inset;
		background: ${({ theme }) => theme.backgroundInput}!important;
}
input:focus {
	outline: unset;
	border: 1.5px solid #004B6F;
}
@media (min-width: 600px) {
.css-1im6ja8-MuiToolbar-root {
    padding-left: 0px;
    padding-right: 0px;
}
}
`;

const GFix = createGlobalStyle`
	.Mui-disabled {
		background-color: ${({ theme }) => theme.disabledButton} !important ;
	color: ${({ theme }) => theme.disabledFont} !important;
	}
	.MuiStepLabel-label {
		color: #949494!important;
		background-color: rgba(0, 0, 0, 0) !important;
	}
	.MuiStepLabel-root{
		background-color: rgba(0, 0, 0, 0) !important;
	}
	.MuiStepper-root{
		background-color: rgba(0, 0, 0, 0) !important;
	}

	.css-db68o {
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.MuiAccordion-root {
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.MuiPaper-root {
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.MuiPaper-elevation{
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.MuiAccordion-root{
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.MuiPaper-elevation0{
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	}
	.DDJJ_accordion__xxZP{
    background-color: ${({ theme }) => theme.background} !important;
    color: ${({ theme }) => theme.fontColor} !important;
	} 


	span a {
		color: ${({ theme }) => theme.politicaFontColor};
		text-decoration: none;
		font-weight: bold;
	} 
		.MuiButtonBase-root {
		background-color: ${({ theme }) => theme.background} ;
		
	}

`;
const AperturaApp = () => {
	const [theme, setTheme] = useState("light");
	const darkModeRedux = useSelector((state) => state.esteticaReducer.darkMode);
	useEffect(() => {
		if (darkModeRedux) {
			setTheme("dark");
		} else {
			setTheme("light");
		}
	}, [darkModeRedux]);

	const pantallaActual = useSelector((state) => state.esteticaReducer.pantallaActual);
	const renderStepperDesktop = () => {
		if (pantallaActual !== -1 && pantallaActual !== undefined) {
			return (
				<Box
					sx={{
						mb: 6,
						mt: 4,
						"@media (max-width:991px)": {
							display: "none",
						},
					}}
				>
					<CustomizedSteppers />
				</Box>
			);
		} else {
			<Box
				sx={{
					mb: 6,
					mt: 4,
					display: "none",
				}}
			>
				<CustomizedSteppers />
			</Box>;
		}
	};
	const renderStepperMobile = () => {
		if (pantallaActual !== -1 && pantallaActual !== undefined) {
			return (
				<Box
					sx={{
						mb: 6,
						mt: 4,
						"@media (min-width:992px)": {
							display: "none",
						},
					}}
				>
					<HorizontalLinearStepper />
				</Box>
			);
		} else {
			<Box
				sx={{
					mb: 6,
					mt: 4,
					display: "none",
				}}
			>
				<HorizontalLinearStepper />
			</Box>;
		}
	};
	//clear local storage when user close the app but no whe the user refresh the page

	// function clearStorage() {
	// 	let session = sessionStorage.getItem("register");

	// 	if (session == null) {
	// 		localStorage.clear();
	// 	}
	// 	sessionStorage.setItem("register", 1);
	// }
	// window.addEventListener("load", clearStorage);
	return (
		<Box sx={{ pb: 6 }}>
			<ThemeProvider theme={Themes[theme]}>
				<GlobalStyle />
				<Global />
				<GlobalFix />
				<GFix />
				<AppBar theme={theme} setTheme={setTheme} />
				{renderStepperDesktop()}
				{renderStepperMobile()}
				<AppRouter />
			</ThemeProvider>
		</Box>
	);
};
export default AperturaApp;
