import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducer/index.js';


const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')): {}

export const store = createStore(rootReducer,persistedState, composeWithDevTools(applyMiddleware(thunk)));

//export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));