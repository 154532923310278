export const GET_USER_DATA = "GET_USER_DATA";
export const GET_OLD_USER_DATA = "GET_OLD_USER_DATA";
export const GET_COTITULAR_DATA = "GET_COTITULAR_DATA";
export const POST_DECLARACIONES_DATA = "POST_DECLARACIONES_DATA";
export const CAMBIAR_NUMERO_TELEFONO = "CAMBIAR_NUMERO_TELEFONO";
export const CAMBIAR_DARK_MODE = "CAMBIAR_DARK_MODE";
export const SET_REANUDAR = "SET_REANUDAR";
export const CAMBIAR_PANTALLA_ACTUAL = "PANTALLA_ACTUAL";
export const GET_DATOS_PERSONALES = "GET_DATOS_PERSONALES";
export const SAVE_DATOS_PERSONALES = "SAVE_DATOS_PERSONALES";
export const POST_IMAGEN_FRENTE = "POST_IMAGEN_FRENTE";
export const POST_IMAGEN_SELFIE = "POST_IMAGEN_SELFIE";
export const POST_IMAGEN_DORSO = "POST_IMAGEN_DORSO";
export const GET_IMAGENES = "GET_IMAGENES";
export const LIMPIAR_FOTO_FRENTE = "LIMPIAR_FOTO_FRENTE";
export const LIMPIAR_FOTO_DORSO = "LIMPIAR_FOTO_DORSO";
export const LIMPIAR_FOTO_SELFIE = "LIMPIAR_FOTO_SELFIE";
export const GET_RESULTADO_TEST = "GET_RESULTADO_TEST";
export const SAVE_RESULTADO_TEST = "SAVE_RESULTADO_TEST";
export const RESTART_STORE = "RESTART_STORE";
export const GET_DDJJ = "GET_DDJJ";
export const SAVE_DDJJ = "SAVE_DDJJ";
export const GET_ETAPAS = "GET_ETAPAS";
export const GET_TERMINOS = "GET_TERMINOS";
export const PUT_NOMBRE = "PUT_NOMBRE";
export const GET_ERRORES = "GET_ERRORES";
export const PUT_TIPOCUENTA = "PUT_TIPOCUENTA";
export const SET_COTITULAR_NUEVOS_DATA = "SET_COTITULAR_NUEVOS_DATA";
export const SET_DNI = "SET_DNI";
