import {
	GET_ERRORES,
} from "../constantes";

const initialState = {
    subsanar: [],
    rutas: undefined,
    rutasSubsanar: undefined,
};

function subsanarReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ERRORES:
			return {
				...state,
				subsanar: action.payload.campos,
				rutas: action.payload.ruta,
				rutasSubsanar: action.payload.rutasSubsanar,
			}
        default:
            return state;
    }
}

export default subsanarReducer;
