import React from "react";
import { validacionesNuevaApertura } from "../../helpers/validacionesNuevaApertura";
import { useFormLogin } from "../../hooks/useFormLogin";
import Button from "@material-ui/core/Button";
import s from "./NuevaAperturaForm.module.css";
import { Input } from "../../views/DatosPersonales/datosElements";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const initialForm = {
	name: "",
	last_name: "",
	phone: "",
	email: "",
};

const NuevaAperturaForm = () => {
	const { height, width } = useWindowDimensions();

	const { form, errors, handleChange, handleSubmit } = useFormLogin(
		initialForm,
		validacionesNuevaApertura
	);

	return (
		<form
			className={s.container}
			onSubmit={handleSubmit}
			autoComplete={"off"}
		>
			<div className={width > 992 ? s.containerEsp : s.containerNoEsp}>
				<div
					className={
						width > 992 ? s.optionContainerEsp : s.optionContainer
					}
				>
					{width < 992 && (
						<label className={s.labelForm}>Nombre</label>
					)}

					<Input
						className={errors.name && 'errorCampo'}
						type="text"
						name="name"
						value={form.name}
						onChange={handleChange}
						placeholder={
							width < 992
								? "Nombre completo como figura en el DNI"
								: "Nombre/s"
						}
					/>

					{width > 992 && (
						<label className={s.textoGris}>
							Nombre completo como figura en el DNI
						</label>
					)}
					{errors.name && <p className={s.error}>{errors.name}</p>}
				</div>
				<div
					className={
						width > 992 ? s.optionContainerEsp : s.optionContainer
					}
				>
					{width < 992 && (
						<label className={s.labelForm}>Apellido</label>
					)}

					<Input
						className={errors.last_name && 'errorCampo'}
						type="text"
						name="last_name"
						value={form.last_name}
						onChange={handleChange}
						placeholder={
							width < 992
								? "Apellido completo como figura en el DNI"
								: "Apellido/s"
						}
					/>

					{width > 992 && (
						<label className={s.textoGris}>
							Apellido completo como figura en el DNI
						</label>
					)}
					{errors.last_name && (
						<p className={s.error}>{errors.last_name}</p>
					)}
				</div>
			</div>
			<div className={s.optionContainer}>
				{width < 992 && <label className={s.labelForm}>Celular</label>}

				<Input
					className={errors.phone && 'errorCampo'}
					type="text"
					name="phone"
					value={form.phone}
					onChange={handleChange}
					placeholder={
						width < 992
							? "Ingresar con código de área (ej. 11) sin 0 ni 15"
							: "Celular"
					}
				/>
				{width > 992 && (
					<label className={s.textoGris}>
						Ingresar con código de área (ej. 11) sin 0 ni 15
					</label>
				)}

				{errors.phone && <p className={s.error}>{errors.phone}</p>}
			</div>
			<div className={s.optionContainer}>
				{width < 992 && <label className={s.labelForm}>Email</label>}

				<Input
					className={errors.email && 'errorCampo'}
					type="text"
					name="email"
					value={form.email}
					onChange={handleChange}
					placeholder={
						width < 992 ? "Ingrese un mail válido" : "Email"
					}
				/>
				{errors.email && <p className={s.error}>{errors.email}</p>}
			</div>
			<div className={s.botonContainer}>
				<Button
					className={s.boton}
					type="submit"
					variant="contained"
					color="primary"
					size="large"
					fullWidth
				>
					Continuar
				</Button>
			</div>
		</form>
	);
};

export default NuevaAperturaForm;
