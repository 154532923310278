import React, { useEffect } from "react";
import {
	cambiarPantallaActual,
	putTipoCuenta,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Icon } from "@iconify/react";
import apiRoutes from "../../api/apiRoutes";
import { useNavigate } from "react-router-dom";

export const TipoDeCuenta = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const darkModeRedux = useSelector(
		(state) => state.esteticaReducer.darkMode
	);

	const primaryFontColor = darkModeRedux ? "white" : "#004b6f";
	const { isMobile } = useWindowDimensions();
	const styles = isMobile()
		? mobileStyles(primaryFontColor, darkModeRedux)
		: desktopStyles(primaryFontColor, darkModeRedux);

	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
	}, []);

	async function onClickButton(opcion) {
		const resp = await apiRoutes.setearTipoDeCuenta(opcion);
		if (resp.data.msg === "Registro actualizado") {
			dispatch(putTipoCuenta(opcion));
			navigate("/datos-personales/");
		}
	}

	return (
		<div style={styles.containerStyle}>
			<div style={{ height: "100px" }} />
			<div style={styles.containerStyle}>
				<span style={styles.titleStyle}>
					¿Cuántas personas formarán parte de la cuenta?
				</span>
				<span style={styles.subTitleStyle}>
					*La opción que elijas definirá qué tipo de cuenta vas a
					abrir. Esto no se podrá modificar con posterioridad a la
					apertura de la misma.
				</span>
			</div>
			<div style={styles.buttonContainerStyle}>
				<button
					type="button"
					style={styles.buttonStyle}
					onClick={() => onClickButton("Unipersonal")}
					name="Unipersonal"
				>
					<Icon
						icon="mdi:account-outline"
						fontSize={"2.7em"}
						color={primaryFontColor}
					/>
					<span style={styles.titleStyle}>Una persona</span>
				</button>
				<button
					type="button"
					onClick={() => onClickButton("Pluripersonal")}
					name="Pluripersonal"
					style={styles.buttonStyle}
				>
					<Icon
						icon="mdi:person-group-outline"
						fontSize={"2.7em"}
						color={primaryFontColor}
					/>
					<span style={styles.titleStyle}>Más de una persona</span>
				</button>
			</div>
			<div style={styles.attentionStyle}>
				<span style={styles.attentionTitleStyle}>ATENCIÓN</span>
				<span style={styles.attentionSubtitleStyle}>
					Recordá que si seleccionás la opción “una persona” no se
					podrán incorporar más personas a la cuenta. Del mismo modo,
					si elegís la opción “más de una persona”, la cuenta no podrá
					tener solo un/a titular.
				</span>
			</div>
		</div>
	);
};

const mobileStyles = (primaryFontColor, darkModeRedux) => {
	return {
		containerStyle: {
			padding: "16px",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			margin: "0 auto",
		},

		buttonContainerStyle: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			marginTop: "32px",
		},

		buttonStyle: {
			backgroundColor: darkModeRedux ? "#282B30" : "white",
			boxShadow: darkModeRedux
				? "4px 4px 8px 0px #000000 inset"
				: "4px 4px 8px 0px #ebebed inset",
			border: darkModeRedux ? "1px solid #000000" : "1px solid #cecece",
			borderRadius: "0.4em",
			cursor: "pointer",
			width: "40%",
			padding: "16px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},

		titleStyle: {
			color: primaryFontColor,
			fontWeight: "bold",
			fontSize: "14px",
			textAlign: "center",
		},

		subTitleStyle: {
			color: primaryFontColor,
			marginTop: "12px",
			textAlign: "center",
		},

		attentionStyle: {
			marginTop: "32px",
			backgroundColor: !darkModeRedux ? "#ebebed" : "#3F4145",
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "center",
			paddingTop: "24px",
			paddingBottom: "48px",
			width: "100%",
			paddingRight: "4px",
		},

		attentionTitleStyle: {
			color: primaryFontColor,
			fontWeight: "bold",
			marginLeft: "16px",
			fontSize: "14px",
		},

		attentionSubtitleStyle: {
			color: primaryFontColor,
			marginTop: "8px",
			fontSize: "10px",
			marginLeft: "16px",
		},
	};
};

const desktopStyles = (primaryFontColor, darkModeRedux) => {
	return {
		containerStyle: {
			padding: "16px",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "70%",
			margin: "0 auto",
		},

		buttonContainerStyle: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			marginTop: "64px",
			width: "80%",
		},

		buttonStyle: {
			backgroundColor: darkModeRedux ? "#282B30" : "white",
			boxShadow: darkModeRedux
				? "4px 4px 8px 0px #000000 inset"
				: "4px 4px 8px 0px #ebebed inset",
			border: darkModeRedux ? "1px solid #000000" : "1px solid #cecece",
			borderRadius: "0.4em",
			cursor: "pointer",
			width: "40%",
			padding: "32px",
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			// marginRight: "16px", // Añadido para el primer botón
		},

		titleStyle: {
			color: primaryFontColor,
			fontWeight: "bold",
			fontSize: "1.0rem",
			textAlign: "center",
		},

		subTitleStyle: {
			color: primaryFontColor,
			marginTop: "0.8rem",
			textAlign: "center",

			// fontWeight:"100"
		},

		attentionStyle: {
			marginTop: "32px",
			backgroundColor: !darkModeRedux ? "#ebebed" : "#3F4145",
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "center",
			paddingTop: "24px",
			paddingRight: "4px",
			paddingBottom: "96px",
			width: "80%",
		},

		attentionTitleStyle: {
			color: primaryFontColor,
			fontWeight: "bold",
			marginLeft: "32px",
			fontSize: "16px",
		},

		attentionSubtitleStyle: {
			color: primaryFontColor,
			marginTop: "8px",
			fontSize: "12px",
			marginLeft: "32px",
		},
	};
};
