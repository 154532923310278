import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cambiarPantallaActual,saveResultadoTest, getEtapasCompletas } from "../../redux/actions";
import s from './InversorTest.module.css'
import { Box, Linea } from "./inversorElements";
import apiRoutes from "../../api/apiRoutes";

/**
 * Este componente recibe la informacion ingresada en la screen Perfil Inversor
 * Y dependiendo de los valores renderiza un resultado 
 * @param {String} HaInvertido 
 * @param {String} GradoConocimiento 
 * @param {String} ActitudInversiones 
 * @param {String} Reserva 
 * @param {String} PlazoEstimado 
 * @param {String} PorcentajeAhorros
 */
export const InversorTest = ({HaInvertido, GradoConocimiento, ActitudInversiones, Reserva, PlazoEstimado, PorcentajeAhorros}) => {

  const dispatch = useDispatch();
  dispatch(cambiarPantallaActual(2));

  const datosP = useSelector((state) => state.datosEtapasReducer.resultadoTestPrevio);

  const esReanudacion = useSelector((state) => state.reanudarReducer.esReanudacion);

  const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

  let cont = 0;

  HaInvertido === "No"? cont =+6 : cont =+ 0;

  switch (GradoConocimiento) {
    case 'menor': cont += 2; break;
    case 'intermedio': cont += 6; break;
    case 'mayor': cont += 10; break;
    default: cont += 0;
  }

  switch (ActitudInversiones) {
    case 'menorRiesgo': cont += 2; break;
    case 'riesgoIntermedio': cont += 4; break;
    case 'mayorRiesgo': cont += 8; break;
    default: cont += 0;
  }

  switch (Reserva) {
    case 'tresMesesDeIngresos': cont += 3; break;
    case 'nula': cont += 6; break;
    default: cont += 0;
  }

  switch (PlazoEstimado) {
    case 'plazoIntermedio': cont += 3; break;
    case 'cortoPlazo': cont += 6; break;
    default: cont +=0;
  }

  switch (PorcentajeAhorros) {
    case '25/60': cont += 3; break;
    case 'masDel60': cont += 6; break;
    default: cont +=0;
  }

  let titulo = "";
  let descripcion = "";

  if (cont > 0 && cont <= 15) {
    titulo = 'CONSERVADOR';
    descripcion = 'Se caracteriza por buscar inversiones que representen la conservación de su capital o un crecimiento moderado, sin asumir riesgos importantes, buscando minimizar la incidencia de las fluctuaciones de mercado';
  } 
  else if (cont > 15 && cont < 30) {
    titulo = 'MODERADO';
    descripcion = 'Se encuentra dispuesto a asumir ciertas oscilaciones en sus inversiones, esperando que en un mediano / largo plazo pueda obtener una mayor rentabilidad. Es un perfil intermedio, que puede tolerar cierto riesgo en sus inversiones a cambio de una mayor rentabilidad'
  } 
  else {
    titulo = 'AGRESIVO';
    descripcion = 'Se caracteriza por inversiones cuyo objetivo principal es maximizar el rendimiento de su cartera, asumiendo para ello un alto componente de riesgo. Está dispuesto a asumir pérdidas de capital';
  }

  useEffect(() => {

    let datos = {
      ExperenciasInversiones: HaInvertido,
      ConocimientoMercadosCapitales: GradoConocimiento,
      ObjetivoInversion: ActitudInversiones,
      SituacionFinanciera: Reserva,
      HorizontePrevisto: PlazoEstimado,
      Ahorro: PorcentajeAhorros,
      ResultadoPerfil: {
        title: titulo,
        description: descripcion,
      }
    }

    if(etapasCompletadas?.includes("Perfil Inversor")){
      apiRoutes.updateTestInversor(datos).then(
        function (value) {
          // Success!
          dispatch(
            saveResultadoTest(datos)
          )
        },
        function (error) {
          console.log(error.response);
        }
      );
    }
    else {
      apiRoutes.testInversor(datos).then(
        function (value) {
          // Success!
          dispatch(
            saveResultadoTest(datos)
          )
          dispatch(getEtapasCompletas("Perfil Inversor"));
        },
        function (error) {
          console.log(error.response);
        }
      )
      
    }
}, [
  dispatch, 
  titulo,
  HaInvertido, GradoConocimiento, ActitudInversiones, Reserva, PlazoEstimado, PorcentajeAhorros
  ]);

  return (
      <div className={s.container}>
          <div>
              <div className={s.lineaGris}></div>
              <h3 className={s.titulo}> RESULTADO DEL PERFIL </h3>
              <Box>
                  <h4 className={s.subtitulo}>{titulo}</h4>
                  <Linea />
                  <p className={s.descripcion}>{descripcion}</p>
              </Box>
              <div className={s.aviso}>
                  <p>
                      El Cliente toma conocimiento del perfil asignado como
                      resultado de la evaluación del presente cuestionario que
                      ha integrado y manifiesta su acuerdo con el mismo. El
                      Cliente se compromete a realizar las actualizaciones
                      necesarias a los efectos de mantener vigente dicha
                      información.
                  </p>
              </div>
          </div>
      </div>
  );
}