import styled from "styled-components"

export const Link = styled.a`
    color: ${({theme}) => theme.linkColor};
	text-decoration: none;
    font-weight: bold;
`

export const ErrorContainer = styled.div`
    background-color: ${({theme}) => theme.errorFondo};
    display: flex;
    text-align: center;
    align-items: center;
    margin: 1em 2.5em;
    padding: 0.8em;
    border-radius: 1em;
    color: ${({theme}) => theme.errorColor};
    ;
`

export const ErrorText = styled.p`
    color: ${({theme}) => theme.errorColor};
    font-size: 0.8em;
    font-weight: bold;
    padding: 0 0 0 2em;
`

export const Error = styled.p`
    color: #E3342F;
    margin: 0.4em 0 0.4em 0;
    font-size: 0.9em;
`