import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@material-ui/core/Button";
import s from "./Terminos.module.css";
import { textos } from "../../texts/textos-condiciones.js";
import apiRoutes from "../../api/apiRoutes";
import {
	cambiarPantallaActual,
	getDDJJ,
	getEtapasCompletas,
} from "../../redux/actions";
import { etapaCompletaAnteriormente } from "../../helpers/helperEtapaCompleta";
import { Contacto } from "../../components/Contacto/Contacto.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const Terminos = () => {
	const datosP = useSelector((state) => state.datosEtapasReducer.terminosAceptados);
	const texts = textos.arrayTextos;
	//handle to checkbox
	const [cargando, setCargando] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
	const handleChange = (event) => {
		setChecked(event.target.checked);
	};
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

	useEffect(() => {
		dispatch(cambiarPantallaActual(4));
		dispatch(getDDJJ());
		if (!etapaCompletaAnteriormente("DDJJ", etapasCompletadas)) {
			dispatch(getEtapasCompletas("DDJJ"));
		}
	}, [dispatch]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	/* 	window.addEventListener("beforeunload", function (e) {
		// Cancel the event
		e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
		// Chrome requires returnValue to be set
		e.returnValue = "";
	}); */

	const onClickContinuar = async () => {
		setCargando(true);
		//api
		try {
			await apiRoutes.aceptarTerminos().then(
				function (value) {
					// Success!
					// console.log(value.data.msj);
				},
				function (reason) {
					// console.log(reason.data);
				}
			);
			await apiRoutes.enviarMensaje().then(
				function (value) {
					// Success!
					setCargando(false);
					navigate("/verificacion-sms");
				},
				function (reason) {
					setCargando(false); // Error!
				}
			);
		} catch (error) {
			console.log(error);
		}
	};
	function getButton() {
		if (!checked && !datosP) {
			return (
				<Button
					type="submit"
					className={s.botonContinuar}
					size="large"
					variant="contained"
					fullWidth
					disabled
					onClick={onClickContinuar}
				>
					Continuar
				</Button>
			);
		} else {
			return (
				<Button
					type="submit"
					className={s.botonContinuar}
					size="large"
					color="primary"
					variant="contained"
					fullWidth
					onClick={onClickContinuar}
				>
					Continuar
				</Button>
			);
		}
	}
	const renderArray = () => {
		return texts.map((text, index) => (
			<div className={s.contenedorTextos} key={index}>
				<Box
					sx={{
						mb: 1,
						mt: 2,
						display: "flex",
						flexDirection: "column",
						textAlign: "left",
					}}
				>
					<span className={s.titulo}>
						{text.titulo.toLocaleUpperCase()}
					</span>
				</Box>

				{text.parrafos.map((parrafo, ind) => (
					<p key={ind} className={s.parrafo}>
						{parrafo}
					</p>
				))}
			</div>
		));
	};
	const spinnerCarga = () => {
		if (!cargando) {
			return (
				<>
				<div className={s.contenedorPrincipal}>
					<h3 className={s.tituloPrincipal}>
						CONDICIONES DE LA APERTURA DE CUENTA EN RAVA BURSÁTIL
					</h3>
					{renderArray()}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							mt: 2,
							mb: 2,
							"@media (min-width:780px)": {
								width: "50%",
							},
						}}
					>
						{datosP ? (
							<Checkbox
								className={s.checkboxIcon}
								{...label}
								onChange={handleChange}
								checked={datosP && datosP}
							/>
						) : (
							<Checkbox
								className={s.checkboxIcon}
								{...label}
								onChange={handleChange}
							/>
						)}
						<span className={s.checkbox}>
							Quien suscribe declara conocer, haber leído y
							aceptar las condiciones para la apertura de cuenta
							en Rava Bursátil
						</span>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mb: 2,
						}}
					>
						{getButton()}
					</Box>
				</div>
				<Contacto />
				</>
			);
		} else {
			return (
				<Box sx={{ display: "flex" }}>
					<CircularProgress style={{ color: "#004B6F" }} />
				</Box>
			);
		}
	};

	return <div className={s.contenedorPrincipal}>{spinnerCarga()}</div>;
};
export default Terminos;
