import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AperturaApp from "./AperturaApp.js"
import { Provider } from 'react-redux';
import { store } from './redux/store/index.js';



store.subscribe(()=>{
	localStorage.setItem('reduxState', JSON.stringify(store.getState()))
  })


ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<AperturaApp/>
    	</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
