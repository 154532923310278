import {
	GET_IMAGENES,
	POST_IMAGEN_FRENTE,
	POST_IMAGEN_SELFIE,
	POST_IMAGEN_DORSO,
	LIMPIAR_FOTO_DORSO,
	LIMPIAR_FOTO_FRENTE,
	LIMPIAR_FOTO_SELFIE,
} from "../constantes";

const initialState = {
    imagenesApi: [],
	imagenDniFrente: {},
	imagenDniDorso: {},
	imagenSelfie: {},
};

function adjuntosReducer(state = initialState, action) {
	switch (action.type) {
        case GET_IMAGENES:
			return {
				...state,
				imagenesApi: action.payload,
			};
		case POST_IMAGEN_DORSO:
			return {
				...state,
				imagenDniDorso: action.payload,
			};
		case POST_IMAGEN_FRENTE:
			return {
				...state,
				imagenDniFrente: action.payload,
			};
		case POST_IMAGEN_SELFIE:
			return {
				...state,
				imagenSelfie: action.payload,
			};
		case LIMPIAR_FOTO_DORSO:
			return {
				...state,
				imagenDniDorso: {},
			};
		case LIMPIAR_FOTO_FRENTE:
			return {
				...state,
				imagenDniFrente: {},
			};
		case LIMPIAR_FOTO_SELFIE:
			return {
				...state,
				imagenSelfie: {},
			};
        default:
            return state;
    }
}

export default adjuntosReducer;
