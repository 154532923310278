import * as React from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import s from "./AppBar.module.css";
import SwitchLightDarkMode from "./SwitchLightDarkMode";

export default function ButtonAppBar(props) {
    const [width, setWidth] = React.useState(window.innerWidth);
    const darkModeRedux = useSelector(
        (state) => state.esteticaReducer.darkMode
    );

    const imagenRender = () => {
        if (!darkModeRedux) {
            return (
                <img
                    src={require("../../images/logo-rava-light.png")}
                    alt="logo"
                    className={s.logo}
                />
            );
        } else {
            return (
                <img
                    src={require("../../images/logo-rava-dark.png")}
                    alt="logo"
                    className={s.logo}
                />
            );
        }
    };

    const appbarRender = () => {
            return (
                <AppBar
                    elevation={0}
                    position="static"
                    style={{
                        backgroundColor: `${({ theme }) => theme.background}`,
                        boxShadow: "none",
                    }}
                >
                    <Toolbar
                        sx={{
                            justifyContent: "space-between",
                            padding: "0px !important",
                        }}
                    >
                        {imagenRender()}

                        <SwitchLightDarkMode
                            theme={props.theme}
                            setTheme={props.setTheme}
                        ></SwitchLightDarkMode>
                    </Toolbar>
                </AppBar>
            );
    };
    return <Box sx={{ height: "5vh" }}>{appbarRender()}</Box>;
}
