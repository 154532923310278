import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getImagenes } from "../redux/actions";
import {
	cambiarPantallaActual,
	setUrlTemporal,
	getDatosPersonales,
	getEtapasCompletas,
} from "../redux/actions";
import apiRoutes from "../api/apiRoutes";
import { etapaCompletaAnteriormente } from "../helpers/helperEtapaCompleta";
import { idsImagenes } from "../helpers/helperUrlTemporal";
import { useState } from "react";

// Un paso a paso general:
// 1-Cargas una imagen
// 2-Va al onChange correspondiente y setea en el state correspondiente
// 3-Un useEffect detecta una nueva imagen cargada y crea una URL temporal en el state correspondiente
// 4-Pones continuar, se activa el spinner de carga, se mandan las imagenes a la API, se obtienen las URL temporales,
// se setean las URL temporales desde el store (data del back del space) y se guardan en los state correspondientes
// para que no venza la url temporal que se genera sola.
// 5- Si reanuda apertura comienza desde el getUrlTemporales en el paso 4.

export const useAdjuntos = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [error, setError] = useState("");
	const [cargando, setCargando] = useState(false);

	//variables para saber si hubo cambios en las distintas imagenes
	const [cambiosDniFrente, setCambiosDniFrente] = useState(false);
	const [cambiosDniDorso, setCambiosDniDorso] = useState(false);
	const [cambiosSelfie, setCambiosSelfie] = useState(false);

	//variable para saber que campo hay que subsar
	const [campoSubsanar, setCampoSubsanar] = useState({
		dni: false,
		selfie: false,
	});

	//variable para saber que campos subsanó
	const [subsanoImagen, setsubsanoImagen] = useState({
		frente: false,
		dorso: false,
		selfie: false,
	});

	const [toDoUrlTemporal, setToDoUrlTemporal] = React.useState(true);

	//files
	const [imagenDniFrente, setImagenDniFrente] = React.useState(null);
	const [imagenDniDorso, setImagenDniDorso] = React.useState(null);
	const [imagenSelfie, setImagenSelfie] = React.useState(null);

	//url temporales
	const [imageUrlFrente, setImageUrlFrente] = React.useState(null);
	const [imageUrlDorso, setImageUrlDorso] = React.useState(null);
	const [imageUrlSelfie, setImageUrlSelfie] = React.useState(null);

	//pedidos al store cuando ya se guardó la imagen
	const storeDniFrente = useSelector(
		(state) => state.adjuntosReducer.imagenDniFrente
	);
	const storeDniDorso = useSelector(
		(state) => state.adjuntosReducer.imagenDniDorso
	);
	const storeDniSelfie = useSelector(
		(state) => state.adjuntosReducer.imagenSelfie
	);
	const storeImagenesApi = useSelector(
		(state) => state.adjuntosReducer.imagenesApi
	);

	const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
	const rutasSubsanar = useSelector(
		(state) => state.subsanarReducer.rutasSubsanar
	);
	const etapasCompletadas = useSelector(
		(state) => state.reanudarReducer.etapasCompletadas
	);

	//Para setear si hay campos para subsanar y cuales
	useEffect(() => {
		if (subsanar.length !== 0) {
			if (
				subsanar.find(
					(element) =>
						element === "Documento de identidad (frente y dorso)"
				) !== undefined
			) {
				setCampoSubsanar({ ...campoSubsanar, dni: true });
			}
			if (
				subsanar.find((element) => element === "Selfie") !== undefined
			) {
				setCampoSubsanar({ ...campoSubsanar, selfie: true });
			}
			setCargando(true);

			callGetImagenes();
		}
	}, []);

	//Seteos de primera renderizacion de componente
	useEffect(() => {
		dispatch(cambiarPantallaActual(1));
		dispatch(getDatosPersonales());
		if (
			!etapaCompletaAnteriormente("Datos Personales", etapasCompletadas)
		) {
			dispatch(getEtapasCompletas("Datos Personales"));
		}
	}, [dispatch]);

	//Para poner en cargando cuando se obtienen las imagenes del store
	useEffect(() => {
		if (etapaCompletaAnteriormente("Adjuntos", etapasCompletadas)) {
			if (
				Object.keys(storeDniDorso).length === 0 ||
				Object.keys(storeDniFrente).length === 0 ||
				Object.keys(storeDniSelfie).length === 0
			) {
				setCargando(true);
			} else {
				setTimeout(() => {
					setCargando(false);
				}, 3000);
			}
		}
	}, [storeDniDorso, storeDniFrente, storeDniSelfie]);

	//3 use effect para que cuando cambie cada una de las imagenes se setee una nueva url temporal en el state correspondiente
	useEffect(() => {
		if (imagenDniFrente === null || imagenDniFrente === undefined) return;
		const newImageUrls = URL.createObjectURL(imagenDniFrente);
		setImageUrlFrente(newImageUrls);
	}, [imagenDniFrente]);

	useEffect(() => {
		if (imagenDniDorso === null || imagenDniDorso === undefined) return;
		const newImageUrls = URL.createObjectURL(imagenDniDorso);
		setImageUrlDorso(newImageUrls);
	}, [imagenDniDorso]);

	useEffect(() => {
		if (imagenSelfie === null || imagenSelfie === undefined) return;
		const newImageUrls = URL.createObjectURL(imagenSelfie);
		setImageUrlSelfie(newImageUrls);
	}, [imagenSelfie]);

	//Se setear la url temporal del space donde ya esta guardada la imagen (se le pide a la API)
	useEffect(() => {
		setUrlTemporalImagenes();
	}, [toDoUrlTemporal]);

	//Se obtienen url temporal de imagenes de la API
	async function callGetImagenes() {
		const res = await apiRoutes.getImagenes();

		res.data.forEach((element) => {
			switch (element.order) {
				case 0:
					setImageUrlFrente(element.url_temporal);
					break;
				case 1:
					setImageUrlDorso(element.url_temporal);
					break;
				case 2:
					setImageUrlSelfie(element.url_temporal);
					break;
			}
		});
		setTimeout(() => {
			setCargando(false);
		}, 3000);
	}

	//Se setear la url temporal del space donde ya esta guardada la imagen (se le pide a la API)
	async function setUrlTemporalImagenes() {
		dispatch(getImagenes());
		if (storeImagenesApi.length > 0 && toDoUrlTemporal) {
			let ids = idsImagenes(storeImagenesApi);
			for await (const img of ids) {
				if (campoSubsanar.dni) {
					if (img.titulo === "DNI-Selfie") {
						dispatch(setUrlTemporal(img.id, img.titulo));
					}
				} else if (campoSubsanar.selfie) {
					if (img.titulo !== "DNI-Selfie") {
						dispatch(setUrlTemporal(img.id, img.titulo));
					}
					dispatch(setUrlTemporal(img.id, img.titulo));
				} else {
					dispatch(setUrlTemporal(img.id, img.titulo));
				}
			}
			setToDoUrlTemporal(false);
			await setImagenesTemporales();
		}
	}

	//onChange para cuando cambian las imagenes
	//primero se setea el file en la variable correspondiente
	//despues se setea si es que hubo cambios respecto a la foto que estaba guardada en el store
	//si es una imagen que se subsanó se setea el cambio de esa imagen subsanada
	function onImagenDniDorsoChange(e) {
		setImagenDniDorso(e.target.files[0]);
		if (Object.keys(storeDniDorso).length !== 0) {
			setCambiosDniDorso(true);
		}

		if (campoSubsanar.dni) {
			setCambiosDniDorso(true);
			setsubsanoImagen({ ...subsanoImagen, dorso: true });
		}
		// dispatch(limpiarFotoStore("DNI-Dorso"));
	}
	function onImagenDniFrenteChange(e) {
		setImagenDniFrente(e.target.files[0]);
		if (Object.keys(storeDniFrente).length !== 0) {
			setCambiosDniFrente(true);
		}
		if (campoSubsanar.dni) {
			setCambiosDniFrente(true);
			setsubsanoImagen({ ...subsanoImagen, frente: true });
		}
		// dispatch(limpiarFotoStore("DNI-Frente"));
	}
	function onImagenSelfieChange(e) {
		setImagenSelfie(e.target.files[0]);
		if (Object.keys(storeDniSelfie).length !== 0) {
			setCambiosSelfie(true);
		}
		if (campoSubsanar.selfie) {
			setCambiosSelfie(true);
			setsubsanoImagen({ ...subsanoImagen, selfie: true });
		}
		// dispatch(limpiarFotoStore("DNI-Selfie"));
	}

	//chequea si todos los archivos son imagenes para ver si puede continuar o no
	function todosLosArchivosImagenes() {
		let todasImagenes = true;
		if (
			imagenDniFrente === null &&
			imagenDniDorso === null &&
			imagenSelfie === null
		) {
			return todasImagenes;
		} else {
			let idx = 0;
			const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

			if (!campoSubsanar.dni && !campoSubsanar.selfie) {
				if (cambiosDniFrente || cambiosDniDorso || cambiosSelfie) {
					if (cambiosDniFrente) {
						let file = imagenDniFrente;
						let fileType = file["type"];
						if (!validImageTypes.includes(fileType)) {
							// invalid file type code goes here.
							todasImagenes = false;
						}
					}
					if (cambiosDniDorso) {
						let file = imagenDniDorso;
						let fileType = file["type"];
						if (!validImageTypes.includes(fileType)) {
							// invalid file type code goes here.
							todasImagenes = false;
						}
					}
					if (cambiosSelfie) {
						let file = imagenSelfie;
						let fileType = file["type"];
						if (!validImageTypes.includes(fileType)) {
							// invalid file type code goes here.
							todasImagenes = false;
						}
					}
				} else {
					while (idx < 3 && todasImagenes) {
						let file =
							idx === 0
								? imagenDniFrente
								: idx === 1
								? imagenDniDorso
								: imagenSelfie;
						let fileType = file["type"];

						if (!validImageTypes.includes(fileType)) {
							// invalid file type code goes here.
							todasImagenes = false;
						}
						idx++;
					}
				}
			} else if (campoSubsanar.dni) {
				while (idx < 2 && todasImagenes) {
					let file = idx === 0 ? imagenDniFrente : imagenDniDorso;

					let fileType = file["type"];

					if (!validImageTypes.includes(fileType)) {
						// invalid file type code goes here.
						todasImagenes = false;
					}
					idx++;
				}
			} else if (campoSubsanar.selfie) {
				let file = imagenSelfie;

				let fileType = file["type"];

				if (!validImageTypes.includes(fileType)) {
					// invalid file type code goes here.
					todasImagenes = false;
				}
			}
		}
		return todasImagenes;
	}

	const onClickContinuar = async () => {
		setError("");
		if (!todosLosArchivosImagenes()) {
			setError("Los archivos deben ser imágenes");
		} else {
			//todos tienen imagenes en el store y no cambio ninguna imagen
			if (
				Object.keys(storeDniFrente).length !== 0 &&
				Object.keys(storeDniDorso).length !== 0 &&
				Object.keys(storeDniSelfie).length !== 0 &&
				!cambiosDniFrente &&
				!cambiosDniDorso &&
				!cambiosSelfie
			) {
				//si no hay que subsanar navega
				if (subsanar.length === 0) {
					navigate("/perfil-inversor");
				} else {
					//si no hay nada mas por subsanar va a usuario validado directamente
					rutasSubsanar?.includes("ddjj")
						? navigate("/declaraciones/")
						: navigate("/usuario-validado/");
				}
				//todos tienen imagenes en el store y hubo cambios en alguna imagen
			} else if (cambiosDniFrente || cambiosDniDorso || cambiosSelfie) {
				setCargando(true);
				if (cambiosDniFrente) {
					const idImagenFrente = storeImagenesApi.find(
						(element) => element.order === 0
					)._id;
					if (!campoSubsanar.dni) {
						await apiRoutes
							.putImagen(idImagenFrente, 0, imagenDniFrente)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniDorso && !cambiosSelfie) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					} else {
						await apiRoutes
							.subsanarImagen(idImagenFrente, 0, imagenDniFrente)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniDorso && !cambiosSelfie) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					}
				}
				if (cambiosDniDorso) {
					const idImagenDorso = storeImagenesApi.find(
						(element) => element.order === 1
					)._id;
					if (!campoSubsanar.dni) {
						await apiRoutes
							.putImagen(idImagenDorso, 1, imagenDniDorso)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniFrente && !cambiosSelfie) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					} else {
						await apiRoutes
							.subsanarImagen(idImagenDorso, 1, imagenDniDorso)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniFrente && !cambiosSelfie) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					}
				}
				if (cambiosSelfie) {
					const idImagenSelfie = storeImagenesApi.find(
						(element) => element.order === 2
					)._id;
					if (!campoSubsanar.selfie) {
						await apiRoutes
							.putImagen(idImagenSelfie, 2, imagenSelfie)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniFrente && !cambiosDniDorso) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					} else {
						await apiRoutes
							.subsanarImagen(idImagenSelfie, 2, imagenSelfie)
							.then(
								function (value) {
									// Success!
									dispatch(getImagenes());
									setToDoUrlTemporal(true);
									if (!cambiosDniFrente && !cambiosDniDorso) {
										setCargando(false);
										if (subsanar.length === 0) {
											navigate("/perfil-inversor");
										} else {
											rutasSubsanar?.includes("ddjj")
												? navigate("/declaraciones/")
												: navigate(
														"/usuario-validado/"
												  );
										}
									}
								},
								function (reason) {
									setCargando(false); // Error!
								}
							);
					}
				}
				if (
					(cambiosDniFrente && cambiosDniDorso) ||
					(cambiosSelfie && cambiosDniDorso) ||
					(cambiosDniFrente && cambiosSelfie)
				) {
					setCargando(false);
					if (subsanar.length === 0) {
						navigate("/perfil-inversor");
					} else {
						rutasSubsanar?.includes("ddjj")
							? navigate("/declaraciones/")
							: navigate("/usuario-validado/");
					}
				}
			} else {
				//cuando es la primera vez que sube imagenes
				setCargando(true);
				let imagenes = [imagenDniFrente, imagenDniDorso, imagenSelfie];

				await apiRoutes.adjuntarImagen(imagenes).then(
					function (value) {
						// Success!
						dispatch(getImagenes());
						setToDoUrlTemporal(true);
						setCargando(false);
						if (subsanar.length === 0) {
							navigate("/perfil-inversor");
						} else {
							rutasSubsanar?.includes("ddjj")
								? navigate("/declaraciones/")
								: navigate("/usuario-validado/");
						}
					},
					function (error) {
						if (error.response.status === 413) {
							setError("Las imagenes deben pesar menos de 1 MB.");
						} else {
							console.log(error.response);
						}
						setCargando(false); // Error!
					}
				);
			}
		}
	};

	async function setImagenesTemporales() {
		if (storeImagenesApi.length !== 0) {
			setImageUrlFrente(
				storeImagenesApi.find((i) => i.order === 0).url_temporal
			);
			setImageUrlDorso(
				storeImagenesApi.find((i) => i.order === 1).url_temporal
			);
			setImageUrlSelfie(
				storeImagenesApi.find((i) => i.order === 2).url_temporal
			);
		}
	}
	return {
		imagenDniFrente,
		storeDniFrente,
		imageUrlFrente,
		subsanar,
		campoSubsanar,
		subsanoImagen,
		imagenDniDorso,
		storeDniDorso,
		imagenSelfie,
		imageUrlDorso,
		storeDniSelfie,
		imageUrlSelfie,
		onClickContinuar,
		cargando,
		onImagenDniDorsoChange,
		onImagenDniFrenteChange,
		onImagenSelfieChange,
		error,
	};
};
