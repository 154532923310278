import React from "react";
import { useEffect } from "react";
import { cambiarPantallaActual } from "../../redux/actions";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import s from "./PoliticaDePrivacidad.module.css";
import { textos } from "../../texts/textos-politica.js";

const PoliticaDePrivacidad = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
	}, [dispatch]);
	const texts = textos.arrayTextos;
	const renderArray = () => {
		return texts.map((text, index) => (
			<div className={s.contenedorTextos} key={index}>
				<Box
					sx={{
						mb: 1,
						mt: 2,
						display: "flex",
						flexDirection: "column",
						textAlign: "left",
					}}
				>
					<span className={s.titulo}>
						{text.titulo?.toLocaleUpperCase()}
					</span>
				</Box>
				{text.tituloChico?.map((tituloChico, ind) => (
					<p key={ind} className={s.tituloChico}>
						{tituloChico}
					</p>
				))}
				{text.parrafos?.map((parrafo, ind) => (
					<p key={ind} className={s.parrafo}>
						{parrafo}
					</p>
				))}

				{text.parrafosConLinks?.map((parrafosConLinks, ind) => (
                    <p key={ind} className={s.parrafo} dangerouslySetInnerHTML={{__html: parrafosConLinks}} />
				))}
                	{text.parrafosPostLinks?.map((parrafosPostLinks, ind) => (
					<p key={ind} className={s.parrafoPostLinks}>
						{parrafosPostLinks}
					</p>
				))}
				{text.parrafosEnBold?.map((parrafosEnBold, ind) => (
					<p key={ind} className={s.parrafoBold}>
						{parrafosEnBold}
					</p>
				))}
				{text.items?.map((items, ind) => (
					<li key={ind} className={s.item}>
						{items}
					</li>
				))}
			</div>
		));
	};
	return (
		<div className={s.contenedorPrincipal}>
			<h3 className={s.tituloPrincipal}>POLÍTICA DE PRIVACIDAD</h3>
			{renderArray()}
		</div>
	);
};

export default PoliticaDePrivacidad;
