import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ConfirmacionMail } from "../views/ConfirmacionMail/ConfirmacionMail";
import { DatosPersonales } from "../views/DatosPersonales/DatosPersonales";
import Home from "../views/Home/Home";
import { AdjuntarArchivos } from "../views/AdjuntarArchivos/AdjuntarArchivos";
import { PerfilInversor } from "../views/Perfilnversor/PerfilInversor";
import { DDJJ } from "../views/DDJJ/DDJJ";
import { Terminos } from "../views/Terminos/Terminos";
import VerificacionSms from "../views/VerificacionSms/VerificacionSms";
import UsuarioValidado from "../views/UsuarioValidado/UsuarioValidado";
import PoliticaDePrivacidad from "../views/PoliticaDePrivacidad/PoliticaDePrivacidad";
import { SubsanarApertura } from "../views/SubsanarApertura/SubsanarApertura";
import { ReAperturaDeUsuarios } from "../views/ReAperturaDeUsuarios/ReAperturaDeUsuarios";
import { CotitularesIncorporacion } from "../views/CotitularesIncorporacion/CotitularesIncorporacion";
import { CotitularesConformidad } from "../views/CotitularesConformidad/CotitularesConformidad";
import { TipoDeCuenta } from "../views/TipoDeCuenta/TipoDeCuenta";

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="confirmacion" element={<ConfirmacionMail />} />
				<Route path="tipo-cuenta" element={<TipoDeCuenta />} />
				<Route path="datos-personales" element={<DatosPersonales />} />
				<Route path="adjuntos" element={<AdjuntarArchivos />} />
				<Route
					path="perfil-inversor"
					element={<PerfilInversor />}
				/>
				<Route path="declaraciones" element={<DDJJ />} />
				<Route path="terminos" element={<Terminos />} />
				<Route path="verificacion-sms" element={<VerificacionSms />} />
				<Route path="usuario-validado" element={<UsuarioValidado />} />
				<Route
					path="politica-de-privacidad"
					element={<PoliticaDePrivacidad />}
				/>
				
				<Route path="subsanar/mail:mail" element={<SubsanarApertura />} />

				<Route path="reapertura/:token" element={<ReAperturaDeUsuarios />} />
				<Route path="reanudar/mail:mail" element={<SubsanarApertura />} />

				<Route path="/incorporacion" element={<CotitularesIncorporacion />} />
				<Route path="/incorporacion/:token" element={<CotitularesIncorporacion />} />

				<Route path="/incorporacion-conformidad/:token" element={<CotitularesConformidad />} />
				<Route path="reanudar-incorporacion/mail:mail" element={<SubsanarApertura />} />
				
			</Routes>
		</BrowserRouter>
	);
};