import { useState, useEffect } from "react"
import { getDDJJ, saveDeclaraciones } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiRoutes from "../api/apiRoutes";

/**
 * Hook que manipula el formulario para realizar y modificar las declaraciones juradas
 * @param {Objetc} initialForm Formulario inicial
 * @param {Object} validateForm Función validadora que devuelque los errores
 * @returns {Objetc} 
 */


export const useFormDeclaraciones = (initialForm, validateForm) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const datosP = useSelector((state) => state.datosEtapasReducer.declaracionesPrevias);
    const subsanar = useSelector((state) => state.subsanarReducer.subsanar);
    const rutasSubsanar = useSelector((state) => state.subsanarReducer.rutasSubsanar)
    const etapasCompletadas = useSelector((state) => state.reanudarReducer.etapasCompletadas);

    const [form, setForm] = useState(initialForm);

    const [errors, setErrors] = useState({});


    useEffect(() => {
        // Ir comprobando los errores mientras el usuario modifica los campos,
		// una vez que ya haya errores, después del primer intento de submit
        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form, datosP, subsanar));
        }
    }, [form, datosP]);

    useEffect(() => {
        // Para que ya esten los campos de errores marcados aunque no hayan intentando
		// hacer submit (ES SOLO EN CASO DE SUBSANACION)
		if(subsanar?.length > 0){
			setErrors(validateForm(form, datosP, subsanar));
		}
	}, [datosP]);

    const handleClick = (e) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value,
        })

        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form, datosP, subsanar));
        }
        
    }

    const handleCheck = (e) => {
        const {name, checked} = e.target;

        setForm({
            ...form,
            [name]: checked,
        })

        if (Object.keys(errors).length > 0) {
            setErrors(validateForm(form, datosP, subsanar));
        }
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let errorsVariableEstatica = validateForm(form, datosP, subsanar);
        setErrors(validateForm(form, datosP, subsanar));
        
        // Se comprueba que no haya errores
        if (
            Object.keys(errors).length === 0 &&
            Object.keys(errorsVariableEstatica).length === 0 
        ) {
            try {
                if(etapasCompletadas?.includes("DDJJ") || subsanar?.length > 0){

                    // En caso de modificacion de datos ya subidos:
					// Se asignan los datos anteriores en caso de no modificarlos para que no se envié 
					// al back un string vació

                    form.VinculoClienteAgente = form.VinculoClienteAgente ? form.VinculoClienteAgente : datosP?.VinculoClienteAgente;
                    form.InvertirExterior = form.InvertirExterior ? form.InvertirExterior : datosP?.InvertirExterior;
                    form.OrigenLicitudFondos = form.OrigenLicitudFondos ? form.OrigenLicitudFondos : datosP?.OrigenLicitudFondos;
                    form.CondicionSujetoObligado = form.CondicionSujetoObligado ? form.CondicionSujetoObligado : datosP?.CondicionSujetoObligado;
                    form.ExpuestaPoliticamente = form.ExpuestaPoliticamente ? form.ExpuestaPoliticamente : datosP?.ExpuestaPoliticamente;
                    form.ResidenciaFiscal = form.ResidenciaFiscal ? form.ResidenciaFiscal : datosP?.ResidenciaFiscal;
                    form.InversorCalificado = form.InversorCalificado ? form.InversorCalificado : datosP?.InversorCalificado;
                    form.FATCA = form.FATCA ? form.FATCA : datosP?.FATCA;

                    if (subsanar?.length > 0) {
                        // Si es subsanacion se hace la peticion correspondiente al back
                        apiRoutes.subsanarDeclaraciones(form).then(
                            function (value) {
                                // Success!
                                dispatch(saveDeclaraciones(form));
                            },
                            function (error) {
                                console.log(error.response);
                            }
                        );
                    } else {
                        // Si es solo una actualizacion producto de ir hacía atrás con el stepper 
						// se hace la peticion correspondiente al back
                        apiRoutes.updateDDJJ(form).then(
                            function (value) {
                                // Success!
                                dispatch(saveDeclaraciones(form));
                            },
                            function (error) {
                                console.log(error.response);
                            }
                        );
                    }
                } else {
                    // Si es la primera vez que se envían los datos se hace la peticion correspondiente al back
                    apiRoutes.declaracionJurada(form).then(
                        function (value) {
                            // Success!
                            dispatch(saveDeclaraciones(form));
                        },
                        function (error) {
                            console.log(error.response);
                        }
                    );
                }

                if(subsanar?.length > 0){ 
                    navigate("/usuario-validado");
                } else {
                    navigate("/terminos");
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    }

    return {
        form,
        errors,
        handleClick,
        handleCheck,
        handleSubmit,
    }
}