/**
 * Función para obtener el id de las imagenes y ordenarlo segun el orden correspondiente
 * @param {any} storeImagenesApi Info del store de las imagenes que hay guardadas en la api
 * @returns {[]} Array ordenado con el titulo de la imagen y el id
 */
export const idsImagenes = (storeImagenesApi) => {
	//save id from storeImagenesApi in Array
	const arrayIds = [
		{ titulo: "DNI-Frente" },
		{ titulo: "DNI-Dorso" },
		{ titulo: "DNI-Selfie" },
	];
	storeImagenesApi.forEach(item => {
		let order = item.order;
		arrayIds[order].id = item._id;
	});
	return arrayIds;
};
