
export const arrayGenero = [ "Femenino", "Masculino", "X"];
export const arrayGeneroFem = [ "Masculino", "X"];
export const arrayGeneroMasc = [ "Femenino", "X"];
export const arrayGeneroX = [ "Femenino", "Masculino"];

export const tipoDocumento = [ "DNI", "Pasaporte", "LE", "LC"];
export const tipoDocumentoDNI = [ "Pasaporte", "LE", "LC"];
export const tipoDocumentoPasaporte = [ "DNI", "LE", "LC"];
export const tipoDocumentoLE = [ "DNI", "Pasaporte", "LC"];
export const tipoDocumentoLC = [ "DNI", "Pasaporte", "LE"];

export const estadoCivil = [ "Soltero/a", "Casado/a", "Divorciado/a", "Viudo/a"];
export const estadoCivilSoltero = [ "Casado/a", "Divorciado/a", "Viudo/a"];
export const estadoCivilCasado = [ "Soltero/a", "Divorciado/a", "Viudo/a"];
export const estadoCivilDivorciado = [ "Soltero/a", "Casado/a", "Viudo/a"];
export const estadoCivilViudo = [ "Soltero/a", "Casado/a", "Divorciado/a"];