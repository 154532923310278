import {
	GET_USER_DATA,
	GET_OLD_USER_DATA,
	GET_COTITULAR_DATA,
	PUT_NOMBRE,
	CAMBIAR_NUMERO_TELEFONO,
	PUT_TIPOCUENTA,
	SET_COTITULAR_NUEVOS_DATA,
} from "../constantes";

const initialState = {
	usuario: [],
	usuarioNuevo: true,
	cotitular: false,
	tipoCuenta: null,
};

function userDataReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USER_DATA:
			return {
				...state,
				usuario: action.payload,
				tipoCuenta: action.payload.tipocuenta,
			};
		case GET_OLD_USER_DATA:
			return {
				...state,
				usuario: action.payload,
				usuarioNuevo: false,
			};
		case GET_COTITULAR_DATA:
			return {
				...state,
				usuario: action.payload,
				cotitular: true,
			};
		case SET_COTITULAR_NUEVOS_DATA:
			return {
				...state,
				usuario: action.payload,
			};
		case PUT_NOMBRE:
			return {
				...state,
				usuario: action.payload,
			};
		case CAMBIAR_NUMERO_TELEFONO:
			return {
				...state,
				usuario: {
					...state.usuario,
					phone: action.payload,
				},
			};
		case PUT_TIPOCUENTA:
			return {
				...state,
				tipoCuenta: action.payload,
			};
		default:
			return state;
	}
}

export default userDataReducer;
