/**
 * Función para chequear si la etapa buscada ya fue completada anteriormente buscando en la lista de etapas ya completas
 * @param {string} nomEtapa Nombre de la etapa buscada
 * @param {string[]} arrEtapas Array de etapas
 * @returns {boolean} Boolean si se encuentra o no
 */
export const etapaCompletaAnteriormente = (nomEtapa, arrEtapas) => {
	if (arrEtapas.length === 0) return false;
	const etapa = arrEtapas.find((etapa) => etapa === nomEtapa);
	if (etapa) {
		return true;
	} else {
		return false;
	}
};