import React from "react";
import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@material-ui/core/Button";
import s from "./AdjuntarArchivos.module.css";
import {
	ContenedorCard,
	ContenedorCardAmarillo,
	ContenedorCardGris,
	ContenedorCardRojo,
} from "./adjuntosElements";
import { useAdjuntos } from "../../hooks/useAdjuntos";
import { Contacto } from "../../components/Contacto/Contacto";

export const AdjuntarArchivos = () => {
	const {
		imagenDniFrente,
		storeDniFrente,
		imageUrlFrente,
		subsanar,
		campoSubsanar,
		subsanoImagen,
		imagenDniDorso,
		storeDniDorso,
		imagenSelfie,
		imageUrlDorso,
		storeDniSelfie,
		imageUrlSelfie,
		onClickContinuar,
		cargando,
		onImagenDniDorsoChange,
		onImagenDniFrenteChange,
		onImagenSelfieChange,
		error,
	} = useAdjuntos();

	//En las rederizaciones tipocard... hay 4 opciones.
	// La primera es cuando el usuario está completando por primera vez esta pantalla
	// La segunda es cuando el usuario debe subsanar esa imagen
	// La tercera cuando el usuario debe subsanar otra imagen y NO esa
	// La última cuando el usuario ya cargó una imagen

	function tipoCardDniFrente() {
		if (
			(imagenDniFrente === null || imagenDniFrente === undefined) &&
			Object.keys(storeDniFrente).length === 0 &&
			(imageUrlFrente === undefined || imageUrlFrente === null)
		) {
			return (
				<ContenedorCard>
					<Icon
						icon="icon-park-outline:id-card-h"
						style={{
							color: `${({ theme }) => theme.fontColor}`,
							width: "55px",
							height: "45px",
							fontWeight: "20px",
							marginRight: "16px",
							marginLeft: "16px",
						}}
					/>

					<span className={s.textoCardAdjuntos}>
						Adjuntar foto del frente del DNI
						<br />
						(Último ejemplar)
					</span>
				</ContenedorCard>
			);
		} else {
			if (campoSubsanar.dni) {
				return (
					<ContenedorCardRojo>
						{subsanoImagen.frente ? (
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlFrente
										? imageUrlFrente
										: storeDniFrente.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>
						) : (
							<Icon
								icon="icon-park-outline:id-card-h"
								style={{
									color: `${({ theme }) => theme.fontColor}`,
									width: "55px",
									height: "45px",
									fontWeight: "20px",
									marginRight: "16px",
									marginLeft: "16px",
								}}
							/>
						)}

						<span className={s.textoCardAdjuntos}>
							Adjuntar foto del frente del DNI
						</span>
					</ContenedorCardRojo>
				);
			} else {
				if (subsanar.length > 0) {
					return (
						<ContenedorCardGris>
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlFrente
										? imageUrlFrente
										: storeDniFrente.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
								style={{ filter: "grayscale(100%)" }}
							/>

							<span
								className={s.textoCardAdjuntos}
								style={{ color: "#949494" }}
							>
								Frente del DNI
							</span>
						</ContenedorCardGris>
					);
				} else {
					return (
						<ContenedorCardAmarillo>
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlFrente
										? imageUrlFrente
										: storeDniFrente.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>

							<span className={s.textoCardAdjuntos}>
								Frente del DNI
							</span>
						</ContenedorCardAmarillo>
					);
				}
			}
		}
	}
	function tipoCardDniDorso() {
		if (
			(imagenDniDorso === null || imagenDniDorso === undefined) &&
			Object.keys(storeDniDorso).length === 0 &&
			(imageUrlDorso === undefined || imageUrlDorso === null)
		) {
			return (
				<ContenedorCard>
					<Icon
						icon="icon-park-outline:id-card"
						style={{
							color: `${({ theme }) => theme.fontColor}`,
							width: "55px",
							height: "42px",
							fontWeight: "20px",
							marginRight: "16px",
							marginLeft: "16px",
						}}
					/>

					<span className={s.textoCardAdjuntos}>
						Adjuntar foto del dorso del DNI
						<br />
						(Último ejemplar)
					</span>
				</ContenedorCard>
			);
		} else {
			if (campoSubsanar.dni) {
				return (
					<ContenedorCardRojo>
						{subsanoImagen.dorso ? (
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlDorso
										? imageUrlDorso
										: storeDniDorso.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>
						) : (
							<Icon
								icon="icon-park-outline:id-card"
								style={{
									color: `${({ theme }) => theme.fontColor}`,
									width: "55px",
									height: "42px",
									fontWeight: "20px",
									marginRight: "16px",
									marginLeft: "16px",
								}}
							/>
						)}

						<span className={s.textoCardAdjuntos}>
							Adjuntar foto del dorso del DNI
						</span>
					</ContenedorCardRojo>
				);
			} else {
				if (subsanar.length > 0) {
					return (
						<ContenedorCardGris>
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlDorso
										? imageUrlDorso
										: storeDniDorso.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
								style={{ filter: "grayscale(100%)" }}
							/>

							<span
								className={s.textoCardAdjuntos}
								style={{ color: "#949494" }}
							>
								Dorso del DNI
							</span>
						</ContenedorCardGris>
					);
				} else {
					return (
						<ContenedorCardAmarillo>
							<img
								src={
									// Object.keys(storeDniFrente).length !== 0
									// 	? storeDniFrente.urlTemporal
									// 	: imageUrlFrente
									imageUrlDorso
										? imageUrlDorso
										: storeDniDorso.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>

							<span className={s.textoCardAdjuntos}>
								Dorso del DNI
							</span>
						</ContenedorCardAmarillo>
					);
				}
			}
		}
	}
	function tipoCardSelfie() {
		if (
			(imagenSelfie === null || imagenSelfie === undefined) &&
			Object.keys(storeDniSelfie).length === 0 &&
			(imageUrlSelfie === undefined || imageUrlSelfie === null)
		) {
			return (
				<ContenedorCard>
					<Icon
						icon="ic:outline-photo-camera-front"
						style={{
							color: `${({ theme }) => theme.fontColor}`,
							width: "55px",
							height: "45px",
							fontWeight: "20px",
							marginRight: "16px",
							marginLeft: "16px",
						}}
					/>
					<span className={s.textoCardAdjuntos}>
						Subí una selfie en la que te veas junto a tu DNI. Es
						importante que la foto tenga buena luz y definición.
					</span>
				</ContenedorCard>
			);
		} else {
			if (campoSubsanar.selfie) {
				return (
					<ContenedorCardRojo>
						{subsanoImagen.selfie ? (
							<img
								src={
									// Object.keys(storeDniSelfie).length !== 0
									// 	? storeDniSelfie.urlTemporal
									// 	: imageUrlSelfie
									imageUrlSelfie
										? imageUrlSelfie
										: storeDniSelfie.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>
						) : (
							<Icon
								icon="ic:outline-photo-camera-front"
								style={{
									color: `${({ theme }) => theme.fontColor}`,
									width: "55px",
									height: "45px",
									fontWeight: "20px",
									marginRight: "16px",
									marginLeft: "16px",
								}}
							/>
						)}
						<span className={s.textoCardAdjuntos}>
							Subí una selfie en la que te veas junto a tu DNI. Es
							importante que la foto tenga buena luz y definición.
						</span>
					</ContenedorCardRojo>
				);
			} else {
				if (subsanar.length > 0) {
					return (
						<ContenedorCardGris>
							<img
								src={
									// Object.keys(storeDniSelfie).length !== 0
									// 	? storeDniSelfie.urlTemporal
									// 	: imageUrlSelfie
									imageUrlSelfie
										? imageUrlSelfie
										: storeDniSelfie.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
								style={{ filter: "grayscale(100%)" }}
							/>
							<span
								className={s.textoCardAdjuntos}
								style={{ color: "#949494" }}
							>
								Selfie junto al DNI
							</span>
						</ContenedorCardGris>
					);
				} else {
					return (
						<ContenedorCardAmarillo>
							<img
								src={
									// Object.keys(storeDniSelfie).length !== 0
									// 	? storeDniSelfie.urlTemporal
									// 	: imageUrlSelfie
									imageUrlSelfie
										? imageUrlSelfie
										: storeDniSelfie.urlTemporal
								}
								alt="imagen"
								className={s.imagenCard}
							/>
							<span className={s.textoCardAdjuntos}>
								Selfie junto al DNI
							</span>
						</ContenedorCardAmarillo>
					);
				}
			}
		}
	}

	//Verifica si está todo cargado para habiitar o no a opción de continuar
	function todoCargado() {
		if (subsanar.length === 0) {
			return (
				(imagenDniDorso !== null &&
					imagenDniFrente !== null &&
					imagenSelfie !== null &&
					imagenDniDorso !== undefined &&
					imagenDniFrente !== undefined &&
					imagenSelfie !== undefined) ||
				(Object.keys(storeDniFrente).length !== 0 &&
					Object.keys(storeDniDorso).length !== 0 &&
					Object.keys(storeDniSelfie).length !== 0)
			);
		} else {
			if (campoSubsanar.dni) {
				if (subsanoImagen.frente && subsanoImagen.dorso) {
					return true;
				} else {
					return false;
				}
			} else if (campoSubsanar.selfie) {
				if (subsanoImagen.selfie) {
					return true;
				} else {
					return false;
				}
			}
		}
	}

	function getButton() {
		if (!todoCargado()) {
			return (
				<Button
					type="submit"
					className={s.botonContinuar}
					size="large"
					variant="contained"
					fullWidth
					disabled
					style={{
						backgroundColor: `${({ theme }) =>
							theme.disabledButton} !important`,
						color: `${({ theme }) =>
							theme.disabledFont} !important`,
					}}
				>
					Continuar
				</Button>
			);
		} else {
			return (
				<Button
					type="submit"
					className={s.botonContinuar}
					size="large"
					color="primary"
					variant="contained"
					fullWidth
					onClick={onClickContinuar}
				>
					Continuar
				</Button>
			);
		}
	}
	const spinnerCarga = () => {
		if (!cargando) {
			return (
				<>
				<div>
					<h3 className={s.titulo}>ADJUNTOS</h3>
					<label
						className={
							campoSubsanar.dni || subsanar.length === 0
								? s.labelPointer
								: null
						}
						htmlFor="foto-frente"
					>
						{tipoCardDniFrente()}
					</label>
					<input
						type="file"
						name="frente-dni"
						id="foto-frente"
						accept="image/*"
						onChange={onImagenDniFrenteChange}
						disabled={!campoSubsanar.dni && subsanar.length !== 0}
					/>
					<label
						className={
							campoSubsanar.dni || subsanar.length === 0
								? s.labelPointer
								: null
						}
						htmlFor="foto-dorso"
					>
						{tipoCardDniDorso()}
					</label>
					<input
						type="file"
						name="dorso-dni"
						id="foto-dorso"
						accept="image/*"
						disabled={!campoSubsanar.dni && subsanar.length !== 0}
						onChange={onImagenDniDorsoChange}
					/>
					<label
						className={
							campoSubsanar.selfie || subsanar.length === 0
								? s.labelPointer
								: null
						}
						htmlFor="foto-selfie"
					>
						{tipoCardSelfie()}
					</label>
					<input
						type="file"
						name="selfie"
						id="foto-selfie"
						accept="image/*"
						disabled={
							!campoSubsanar.selfie && subsanar.length !== 0
						}
						onChange={onImagenSelfieChange}
					/>
					{error !== "" && (
						<span style={{ color: "red", textAlign: "center" }}>
							{error}
						</span>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mb: 2,
						}}
					>
						{getButton()}
					</Box>{" "}
				</div>
				<Contacto/>
				</>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress style={{ color: "#004B6F" }} />
				</Box>
			);
		}
	};

	return <div className={s.contenedorPrincipal}>{spinnerCarga()}</div>;
};
export default AdjuntarArchivos;
