import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	cambiarPantallaActual,
	setDni,
} from "../../redux/actions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ContenedorBox } from "./elements";
import { Input } from "../ReAperturaDeUsuarios/reAperturaElements";
import s from "./CotitularesIncorporacion.module.css";
import { Button } from "@mui/material";
import { useFormIntegracion } from "../../hooks/useFormIntegracion";
import { Buffer } from "buffer";
import { validacionesCotitulares } from "../../helpers/validacionesCotitulares";

export const CotitularesIncorporacion = () => {
	const { width } = useWindowDimensions();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(cambiarPantallaActual(-1));
		getTokenData();
	}, []);
	const [emailCotitularNuevo, setEmailCotitularNuevo] = useState(null);
	const [titularId, setTitularId] = useState(null);

	const initialForm = {
		name: "",
		last_name: "",
		email: "",
		phone: "",
		numCuenta: "",
	};

	const { form, errors, handleChange, handleSubmit, setParams } =
		useFormIntegracion(
			initialForm,
			validacionesCotitulares,
			emailCotitularNuevo,
			titularId
		);

	function getTokenData() {
		let url = window.location.href;
		let token = url.substring(url.lastIndexOf("/")).slice(1);

		// Create a buffer from the string
		let bufferObj = Buffer.from(token, "base64");

		// Encode the Buffer as a utf8 string
		let decodedString = bufferObj.toString("utf8");

		// Usamos una expresión regular para buscar el email y el titular id
		const emailMatch = decodedString.match(/email:([^&]+)/);
		const titularIdMatch = decodedString.match(/titularid:([^&]+)/);
		const nombreMatch = decodedString.match(/nombre:([^&]+)/);
		const apellidoMatch = decodedString.match(/apellido:([^&]+)/);
		const dniMatch = decodedString.match(/dni:([^&]+)/);

		// Comprobamos si se encontraron coincidencias y extraemos los valores
		if (
			emailMatch &&
			titularIdMatch &&
			nombreMatch &&
			apellidoMatch &&
			dniMatch
		) {
			const emailParam = emailMatch[1];
			const titularIdParam = titularIdMatch[1];
			setEmailCotitularNuevo(emailParam);
			setTitularId(titularIdParam);

			setParams(nombreMatch[1], apellidoMatch[1]);
			dispatch(setDni(dniMatch[1]));
		}
	}

	const politicaPrivacidad =
		'Al enviar tus datos estás aceptando la <a href="/politica-de-privacidad" target="_blank">Politica de privacidad</a> de Rava Bursátil';

	return (
        <div className={s.contenedorPrincipal}>
            <ContenedorBox>
                <span className={s.titulo}>
                    <h1>
                        INGRESA TUS DATOS DE TU CUENTA Y SOLICITÁ TU
                        INCORPORACIÓN A LA CUENTA
                    </h1>
                </span>
                <form
                    onSubmit={handleSubmit}
                    autoComplete={"off"}
                    className={s.contenedorFormulario}
                >
                    <div
                        className={
                            width > 992 ? s.containerEsp : s.containerNoEsp
                        }
                    >
                        <div
                            className={
                                width > 992
                                    ? s.optionContainerEsp
                                    : s.optionContainer
                            }
                        >
                            <label className={s.labelForm}>Nombre/s</label>

                            <Input
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                placeholder={
                                    width < 992
                                        ? "Nombre completo como figura en el DNI"
                                        : "Nombre/s"
                                }
                            />

                            {width > 992 && (
                                <label className={s.textoGris}>
                                    Nombre completo como figura en el DNI
                                </label>
                            )}
                            {errors.name && (
                                <p className={s.error}>{errors.name}</p>
                            )}
                        </div>
                        <div
                            className={
                                width > 992
                                    ? s.optionContainerEsp
                                    : s.optionContainer
                            }
                        >
                            <label className={s.labelForm}>Apellido/s</label>

                            <Input
                                type="text"
                                name="last_name"
                                value={form.last_name}
                                onChange={handleChange}
                                placeholder={
                                    width < 992
                                        ? "Apellido completo como figura en el DNI"
                                        : "Apellido/s"
                                }
                            />

                            {width > 992 && (
                                <label className={s.textoGris}>
                                    Nombre completo como figura en el DNI
                                </label>
                            )}
                            {errors.last_name && (
                                <p className={s.error}>{errors.last_name}</p>
                            )}
                        </div>
                    </div>
                    {!emailCotitularNuevo ? (
                        <div className={s.optionContainer}>
                            <label className={s.labelForm}>Email</label>

                            <Input
                                type="text"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                placeholder={
                                    width < 992
                                        ? "Ingrese un mail válido"
                                        : "Email"
                                }
                            />
                            {errors.email && (
                                <p className={s.error}>{errors.email}</p>
                            )}
                        </div>
                    ) : null}

                    <div className={s.optionContainer}>
                        <label className={s.labelForm}>Celular</label>

                        <Input
                            type="text"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                        />
                        {width > 992 && (
                            <label className={s.textoGris}>
                                Ingresar con código de área (ej. 11) sin 0 ni 15
                            </label>
                        )}

                        {errors.phone && (
                            <p className={s.error}>{errors.phone}</p>
                        )}
                    </div>

                    {!emailCotitularNuevo ? (
                        <div className={s.optionContainer}>
                            <label className={s.labelForm}>
                                Número de cuenta
                            </label>

                            <Input
                                type="text"
                                name="numCuenta"
                                value={form.numCuenta}
                                onChange={handleChange}
                                placeholder={
                                    "N° de cuenta de la cual querés formar parte"
                                }
                            />
                            {errors.numCuenta && (
                                <p className={s.error}>{errors.numCuenta}</p>
                            )}
                        </div>
                    ) : null}

                    {errors.request && (
                        <p className={s.error}>{errors.request}</p>
                    )}

                    <div className={s.botonContainer}>
                        <Button
                            className={s.boton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            Continuar
                        </Button>
                    </div>
                </form>
                <div className={s.secondBox}>
                    <span
                        className={s.privacidad}
                        dangerouslySetInnerHTML={{ __html: politicaPrivacidad }}
                    />
                </div>
            </ContenedorBox>
        </div>
    );
};
