import styled from "styled-components";

export const ContenedorBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.backgroundContainerHome};
    border-radius: 0.8em;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    width: 90%;
    height: 85%;
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
    justify-content: center;
    justify-self: center;
    padding: 1em;
    align-self: flex-start;
    align-content: center;
    @media (min-width: 992px) {
        width: 40%;
        height: 82%;
        padding: 1em;
        margin-top: 0;
        margin-left: 0em;
        margin-right: 0em;
        border-radius: 0.4em;
        align-self: center;
    }
    @media (min-width: 1440px) {
        width: 35%;
        height: 88%;
        padding: 1em;
    }
    @media (min-width: 1600px) {
        width: 35%;
        height: 75%;
        padding: 1em;
    }
`;
