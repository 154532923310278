import {
	SET_REANUDAR,
	GET_ETAPAS,
} from "../constantes";

const initialState = {
    esReanudacion: false,
    etapasCompletadas: [],
};

function reanudarReducer(state = initialState, action) {
	switch (action.type) {
        case GET_ETAPAS:
			return {
				...state,
				etapasCompletadas: [...state.etapasCompletadas, action.payload],
			};
        case SET_REANUDAR:
            return {
                ...state,
                esReanudacion: action.payload,
            };
        default:
            return state;
    }
}

export default reanudarReducer;
